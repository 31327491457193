/* allorder css */
@media screen and (max-width:1105px) {
    .Profile-divider .order-content {
        width: 100%;
        height: auto;
    }

    .order-menu {
        display: none;
    }
}

@media screen and (max-width:770px) {
    .recentorder .card-head p {
        font-size: 13px;
    }

    .recentorder .order-btn button {
        width: 89px;
        height: 30px;
        font-size: 14px;
        padding-top: 0px;
    }
}

@media screen and (max-width:470px) {

    .recentorder .card-head p {
        font-size: 11.5px;
        padding-top: 7px;
    }

    .recentorder .current-body span {
        font-size: 13px;
    }
    .Profile-divider .order-content h5{
        font-size: 14px;
        font-weight: 600;
    }
 .recentorder .order-btn h3{
    font-size: 12px;
  }
  .recentorder .order-btn h4{
    font-size: 12px;
  }
}
@media screen and (max-width:452px){
    .ordr-dtl .odr-img{
        width: 65px;
        height: 65px;
    }
    .ordr-dtl .ord-text h4{
        font-size: 14px;
        padding-left: 15px;
    }
    .ord-text .ord-rate h5{
        padding-left: 15px;
        font-size: 11px;
        width: 165px;
    }
    .ord-text .ord-rate{
        margin-top: 15px;
    }
    .ord-text .ord-rate h5 span{
        font-size: 8px;
    }
    .ord-text .ord-rate h4{
        font-size: 8px;
        
    }
}
@media screen and (max-width:1200px){
    .ordr-dtl .ord-text{
        width: 85%;
    }
    .ord-text .ord-rate{
        width: 100%;
    }
}
@media screen and (max-width:768px){
    .ord-text .ord-rate h5 {
        font-size: 12px;
        word-spacing: -1px;
    }
    .ord-text .ord-rate h5 span{
        font-size: 12px;

    }
    .ord-text .ord-rate h4{
        font-size: 12px;
    }
}
@media screen and (max-width:495px){
    .ordr-dtl .ord-text{
        width: 82%;
    }
}
@media screen and (max-width:578px){
    /* .order-content{
        background-color: #F5F3F3;
    } */
    .recentorder {
        background-color: white;
    }
}
.allorder-wrap {
    background-color: white;
    height: auto;
    width: 100%;
    border-radius: 10px;
    font-family: 'Open Sans', sans-serif;
    margin-top: 20px;
    color: #212121;
}

.order-menu {
    width: 350px;
    height: 510px;
    margin-bottom: 20px;
}

.order-content {
    width: 74%;
    height: 73vh;
    border-left: 1px solid rgb(198, 198, 198);
    margin-bottom: 20px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.recentorder {
    border: 1px solid rgb(204, 203, 203);
    border-radius: 4px;
    width: 95%;
    height: auto;
    margin: auto;
    margin-top: 27px;
}

.order-content h1 {
    font-size: 20px;
    
    padding-top: 15px;
    padding-left:19px ;
    line-height: 25px;
}
.ordr-dtl {
    width: 97%;
    margin: auto;
    border-bottom: 1px solid #D5D2D2;
    margin-top: 10px;
    padding-bottom: 10px;
    display: flex;
    
}

.odr-img {
    width: 102px;
    height: 102px;
    border-radius: 5px;
}

.odr-img img{
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
.ord-text{
    width: 100%;
    height: auto;
}
/* .ord-text h4{
    font-size: 18px;
    padding-left: 30px;
   
} */
.ord-rate{
    display: flex;
    margin-top: 35px;
    width: 70%;
    
}
.ord-rate h5{
    padding-left: 30px;
    font-size: 16px;
    font-weight: 500;
    word-spacing: 5px;
}
.ord-rate h4{
    font-size: 16px;
    padding-right: 20px;
    text-align: right;
    font-weight: 600;
    word-spacing: 3px;
}
.rate1{
    width: 50%;

}
.rate2{
    width: 50%;
}
.order-btn h4{
    font-size: 16px;
    padding-left: 15px;
    font-weight: 600;
}
.order-btn {
    width: 100%;
    height: 135px;
    margin-bottom: 10px;
    margin-top: 30px;
   
}
.order-btn h3{
   font-size: 16px;
   padding-left: 15px;
}
.order-btn button {
    float: right;
    margin-right: 20px;
    height: 40px;
    width: 174px;
    background-color:#A3080C;
    border-style: none;
    color: white;
    border-radius: 5px;
    font-size: 20px;
    margin-top: 30px;
}



































































































































































































































