@media screen and (max-width:1105px) {
    .Profile-divider .track-content {
        width: 100%;
    }

    .trackorder-wrap {
        margin-bottom: 50px;
        height: 88vh;
    }

    .Profile-divider .track-content {
        border-left: 0px;
    }
}

@media screen and (max-width:768px) {
    .track-content .track-menu {
        width: 90%;
    }

    .track-menu .track1 p {
        font-size: 14px;
    }

    .track-menu .track1 p span {
        font-size: 12px;
    }

    .track-menu .track1 img {
        width: 18px;
        height: 18px;
    }

    .track-menu .track1-indicator {
        width: 21px;
        height: 21px;
    }

    .track-menu .track1-indicator img {
        width: 14px;
        height: 14px;
        margin-top: -5px;
        margin-left: 3px;
    }

    .track-menu .track-line1 {
        margin-left: 47px;
    }

    .recentorder .trk-btn {
        margin-top: -6px;
        margin-bottom: -21px;
    }

    .recentorder .trk-btn button {
        width: 73.041px;
        height: 24px;
        font-size: 14px;
        
    }
    .noaddress .address_img{
        width: unset;
    }
    .noaddress h3{
        font-size: 15px;
    }
    
}
.address_img{
    width: 360px;
   margin: auto;
   
}
.address_img img{
    width: 100%;
    height: auto;
}
.noaddress h3{
    text-align: center;
    margin-top: 30px;
    font-size: 16px;
    color: #3A3A3A;
}
.noaddress p{
    text-align: center;
  
    font-size: 14px;
    color: #3A3A3A;
}
.noaddress{
    width: 80%;
    margin: auto;
    height: 450px;
    margin-top: 20px;
   
}
.trackorder-wrap {
    background-color: white;
    height: auto;
    width: 100%;
    border-radius: 10px;
    font-family: 'Open Sans', sans-serif;
    margin-top: 20px;
    color: #212121;
}

.track-content {
    width: 74%;
    height: auto;
    border-left: 1px solid rgb(198, 198, 198);
    margin-bottom: 20px;
}

.track-menu {
    width: 80%;
    height: auto;
    margin: auto;
    margin-top: 40px;
}

.track1 {
    display: flex;

}

.track1 img {
    width: 28px;
    height: 28px;
}

.track1 p {
    margin-left: 20px;
    font-size: 18px;
    color: #212121;
    line-height: 20px;
    font-weight: 600;
}

.trk-btn {
    width: 100%;
    height: 100px;
    margin-bottom: -4px;
    margin-top: 30px;
    padding-right: 40px;
}

.trk-btn button {
    float: right;
    margin-right: -17px;
    height: 40px;
    width: 121px;
    background-color:#A3080C;
    border-style: none;
    color: white;
    border-radius: 5px;
    font-size: 20px;
    margin-top: 30px;
   
}



.track1 p .spans {
    font-size: 14px;
    color: #433f3f7f;
    margin: 0;
    padding: 0;

}

.track1-indicator {
    width: 26px;
    height: 26px;
    background: #D9D9D9;
    border-radius: 60px;
    margin-left: 20px;
}

#track_active {
    background-color:#A3080C;
}

.track1-indicator img {
    width: 16px;
    height: 16px;
    margin-left: 5px;
}

.track-line1 {
    width: 3px;
    height: 52px;
    background-color: #D9D9D9;
    margin-left: 60px;
    margin-top: -55px;
}



/* .track-menu ul{
    padding-left: 50px;
    position: relative;
    list-style: none;
}
.track-menu ul li{
    font-size: 14px;
    font-weight: 500;
    padding: 10px 0;
    font-weight: 600;
}
.track-menu ul li span{
    font-size: 12px;
    color: #868585;
}
.track-menu ul::after{
    content: "";
    position: absolute;
    width: 4px;
    height:84%;
    left:20px;
    top:0;
    background-color: #dadada;
    margin-top: 12px;
}
.track-menu ul li:before{
    content: "";
    position: absolute;
    background-color: #dadada;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    left: 9px;
    margin-top: 2px;
    z-index:1;
    background-image: url("../../assets/images/check.png");
    background-position: center;
    background-size: 17px 17px;
    background-repeat: no-repeat;
}
.track-menu ul li.active::before{
    background-color: #c9175e;
}
.track-icon{
    width: 50px;
    padding:16px;
    margin-left: 5px;
    
}
.track-icon img{
    margin: auto;
    width:25px;
    margin-top: 36px;
} */