body {
    background-color: #f5f5f5;
}

.Container-wrap {
    background-color: white;
    margin-top: 20px;
    border-radius: 10px;
    margin-bottom: 90px;
    font-family: 'Open Sans', sans-serif;
}

.head {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid rgb(217, 214, 214);
}

.box-right {
    width:35%;
    border-left: 1px solid rgb(209, 209, 209);
}

.box-left {
    width: 65%;
}

.head h3 {
    font-size: 20px;
    padding-top: 10px;
    margin-left: 10px;
}

.product-details {
    display: flex;
    width: 100%;

}

.css-1fdsijx-ValueContainer {
    padding: 0px 8px !important;
}

.no_cart {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.no_cart_img {
    height: 10rem;
    width: 12rem;
}

.no_cart_text {
    display: flex;
    width: 80%;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}

@media screen and (max-width :1048px) {

    .product-details {
        display: block;
    }

    .head {
        display: none;
    }

    body {
        background-color: #efeded;

    }

    .Container-wrap {
        background-color: transparent;
    }

    .cart-parent {
        padding-top: 40px;
    }

    .box-left {
        width: 100%;
    }

    .box-right {
        width: 100%;
        border-left: 0;
    }

    .box-left .summary-cards {
        width: 95%;
        margin: auto;
    }
}


/* summary css */


.summary-cards {

    margin-top: 20px;
    width: 90%;
    margin: auto;
    height: auto;
}

.card-wraper {
    width: 100%;
    flex-wrap: wrap;
}

@media screen and (max-width:768px) {

    .card-wraper {
        width: 100%;
    }

    .box-left .summary-cards {
        width: 95%;
        margin: auto;
    }

    .box-right {
        width: 100%;
        border-left: 0;
    }

    .card-wraper .card-details .card-prize button {
        
        width: 70px;
        height: 23px;
    }

    .card-prize button .plus {
        
        font-size: 18px;

    }

    .card-prize button .Value {
        font-size: 12px;

    }

    .card-prize button .minus {
        
        font-size: 18px;

    }
    .summary-cards .card-img {
        height: 99px;
    }
}

.details {
    margin-top: 15px;


}

.card-det1 {
    display: flex;

    width: 100%;
    padding: 0;
    height: auto;
}

.card-details {
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    border-left: 1px solid #cdcdcd;
    border-top: 1px solid #cdcdcd;
    border-right: 1px solid #cdcdcd;
    border-bottom: 1px solid #cdcdcd;
    height: auto;
    box-shadow: 0px 5px 5px -5px gray;
    flex: 1 1 200px;
}

.card-img {
    width: 140px;
    margin-top: 10px;
    margin-left: 10px;
    height: 113px;
    /* background-image: url("../../assets/images/v682_250.png");
    background-size: cover;
    background-position: center; */
}
.card-img img{
    width: 100%;
    height: 100%
}
.summary-cards .lable {
    font-size: 14px;
    font-weight: 500;
    width: 150px;
    margin-left: 25px;
    margin-top: 10px;
}

.summary-cards .lable img {
    margin-top: -3px;
}

.card-details .card-heading {
    width: 100%;
    margin-top: 5px;

}

.card-details .card-heading ul {
    font-size: 12px;
    margin-top: 20px;
    width: 90%;
    height: auto;
    margin: auto;
}

.card-details .card-heading ul li {
    margin-left: -15px;
    margin-top: 5px;
    word-spacing: 3px;
}

.card-details .card-heading h5 {
    font-size: 16px;
    width: 90%;
    margin: auto;
    padding: 5px;
    border-bottom: 1px solid rgb(207, 207, 207);
    line-height: 22px;
    word-spacing: 2px;
}

.card-details .card-prize {
    height: 46px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}
.parah2 span{
    color:#38AE36;
     padding-left:15px;
      padding-right:15px;
}
.card-details .card-prize p {
    padding-top: 13px;
    font-size: 14px;
    padding-left: 10px;
    width: 78%;
    font-weight: 600;
    text-align: left;
}

.card-details .card-prize button {
    display: flex;
    align-items: center;
    width: 97.96px;
    height: 30.409px;
    border-radius: 20px;
    background-color:#A3080C;

    font-weight: 700;
}

.plus {
    display: -webkit-flex;
    height: 30px;
    width: 30px;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
    margin-top: -3px;
    color: #fff;
}

.Value {
    display: -webkit-flex;
    width: 45px;
    height: 85%;
    background-color: white;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #212121;
}

.minus {
    display: -webkit-flex;
    height: 30px;
    width: 30px;
    justify-content: center;
    align-items: center;
    padding-left: 1px;
    cursor: pointer;
    margin-top: -1px;
    font-size: 20px;
    color: #fff;
}

.card-details button {
    font-size: 11px;
    width: 70px;
    height: 30px;
    float: right;
    margin-right: 10px;
    display: flex;
    margin-top: -5px;
    border-radius: 5px;
    background-color:#A3080C;
    border-style: none;
    color: white;
}

/* image slider */


.img-container {
    margin-top: 50px;
    width: 85%;
}

.img-container .carousel {
    height: 300px;

}

.img-container .carousel-inner {
    height: 300px;
}

.img-container .carousel-item {
    height: 300px;
}

@media screen and (max-width:800px) {
    .img-container {
        display: none;
    }
}

/* card slider */

.card-box {
    width: 152px;
    height: 242px;
    border: 1px solid #949494;
    border-radius: 3px;
    margin-top: 20px;
    background-color: white;
}

.card-box h6 {
    font-size: 14px;
}

.mycard {
    position: relative;
    overflow: hidden;
    padding: 20px;

    margin-top: 20px;
    margin-bottom: 10px;
    /* border-top: 5px solid rgba(231, 229, 229, 0.699); */
    /* -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
    z-index: 1;
    overflow-y: hidden;
  white-space: nowrap;
  flex-direction: row; */
}

.mycard h2 {
    font-size: 16px;
    font-weight: 600;
}

.box1 {
    margin-left: 25px;
}

.product-container {
    /* padding: 0 10px; */
    display: flex;
    overflow-x: hidden;
    scroll-behavior: smooth;
    width: 100%;
}

.pre-btn,
.next-btn {
    border: none;
    width: 50px;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    background-color: rgba(0, 0, 0, 0);
}

.next-btn {
    right: 0;
}

.pre-btn {
    left: 0;
}

.next-btn p,
.pre-btn p {
    font-size: 20px;
    background-color: rgba(255, 255, 255, 0.436);
    box-shadow: 0px 0px 10px 1px rgb(155, 155, 155);
    border-radius: 60%;
    color:#A3080C;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

@media screen and (max-width:1049px) {
    .mycard {
        width: 100%;
        margin-top: -10px;
    }

    .mycard .card-box {
        border-style: none;
        width: 120px;
        height: 191px;
        font-size: 12px;
        margin-top: -3px;
        margin-left: 8px;
    }

    .mycard .card-box img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin: 10px 18px;
    }

    .mycard .card-box h6 {
        font-size: 12px;
        text-align: center;
        margin-top: -8px;
    }

    .mycard .card-box p {
        font-size: 12px;
        text-align: center;
        margin-top: -6px;
        font-weight: 600;
    }

    .mycard .card-box button {
        width: 80px;
        height: 25px;
        font-size: 12px;
        margin-left: 17px;
        border-radius: 20px;
        background-color:#A3080C;
        color: white;
        border-style: none;
        margin-top: -10px;
    }

    .product-container .card-box .service_cartbtn {
       margin-top: -4px;
       margin-left: 17px;
    }
}

/* .card-box-wrap h5{
    font-size: 18px;
    padding-left: 20px;
    padding-top: 10px;
} */
.card-box img {
    width: 101px;
    height: 101px;
    border-radius: 50%;
    margin: 10px 25px;
}

.card-box h6 {
    font-size: 14px;
    text-align: center;
}

.card-box p {
    font-size: 14px;
    text-align: center;
    padding-top: 6px;
    font-weight: 600;
}

.card-box button {
    width: 80px;
    height: 25px;
    font-size: 14px;
    margin-left: 35px;
    border-radius: 20px;
    background-color:#A3080C;
    color: white;
    border-style: none;

}

.card-box .service_cartbtn {
    display: flex;
    border-radius: 20px;
    width: 80px;
    height: 25px;
    margin-left: 35px;
    background:#A3080C;

    color: white;
    border: 1px solid#A3080C;

}

.card-box .service_cartbtn span {
    background-color: transparent;
    height: 100%;
    width: 30px;
    text-align: center;
    font-size: 16px;
}

.card-box .service_cartbtn .counter {
    background-color: white;
    color: black;
    border-radius: 0px;
}

/* payment */



.payment {
    width: 80%;
    margin: auto;
    background-color: white;
    height: auto;
}

.payment-details {
    width: 100%;
    margin-top: 50px;
}

@media screen and (max-width:1049px) {
    .Container-wrap .cart_cost {
        display: flex;
        padding: 9px;
        position: fixed;
        bottom: 0;
        height: 55px;
        background: #fff;
        width: 99.9%;
        align-items: center;
        justify-content: center;
        box-shadow: 5px 5px 10px 5px #c3c3c3;
        border-radius: 1px;
        z-index: 1;
    }

    .Container-wrap .cart_cost .cart_left span {
        font-size: 14px;
    }

    
    .Container-wrap .cart_cost .cart_right span {
        font-size: 15px;
        font-weight: 500;
        border-radius: 2px;
    }

}

@media screen and (max-width:580px) {
    .Container-wrap .cart_cost .cart_left {
        display: none;
    }

    .Container-wrap .cart_cost .cart_right {
        display: none;
    }

    .Container-wrap .cart_cost .cart_addressbtn {
        display: block;
    }

    /* .Container-wrap .cart_cost .cart_addressbtn a {
        background-color:#A3080C;
        color: white;
        width: 100%;
        height: 36px;
        text-align: center;
        padding-top: 6px;
        border-radius: 3px;
        font-size: 14px;
        font-weight: 500;
        text-decoration: none;
    } */
}

@media screen and (max-width:1048px) {

    .payment-details {
        width: 90%;
        margin: auto;
    }

    .payment {
        border-style: none;
        margin: auto;
        margin-top: -5px;
        width: 100%;
        margin-bottom: 80px;
    }

    .payment .payment-details table tr td {
        padding-top: -10px;
    }

    .payment .payment-details table tr th {
        padding-top: -10px;
    }

    .total {
        border-bottom: 1px solid rgb(255, 255, 255);
    }

    .divider-line {
        width: 100%;
        height: 8px;
        background-color: rgb(232, 232, 232);
    }

    .payment .total {
        height: 60px;
        padding-top: 5px;
        border-top: 8px solid rgb(234, 234, 234);
    }

    .payment .total table {
        width: 90%;
        margin: auto;
    }

    .payment .pay {
        font-weight: 400;
        font-size: 14px;

    }

    .payment .payment-details h5 {
        font-size: 16px;
    }

    .payment .total table {
        font-size: 14px;
        margin-top: -6px;
    }

    .payment .coupon {
        width: 90%;
        margin-top: 5px;
    }

    .payment-details table tr td {
        font-weight: 400;
        padding-top: 5px;
    }
}

.cart_addressbtn {
    display: none;
}

.payment-details h5 {
    font-size: 24x;
    padding-top: 15px;
    font-weight: 600;
    color: #292929;
}

.cart_cost .cart_addressbtn {
    background-color:#A3080C;
    color: white;
    width: 100%;
    height: 45px;
    text-align: center;
    padding-top: 12px;
    border-radius: 3px;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
}

.pay {
    width: 100%;
    margin-top: 20px;
    font-size: 17px;

}

.payment-details table tr th {
    padding-top: 15px;
    font-weight: 400;
}

.payment-details table tr td {
    font-weight: 400;
    padding-top: 15px;
}

.pay tr td {
    font-weight: 400;
}

.pay tr th {
    float: right;
    margin-right: 10px;
}

.total {
    width: 100%;
    height: 40px;
    margin-top: 20px;
    border-top: 1px solid rgb(212, 211, 211);
    border-bottom: 1px solid rgb(212, 211, 211);
}

.total table {
    width: 100%;
    font-size: 17px;
    font-weight: 400;
}

.success {
    color: #38AE36;
}

.danger {
    color: red;
}

.total table tr td {
    padding-top: 7px;
}

.total table tr th {
    float: right;
    margin-right: 10px;
    padding-top: 7px;
}

.coupon {
    height: 65px;
    background-color: rgb(255, 255, 255);
    margin: auto;
    width: 100%;
    margin-top: 20px;
}

.coupon table {
    width: 100%;
    height: 30px;

}

.coupon table tr th {
    padding-top: 10px;
    font-size: 14px;
    font-weight: 600;
}

.coupon table tr td {
    float: right;
    padding-right: 30px;

    padding-top: 20px;

}

/* .coupon table tr td a {
    text-decoration: none;
    color:#A3080C;
    font-weight: 600;
    font-size: 14px;
} */

.coupon table tr th span {
    text-decoration: none;
    font-size: 12px;
    color: #767676;
    font-weight: 600;
    padding-left: 45px;
    padding-top: -10px;
}