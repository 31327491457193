.whatsapp-button {
    position: fixed; /* This ensures the button stays in place on the screen even when scrolling */
    bottom: 74px;
    right: 9px;/* Adjust distance from the right */
    z-index: 1000; /* Ensure it stays above other content */
    background-color: #25D366;
    border-radius: 50%; /* Circle-shaped button */
    width: 60px;
    height: 60px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
  }
  
  .whatsapp-button img {
    width: 35px;  /* Size of WhatsApp logo */
    height: 35px;
  }
  
  .whatsapp-button:hover {
    background-color: #22c35e; /* Slightly darker green on hover */
  }