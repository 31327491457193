.menu {
    width: 26px;
    height: auto;
}

@media screen and (max-width:1049px) {
    .Navbar {
        display: none;
    }
}

@media screen and (max-width:1205px) {
    .nav-Container .nav-logo .logo_img img {
        width: 200px;
        height: 45px;
    }

    .nav-Container .nav-logo p {
        font-size: 18px;
    }

    .nav-Container .nav-items {
        font-size: 16px;
        font-weight: 500;
    }

    .nav-items .nav-links {
        font-size: 16px;
        font-weight: 500;
    }

    .nav-Container .nav-menu {
         
        margin: -2px 30px 0px 0px;
    }
}
.nav-items {
    cursor: pointer;
}
.nav-menu{
    padding: 0px;
    margin: -3px 30px 0px 0px;
   
}
.mobile_navbar {
    position: unset;
    background-color: white;
    top: 0;
}

.Navbar {
    width: 100%;
    height: 60px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px -4px 15px 0px rgb(105, 105, 105);

}

.nav-logo .logo_img {
    width: auto;
    height: 49px;
    margin-top: 5px;

}

.nav-logo .logo_img img {
    width: auto;
    height: 41px;
}

.nav-links {
    font-size: 20px;
    font-weight: 500;
    text-decoration: none;
    font-family: 'Open sans', sans-serif;
    color: #0c0c0c;
    font-family: 'open sans', sans-serif;
    padding: 0.5rem 1rem;
    border-bottom: 3px solid transparent;
    
}

.nav-Container {
    font-family: 'Open Sans', sans-serif;
    margin: auto;
    width: 90%;
    display: flex;

}

.nav-Container .nav-logo {
    height: 49px;

}

.nav-logo .tageline {
    font-family: 'Open Sans', sans-serif;

}

.nav-Container a {
    font-family: 'Open Sans', sans-serif;

}

.nav-Container ul li {
    height: 62px;
    padding-top: 15px;
    font-size: 20px;
    font-family: 'Open Sans', sans-serif;
    margin-left: 25px;
}

.nav-Container ul li:hover {
    color:#A3080C;
}

.nav-Container ul li a:hover {
    color:#A3080C;
    font-family: 'Open Sans', sans-serif;
}
.mobile_nav_logo{
    display: flex;
    flex: center;
}


.mobile-tagline {
    width: 56%;
    display: flex;
    flex-direction: row;
    height: 30px;
    /* margin-left: 30px; */
    font-size: 12px;
    /* justify-content: center; */
    align-items: center;
}

.Login-menu {
    width: 149px;
    height: 175px;
    background-color: white;
    margin-top: 100px;
    position: absolute;
    right: 0;
    margin-top: 55px;
    border-radius: 7px;
    margin-right: 190px;
    border: 1px solid #E6E6E6;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.42);
}

.Login-menu .arrowup {
    margin-top: -42px;
}

.Login-menu ul {
    width: 100%;
    height: 100%;
    margin-top: -28px;
}

.Login-menu ul li {
    margin-left: -30px;
    list-style: none;
    font-size: 14px;
    font-weight: 700;
    line-height: 10px;
    height: 40px;
    border-bottom: 1px solid rgb(218, 217, 217);
    padding-left: 13px;
    padding-top: 13px;
    color: #4D4949;
}

.Login-menu a {
    text-decoration: none;
    color: #4D4949;
}
.sidemodal-content .ac-banner{
    height: 114px;
}
.sidemodal-content .user{
    display: flex;
    flex-direction: column;
    padding-top: 20px
}
.sidemodal-content .user-detail{
    margin-left: 0px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}
.sidemodal-content .user-detail h4{
    font-size: 18px;
}
.sidemodal-content .user-detail button{
    margin-right: 20px;
    width: 60px;
    height: 23px;
    font-size: 14px;
    font-weight: 500;
    background-color:#A3080C;
    color:white;
    border-style: none;
    border-radius: 3px;
}