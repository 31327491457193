@media screen and (max-width: 1105px) {
    .profile-parent .profile-head {
        display: none;
    }
    .profile-parent{
        padding-top: 60px;
    }
    .profile1-menu{
        display: none;
    }
    .profile-content .prbtn-wrap{
        width: 100%;
        border-top: 8px solid rgb(238, 238, 238);
    }
    .Profile-divider .profile-content{
        width: 100%;
    }
    .profile-content .profile-detail{
        width: 90%;
    }
}
@media screen and (max-width: 546px) {
    .profile-content .profile-detail h4{
        font-size: 18px;
    }
    .profile-content .profile-detail table{
        font-size: 14px;
    }
    .profile-content .profil-edit button{
        font-size: 18px;
    }
}

.profile-wrap{
    background-color: white;
    height: auto;
    width: 100%;
    border-radius: 10px;
    font-family: 'Open Sans', sans-serif;
    margin-top: 20px;
    color: #212121;
}
.profile-head{
    display: flex;
    width: 100%;
    height: 60px;
    padding: 20px;
    border-bottom: 1px solid rgb(215, 215, 215);

}

.username p{
    float: right;
    font-size: 20px;
}
.username {
    margin: auto;
    width: 20%;
    margin-right: 50px;
}
.profile-parent{
    padding-bottom: 20px;
}
.Profile-divider{
    display: flex;
    height: auto;
    width: 100%;
}
.profile1-menu{
    width: 350px;
    height: 510px;
    border-right: 1px solid rgb(211, 211, 211);
    margin-bottom: 20px;
}
.menu-div{
    width: 80%;
    margin: auto;
    height: 500px;
    margin-top: 10px;
    background-color: #f5f5f5;
    
}
.menu-div ul{
    width: 100%;
    margin: auto;
    padding-top: 10px;
}
.menu-div   ul a li{
    height: 45px;
    font-size: 16px;
    width: 109%;
   margin-left: -28px;
    padding-top: 10px;
    border-bottom: 1px solid rgb(214, 214, 214);
    list-style: none;
   justify-content: center;
   
}
.menu-div ul a  {
    width: 100%;
    text-decoration: none;
    color: #2b2b2b;
    font-weight: 400;
}
.menu-div ul a li .nexticon{
    float: right;
    margin-right: 22px;
    margin-top: 10px;
}
.menu-div ul a  li .usericon{
    margin-left:21px;
    margin-right: 21px;
    margin-top:-5px ;
}
.menu-div .active{
 
    background-color: rgba(152, 19, 19, 0.12);
    border-radius: 5px;
}
.menu-div .logout-btn {
    width: 50%;
    margin: auto;
    margin-top: 90px;
}
.logout-btn button{
    width: 100%;
    height: 35px;
    border-radius: 3px;
    border-style: none;
    background-color:#A3080C;
    color: white;
    font-size: 18px;
}
.profile-content{
    width:74%;
}
.profile-detail{
    width: 80%;
    margin: auto;
    height: 450px;
    margin-top: 10px;
}
.profile-detail h4{
    border-bottom: 1px solid rgb(223, 223, 223);
    padding: 10px;
    font-size: 18px;
    color: #3A3A3A;
}
.profile-detail table{
    margin-left: 10px;
    width: 100%;
    font-size: 18px;
}
.profile-detail table tr th {
    padding-top: 10px;
    font-weight: 500;
    color: #3A3A3A;
}
.profile-detail table tr td {
    padding-top: 10px;
    color: #3A3A3A;
}
.profil-edit{
    width: 95%;
    margin: auto;
 
}
.profil-edit button{
    width: 100%;
    height: 38px;
    border-radius: 3px;
    background-color:#A3080C;
    color: white;
    border-style: none;
    font-size: 18px;
    margin-top: 30px;
}
.prbtn-wrap{
    width: 57%;
    margin-top: 30px;
    height: 100px;
}

/* Profile mobile page */ 

.ac-parent{
    padding-top: 45px;
}
.ac-banner{
    width: 100%;
    height: 132px;
    background-image: url(../../assets/images/profil_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.banner-overley{
    width: 100%;
    height: 100%;
    background-color:  rgba(0, 0, 0, 0.73);
}
.user{
    display: flex;
    width: 100%;
    height: auto;
    padding-top: 30px;
    padding-left: 20px;
}
.user-box{
    width: 50px;
    height: 52px;
    background-color: white;
    border-radius: 5px;
   padding: 10px;
}
.user-box img{
    width: 29px;
    height: 30px;
   
}
.user-detail{
    margin-left: 20px;
}
.user-detail h4{
    color: white;
    font-size: 14px;
}
.user-detail p{
    color: white;
    font-size: 14px;
}
.menu-box{
    width: 100%;
    height: auto;
    background-color: white;
    border-top-left-radius: 20px ;
    border-top-right-radius: 20px ;
    margin-top: -17px;
    padding-top: 10px;
}
.menu-box .menu-div {
    width: 100%;
    margin: auto;
    height: 624px;
    background-color: transparent;
}
.menu-box .menu-div ul li{
    height: 50px;
    font-size: 16px;
    font-weight: 500;
    color: #2b2b2b;
}

/* reschedule css */

.reschdule-wrap{
    width: 90%;
    margin: auto;
    margin-top: 30px;
}
.reschdule-wrap

.time-box h3 {
    padding-left: 0px;
    font-size: 25px;
    margin-top: -5px;
    
}
.res-heading {
    width: 100%;
  
    border-bottom: 2px solid rgb(238, 238, 238)
}
.res-heading h4{
    padding:10px 20px;
    font-size: 24px;
    font-weight: 600;
}
.reschdule-wrap .timebox1{
    width: 85px;
    height: 85px;
    padding: 15px;
}
.reschdule-wrap .time-box p{
    padding-left: 0px;
    padding-top: 0px;
}
.reschdule-wrap .time-box1{
    width: 167px;
}
@media (max-width: 768px) {
    .popup-content  {
        position: fixed;
        background: #f4f0f0;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 9;
        
    }
    .current-content .reschdule-wrap .time-box h3 {
        /* margin-left: -50px; */
        margin-top: -11px;
    }
    .reschdule-wrap{
        width: 100%;
    }
    .current-content .reschdule-wrap .timebox1 p {
        font-size: 13px;
        margin-left: -2px;
        text-align: center;
        margin-top: -15px;
    }
    .popup-content .reschedule-body {
      
        background: white;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        z-index: 9;
        overflow: auto;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 24px;
        border-top-left-radius: 24px;
        height: fit-content;
    }
    .cancel-btn {
        display: block;
    }
    .popup-content .reschedule-body .parent-scroll{
        height: fit-content;
    }
    .popup-content .reschedule-body .time-box p{
        padding-top: 6px;
    }
    .reschdule-wrap .res-heading h4{
        font-size: 16px;
    }
}

.reschedule-body{
    background-color: white;
    width: 800px;
    height: 500px;
     border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    margin-top: 10px;
    font-family: 'Open Sans', sans-serif;
    padding-top: 10px;
    padding-bottom: 10px;
}
.parent-scroll{
  overflow-y: scroll;
  overflow-x: hidden;
  height: 480px;

}

.reschedule-body .time-box h3 {
    padding-left: 0px;
    font-size: 25px;
    margin-top: -10px;
}
.reschedule-body .timebox1{
    width: 85px;
    height: 85px;
    padding: 15px;
}
.reschedule-body .time-box p{
    padding-left: 0px;
    padding-top: 0px;
}
.reschedule-body .time-box1{
    width: 167px;
}