.mobilelogin {
    padding-top: 70px;
    width: 100%;
    height: 100vh;
    background-color: white;

}

.LOGIN-body {

    width: 90%;
    margin: auto;
    height: auto;
    font-family: 'Open Sans', sans-serif;
}

.LOGIN-body h3 {
    text-align: center;
    font-size: 18px;
    padding-top: 20px;
    font-weight: 600;
    color: #363535;
}

.LOGIN-body form {
    margin-top: 20px;
}

.LOGIN-body form {
    width: 100%;
    height: auto;
    margin-top: 40px;

}

.LOGIN-body .phone-input {
    width: 100%;
    height: 48px;
    border: 1px solid #818181;
    display: flex;
    border-radius: 3px;
    margin-top: 40px;
}

.phone-input span {
    width: 61px;
    height: 45px;
    background-color: white;
    padding: 12px;
    border-right: 1px solid #918383;
    font-size: 16px;
}

.phone-input input {
    width: 400px;
    padding: 10px;
    border: 0px;
    height: 45px;
    font-size: 16px;
}

.LOGIN-body label {
    width: 100%;
    padding-top: 20px;
    display: flex;

    margin-top: 10px;
}

.LOGIN-body h4 {
    font-size: 12px;
    color: #686868;
    font-weight: 400;
    margin-top: 20px;
    line-height: 17px;
}

.LOGIN-body h4 span {
    color:#A3080C;
    font-weight: 600;
}

.LOGIN-body h5 {
    color: #686868;
    font-size: 13px;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    padding-top: 40px;
}
.LOGIN-body h5 span{
    color:#A3080C;
    font-weight: 700;
    padding-left: 5px;
}
.LOGIN-body label input {
    width: 20px;
    height: 20px;
    margin-top: 7px;
}

.LOGIN-body label span {
    margin-top: 6px;
    font-size: 14px;
    padding-left: 5px;
    color: #686868;
}

.LOGIN-body label input[type="checkbox"] {
    width: 30px;
    accent-color: #ed1765d9;
}

.LOGIN-body .login-btn {
    margin-top: 40px;
    width: 100%;
    height: 45px;
    top: 10px;
    right: 10px;
    color: white;
    background:#A3080C;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 3px;
    font-weight: 600;
}

/* optpage css */

.LOGIN-body p {
    text-align: center;
    font-size: 12px;
    color: #413b3bde;
    margin-top: 10px;
    font-weight: 400;
}

.LOGIN-body .otp-inputs .otp-input {
    margin-top: 10px;
    border-color: rgb(200, 200, 200);
    box-shadow: none;
    
}

.LOGIN-body h6 {
    text-align: center;
    margin-top: 25px;
    color: #cc1c5dde;
    font-size: 12px;
    font-weight: 600;
}