.salon-container {
    font-family: Arial, sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 4rem 1rem 1rem 1rem;
    position: relative;
    min-height: 100vh;
  }
  
  .offer-banner {
    background-color: #025002;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .offer-banner p {
    font-size: 1rem;
    font-weight: 500;
    margin: 0rem;
    
  }
  
  .main-banner {
    position: relative;
    width: 100%;
    height: 12rem;
    margin-bottom: 2rem;
    border-radius: 1rem;
    overflow: hidden;
  }
  
  .main-banner-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .main-banner-overlay {
    position: absolute;
    inset: 0;
    background: linear-gradient(to right, #A3080C, #FF9A8B);
    opacity: 0.8;
  }
  
  .main-banner-content {
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 1rem;
    text-align: center;
  }
  
  .main-banner-content h1 {
    font-size: 1.875rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .main-banner-content p {
    font-size: 1.125rem;
    max-width: 42rem;
  }
  
  .subcategories-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.75rem;
  }
  
  .subcategory-card {
    background-color: white;
    border-radius: 0.75rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: all 0.3s ease;
  }
  
  .subcategory-card:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  }
  
  .subcategory-content {
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .subcategory-icon {
    width: 4rem;
    height: 4rem;
    border-radius: 1rem;
    /* background-color: #A3080C; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
  }
  
  .subcategory-name {
    font-size: 14px;
    font-weight: 400;
    color: #616161;
  }
  
  .floating-menu-button {
    position: fixed;
    bottom: 4.5rem; /* Increased to add more space at the bottom */
    left: 45%;
    /* transform: translateX(-50%); */
    background-color: #A3080C;
    color: white;
    padding: 0.75rem;
    border-radius: 9999px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 50;
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease;
}

@media (min-width: 750px) {
  .floating-menu-button {
      display: none;
  }
}
  
  .floating-menu-button:hover {
    transform: scale(1.1);
  }
  
  .floating-menu-button:active {
    transform: scale(0.9);
  }
  
  .modal-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    padding: 1rem;
  }
  
  .modal-content {
    background-color: white;
    border-radius: 1rem;
    padding: 0.5rem 1rem 0.5rem 1rem;
    max-width: 32rem;
    width: 100%;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 1.5rem; */
  }
  
  .modal-title {
    font-size: 21px;
    font-weight: bold;
    color: #A3080C;
  }
  
  .modal-close-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #A3080C;
  }
  
  .modal-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* gap: 0.2rem 1rem; */
  }
  
  .modal-item {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-radius: 0.5rem;
    transition: background-color 0.2s ease;
  }
  
  .modal-item:hover {
    background-color: #f3f4f6;
  }
  
  .modal-item-icon {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.5rem;
    /* background-color: #A3080C; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.75rem;
  }
  
  .modal-item-name {
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  @media (min-width: 640px) {
    .salon-container {
      padding: 1.5rem;
    }
  
    .main-banner {
      height: 16rem;
    }
  
    .main-banner-content h1 {
      font-size: 2.25rem;
    }
  
    .main-banner-content p {
      font-size: 1.25rem;
    }
  
    .subcategories-grid {
      gap: 1rem;
    }
  
    .subcategory-content {
      padding: 1rem;
    }
  
    .subcategory-icon {
      width: 5rem;
      height: 5rem;
    }
  
    .subcategory-name {
      font-size: 1rem;
    }
  }
  
  @media (min-width: 768px) {
    .salon-container {
      padding: 2rem;
    }
  
    .main-banner {
      height: 20rem;
    }
  
    .main-banner-content h1 {
      font-size: 3rem;
    }
  
    .main-banner-content p {
      font-size: 1.5rem;
    }
  
    .subcategories-grid {
      gap: 1.5rem;
    }
  
    .subcategory-content {
      padding: 1.5rem;
    }
  
    .subcategory-icon {
      width: 6rem;
      height: 6rem;
    }
  
    .subcategory-name {
      font-size: 1.125rem;
    }
  }