.sidebar {
    /* padding: 1rem 0rem; */
    border-radius:15px 15px 0px 0px;
    background: #ffffff;
    /* box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25); */
    margin-top: -20px;
    overflow: hidden;
   
}



.accordion-body {
    padding: 0px !important;
}


.card_services {
    margin: 0rem 11rem;
    padding: 1rem 0.5rem 0.5rem 0.5rem;
}

.menu-list-itme {
    display: flex;
    padding: 9px;
    padding-right: 10px;
    margin: 1px 0px;
    align-items: center;
    /* border-radius: 7px; */
    /* border: 1px solid #C0BCBC; */
    background: #E6E6E6;
    font-size: 14px;
    font-weight: 500;
    height: 37px;
}

.menu-list-itme:hover {
    background: rgba(147, 41, 75, 0.20);
}

.mennlistactive{
    background: rgba(147, 41, 75, 0.20) !important;
}

.menu-list-itme p {
    margin: 10px;
}

/* .card_services {
    margin: 0rem 11rem;
    border-radius: 10px;
} */
/* .section-heada {
    margin-top: 10px;

} */

.section-heada h4 {
    position: relative;
    color:#A3080C;
    line-height: 1;
    letter-spacing: 0.3px;
    font-size: 25px;
    font-weight: 600;
    text-align: left;
    text-transform: none;
}

/* .section-heada h4:before {
    content: "";
    width: 100px;
    height: 3px;
    text-align: left;
    border-radius: 50px;
    background: #E30B5C;
    position: absolute;
    left: 0px;
    bottom: -15px;
    right: 100;
    margin: 0 auto;
} */

.section-heada h4 span {
    font-weight: 700;
    padding-bottom: 5px;
    color: #2f2f2f;
}

.card_services_heading {
    padding-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card_services_button {
    border-radius: 6.476px;
    background:#A3080C;
    color: white;
    display: flex;
    height: 3rem;
    width: 7rem;
    align-items: center;
    justify-content: center;
}

.card_services_button button {
    border-radius: 6.476px;
    background:#A3080C;
    border: none;
    height: 3rem;
    width: 5rem;
    color: white;
}



/* .accordion-item {
    margin-left: 2px !important;
    margin-right: 5px !important;
} */


.button-accordion {
    /* border-radius: 7px; */
    background: #ffffff;
    border-bottom: #e2e2e2 solid 1px;
    /* border-radius: 7px !important; */
    /* margin: 0.3rem 0rem; */
    color: #1f1f1f;
    /* background-color: red; */
    height: 50px;
    font-size: 15px;
    font-weight: 600;
}


/* .accordion-item {
    border: none;
} */



.accordion-button:not(.collapsed) {
    color: #2f2f2f !important;
    background-color: #ffffff;
}

.accordion {
    overflow-y: scroll;
    height: 552px;
 
}

/* .accordion-item {
    margin-right: 10px;
    margin-left: 10px;
    
} */

.servicebar_scrol {
    overflow-y: scroll;
    height: 37rem;
    margin-top: -17px;
}

.servicebar_scrol::-webkit-scrollbar {
    display: none;
}

.servicebar {
    border-radius: 15.228px;
    border: 0.03px solid #b1b1b1;
    border-left: none;
    margin: 0.5rem 0rem;
    background: #FFF;
    box-shadow: 0px 4.060737609863281px 4.060737609863281px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-between;
}


.servicebar_1 {
    border-radius: 15px 0px 0px 15px;
    background: #E888AC;
    width: 20px;
}


.servicebar_2 {
    display: flex;
    flex-direction: column;
    margin: 0.8rem 1.5rem;
}

.servicebar_2 h1 {
    color: #272626;
    font-size: 26px;
    font-family: 'Open sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.servicebar_2 ul li {
    font-size: 16px;
    color:#A3080C;
    padding: 0.2rem 0.2rem;
}


.servicebar_2 ul li span {
    color: #272626;
    width: 100%;
}

.servicebar_3 {
    display: flex;
    align-items: center;
    flex-direction: column;
    /* justify-content: space-between; */
}

.servicebar_3 img {
    width: 280px;
    height: 180px;
    border-radius: 0px 15px;
    background: cover no-repeat;
}

.servicebar_3_con {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.servicebar_3_con ul li {
    font-size: 16px;
    color:#A3080C;
    padding: 0.2rem 0.2rem;
}


.servicebar_3_con ul li span {
    color: #272626;
    width: 100%;
}

.servicebar_3_price {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
}

.servicebar_3_price1 {
    color: #7D7C7C;
    text-decoration: line-through;
}

.servicebar_3_price2 {
    color: #38AE36;
    /* text-decoration:line-through; */
}

.editpack {
    width: 120px;

    /* justify-content: center;
    align-items: center; */
    margin-top: 20px;
    height: 45px;

}

.editpack button {
    width: 100%;
    height: 40px;
    font-size: 13px;
    background-color:#A3080C;
    border: 0px;
    color: rgb(255, 255, 255);
    border: 1px solid rgb(227, 226, 226);
    border-radius: 8px;
}

.servicebar_3_con button {
    border-radius: 7px;
    color: #FFF;
    margin-bottom: 0.5rem;
    border: 0.3px solid#A3080C;
    background:#A3080C;
    padding: 0.5rem 1rem;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.moredetails {

    color:#A3080C;
    cursor: pointer;
}

.servicebar_2_rating {
    display: flex;
    align-items: center;

}


.servicebar_2_rating span {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.servicebar_2_rating p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    margin-left: 5px;
}


.servicebar_2_rating p span {
    color:#A3080C;
}


.cat_silders {
    display: none;
    width: 100%;
    padding: 4.2rem 0.0rem 0.0rem 0.0rem;
    position: fixed;
}

@media (max-width: 815px) {
    .col-4 {
        display: none;
    }

    .cat_silders {
        display: block;
        width: 100%;
        padding: 4.0rem 0.0rem 0.0rem 0.0rem;

    }

    .servicebar_scrol {
        overflow-y: scroll;
        height: 65.8rem;
        width: 100%;
    }

    .card_services {
        margin: 0rem 0.3rem;
        padding: 5.5rem 0.5rem 0.5rem 0.5rem;
    }
}



@media (max-width: 674px) {
    .section-heada h4 {
        font-size: 18px;
    }

    .card_services_button {
        border-radius: 6.476px;
        height: 2rem;
        width: 7rem;

    }

    .card_services_button button {
        height: 2rem;
        width: 5rem;
    }

    .servicebar_3 img {
        width: 188px;
        height: 121px;
    }

    .card_services {
        margin: 0rem 0.3rem;
        padding: 5.5rem 0.5rem 0.5rem 0.5rem;
    }
}




@media (max-width: 1300px) {

    .card_services {
        margin: 0rem 8.5rem;
        padding: 1.2rem 0.5rem 0.5rem 0.5rem;
    }
}


@media (max-width: 1257px) {

    .card_services {
        margin: 0rem 6rem;
        padding: 1.2rem 0.5rem 0.5rem 0.5rem;
    }
}

@media (max-width: 1180px) {

    .card_services {
        margin: 0rem 3rem;
        padding: 1.2rem 0.5rem 0.5rem 0.5rem;
    }
}

@media (max-width: 1049px) {
    .col-4 {
        display: none;
    }

    .cat_silders {
        display: block;
        width: 100%;
        padding: 5rem 0.0rem 0.0rem 0.0rem;
    }




    .servicebar_scrol {
        margin-top: 96px;
    }

    .row {
        justify-content: center;
    }

    .servicebar_scrol {
        overflow-y: scroll;
        height: 65.8rem;
        width: 100%;
    }

    .card_services {
        margin: 0rem 0.8rem;
        padding: 0rem 0rem 0rem 0rem;
    }

    

}

.moblie_service {
    display: none;
}

@media (max-width: 560px) {
    .card_services_heading{
        display: none;
    }
    .servicebar_scrol {
        display: none;
    }

    .moblie_service {
        display: block;
        
    }
}

@media (max-width: 455px) {

    .cat_silders {
        padding: 4.3rem 0.0rem 0.0rem 0.0rem;
    }

}

@media screen and (max-width: 445px) {}


.container {
    padding: 0;
}

.col-s {
    padding: 0px;
}

.SEEF-card {
    height: 95px;
    width: 120px;

}

.card-body ul {
    font-size: 12px;
    margin-left: -10px;
    margin-bottom: 0;
}

.card-body ul li {
    color:#A3080C;
    font-size: 12.5px;

}

.card-body ul li span {
    color: black;
    font-size: 12px;
}

.SEEF-card img {
    width: 100%;
    height: 100%;
    border-radius: 2px;
    background-size: cover;
    background-position: center;
}

.card_1 {
    background: #FFF;
    border: 1px solid rgb(192, 191, 191);
}

.card_1 h6 {
    font-size: 14px;
    margin: 0;
    padding-top: 10px;
    padding-left: 10px;
}


.moblie_service {
    padding: 0;
    margin: 0;
    margin-bottom: 3.8rem;
    position: absolute;
    top:180px;
    z-index: -1;
    height: 100vh;
    overflow-y: scroll;
}

.moblie_service::-webkit-scrollbar {
    display: none;
}



.button_serss {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    background-color:#A3080C;
    padding: 7px;
    border-radius: 5px;
    margin-left: 5px;

}

.button_serss:hover {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #080808;
    border:#A3080C 2px solid;
    background-color: #ffffff;
    padding: 5px;
    border-radius: 5px;
    margin-left: 5px;
}

.button_serss p {
    font-size: 12px !important;
    margin: 0 !important;
    padding: 0 !important;
}

.tags-group {
    display: flex;
    align-items: center;
    padding-top: 8px;
}

.tags-group p {
    background-color: #e888ac38;
    margin-left: 5px !important;
    padding: 4px 4px 4px 4px !important;
    border-radius: 5px;
    color:#A3080C !important;
}

.card_1 p {
    font-size: 12px;
    margin: 0;
    padding: 0;
    padding-left: 5px;
    padding-left: 10px;
}

.button-group button {
    border: 2px solid#A3080C;
    background-color: white;
    padding: 3px 4px;
    font-size: 10px;
    border-radius: 3px;
}

.button-group button:hover {
    background-color:#A3080C;
    color: white;
}

.button-group1 button {
    background-color:#A3080C;
    padding: 3px 8px;
    color: white;
    font-size: 10px;

}

.button-group1 button:hover {
    border: 2px solid#A3080C;
}

.card_1 {
    border-left: 10px solid#A3080C;
    border-radius: 8px;
}

.poster {
    background-color: white;
    width: 100%;
    height: auto;
    margin-top: 10px;
}

.service-poster {
    width: 100%;
    height: 40vh;
    background-color: white;
    margin-top: 15px;
}

.service-slider {
    height: 120px;
    width: 70px;
    border-radius: 5px;
    margin-left: 15px;
    margin-top: 15px;
    border-style: none;
}

.service-slider p {
    font-size: 10px;
    font-weight: 1050px;
}

.service-slider img {
    border-radius: 5px;
    height: 70px;
    width: 100%;
}

.rating {
    width: 100%;
    margin-top: -13px;
    display: in-lineflex;
}

/* .rating img{
    width: 13px;
    height: 13px;
  } */
.cat_views::-webkit-scrollbar {
    display: none;
}



.cat_views {
    display: flex;
    overflow-y: scroll;
    background-color: white;
    margin-top: -35px;
    padding-top: 18px;
    box-shadow: 0 4px 10px -4px rgba(0, 0, 0, 0.5); /* Adds a shadow at the bottom */
}

.cat_itmes {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 10px;
    font-family: 'Open Sans', sans-serif;
}

.cat_itmes img {
    width: 52px;
    height: 52px;
    border: 0.5px solid rgb(171, 171, 171);
    border-radius: 50px;

}

.cat_itmes:hover {
    color:#A3080C;
}

.cat_itmes img:hover {
    width: 52px;
    height: 52px;
    border: 2px solid#A3080C;
    border-radius: 50px;
}

.cat_itmesAct img {
    width: 52px;
    height: 52px;
    border: 2px solid#A3080C;
    border-radius: 50px;
}

.cat_itmesAct span {
    padding: 0;
    margin: 0;
    width: max-content;
    margin-top: 7px;
    margin-bottom: 6px;
    text-align: center;
    font-weight: 600;
    font-size: 10px;
    color:#A3080C !important;
}




.cat_itmes span {
    padding: 0;
    margin: 0;
    width: max-content;
    margin-top: 7px;
    margin-bottom: 6px;
    text-align: center;
    font-weight: 600;
    font-size: 10px;
    color: #404040;

}

.subcat_silder {
    background-color: #fffbfb;
    width: 100%;
    display: flex;
    align-self: center;
    font-family: 'Open Sans', sans-serif;
    display: none;
}

.subcat_views::-webkit-scrollbar {
    display: none;
}

.subcat_views {
    display: flex;
    padding: 5px 0px;
    overflow-y: scroll;
    height: 42px;
    background-color: #D6D6D6;
    width: 100%;
}

.subcat_button {
    display: flex;
    align-items: center;
    /* border-radius: 3px; */
    /* border: 1px solid #212529; */
    padding: 10px 10px;
    margin: 0px 7px;
    height: 31px;
    font-family: 'Open Sans', sans-serif;
}

.subcat_button:hover {

    border-radius: 20px;
    background-color:#A3080C;
}


.subcat_button span:hover {
    color: white;

}

.subcat_button span {
    color: #404040;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    width: max-content;

}



.subCat_sec {
    display: -webkit-flex;
    padding-top: 5px;

    white-space: nowrap;
    height: 120px;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    background-color: #f5f5f5c9;

}

.subCat_sec .suv_cat_card img {
    width: 51px;
    height: 51px;
    border-radius: 50px;
}

.subCat_sec .suv_cat_card {
    display: -webkit-flex;
    justify-content: center;
    padding: 8px;
    flex-direction: column;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -moz-box-direction: normal;
    -moz-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    align-items: center;
}

.sub_cat_name {
    font-size: 10px;
    margin-top: 10px;
    margin-bottom: 6px;
    font-weight: 600;
}

.subCat_sec a {
    color: #212121;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;

}


.btnslider {
    width: auto;
    height: 43px;
    background-color: white;
    margin-top: -24px;
    z-index: 1;
    position: sticky;
    overflow-x: auto;
    white-space: nowrap;
    padding: 8px 20px;
}

.btnslider ul {

    margin-top: 8px;
}

.btnslider ul li {
    border-radius: 3px;
    text-align: center;
    height: 27px;
    border: 2px solid rgba(207, 206, 206, 0.612);
    padding: 3px 10px;
    margin-left: 10px;
    list-style: none;
    font-size: 12px;
}

.btnslider ul li:hover {
    border: 1px solid#A3080C;
}

.service-card {
    border-right: 2px solid rgb(211, 211, 211);
    border-bottom: 2px solid rgb(211, 211, 211);
    border-left: 10px solid#A3080C35;
    border-top: 2px solid rgb(211, 211, 211);
    width: 100%;
    height: auto;
    border-radius: 10px;
    overflow: hidden;
    background-color: white;

}
.card_services_up{
    font-family: 'Open sans', sans-serif;
}
/* new card updet */

@media screen and (max-width:1049px){
    .card_services_up .cart_cost{
        display: flex;
        padding: 15px;
        position: fixed;
        bottom: 0;
        height: 55px;
        background: #fff;
        width: 99.9%;
        align-items: center;
        justify-content: center;
        box-shadow: 5px 5px 10px 5px #c3c3c3;
        border-radius: 1px;
    }
    .card_services_up .cart_cost .cart_left span{
        font-size: 14px;
    }
    .card_services_up .cart_cost .cart_right span{
        font-size: 15px;
        font-weight: 500;
        border-radius: 2px;
    }
    .servicebar_scrol{
        margin-bottom: 60px;
    }

   
}
.servicebar_scrol h2 {
    color:#A3080C;
    font-family: 'Open sans', sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

}

.service-box1 {
    display: flex;
    height: auto;
    font-family: 'Open Sans', sans-serif;

}

.card-details1 {
    width: 70%;
    height: auto;
    padding: 10px;

}

.card-details1 h3 {
    font-size: 18px;
    padding: 10px 10px;
    font-weight: 400;
    height: auto;
    color: #212529;
    line-height: 25px;
}

.card-details1 ul {
    margin-top: -5px;
    margin-left: -5px;
    font-size: 13px;
}

.card-details1 ul li {
    line-height: 25px;
    color: #212529;
    font-weight: 500;
}

.card-details2 {
    width: 195px;
    background-image: url('../../assets/images/v682_250.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 195px;
    border-bottom-left-radius: 5px;
    margin-left: 32px;
}

.card-details2 .img-lable {
    width: 131px;
    background-color: white;
    height: 30px;
    float: right;
    margin-top: 15px;
    text-align: center;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;

}

.img-lable p {
    padding-top: 5px;
    font-size: 14px;
    font-weight: 600;
}

.img-lable img {
    margin-top: 8px;
    margin-left: 8px;
    margin-right: 5px;
}

.service-box2 {
    width: 100%;
    height: 57px;
    border-top: 2px solid rgb(218, 215, 215);
    display: flex;
}

.card-details3 {
    width: 45%;
    height: 100px;
    display: flex;

}

.card-details4 {
    width: 40%;
    height: 100%;
}

.card-details4 p {
    font-size: 16px;
    text-align: right;
    padding-top: 15px;
    padding-right: 10px;
}

.card-details5 {
    width: 25%;
    height: 100%;
}

.card-details5 button {
    width: 115px;
    height: 35px;
    color: white;
    border-style: none;
    border-radius: 24px;
    background:#A3080C;
    box-shadow: 0px 4px 4px 0px rgba(201, 23, 94, 0.10);
    font-size: 14px;
}

.increment-decrement-buttons {
    width: 97px;
    height: 35px;
    display: flex;
    border-radius: 24px;
    background:#A3080C;
    justify-content: space-between;
    border: 2px solid#A3080C;
    cursor: pointer;
}

.increment-decrement-buttons span {
    color: white;
    border-radius: none;
    text-align: center;
    width: 40px;
    padding: 3px;
    font-size: 16px;
    font-weight: 700;
}

.increment-decrement-buttons .counter {
    background-color: white;
    color: black;
    font-weight: 700;
    font-size: 16px;
}

.card-details5 .add-btn {
    margin: auto;
    width: 118px;
    margin-top: 10px;
}

.card-details3 .btn-grp {
    width: 310px;
    height: 100%;
    display: flex;
}

.card-details3 .btn-grp span {
    width: auto;
    height: 22px;
    font-size: 11px;
    margin-left: 10px;
    padding: 1px 7px;
    margin-top: 16px;
    text-align: center;
    border-radius: 10px;
    border: 1px solid rgba(147, 41, 75, 0.44);
    background: rgba(147, 41, 75, 0.00);
    color: rgba(147, 41, 75, 0.76);
}

.card-details3 .btn-grp button {
    width: 182px;
    height: 31px;
    border-radius: 7px;
    border: 2px solid rgba(147, 41, 75, 0.79);
    background: rgba(147, 41, 75, 0.00);
    box-shadow: 0px 4px 4px 0px rgba(201, 23, 94, 0.10);
    margin-top: 12px;
    margin-left: 10px;
    color:#A3080C;
    font-family: Open Sans;
    font-size: 13px;
    font-weight: 600;
}
.package-list{
   margin-left: 10px;
    width: 400px;
   height: auto;
}
.package-list label input{
    width: 15px;
    height: 15px;
    margin-top: 2px;
    
    
}
.package-list label input[type='checkbox']{
    accent-color:#A3080C;
}
.package-list label{
    display: flex;
    width: 300px;
    height: 25px;
}
.package-list label p{
   font-size: 13px;
   padding-left: 10px;
}


/* new card  mobile */

.Mobil-cart {
    width: 100%;
    background-color: rgb(255, 255, 255);
    height: auto;
    border-radius: 3px;
    font-family: 'Open Sans', sans-serif;
    /* border-top:2px solid rgb(215, 215, 215) ;
    border-bottom:2px solid rgb(215, 215, 215) ;
    border-right:2px solid rgb(215, 215, 215) ;
    border-left:10px solid #c10f504f; */
    margin-bottom: 30px;
}
.subcat_button.active span {
    color: white;
}

.subcat_button.active {
    border-radius: 20px;
    background-color:#A3080C;
}

.moblie_service {
    width: 100%;
    /* margin-top: 11.5rem; */
}

.moblie_service h2 {
    font-size: 14px;
    font-weight: 600;
    color:#A3080C;
    font-family: 'Open Sans', sans-serif;
    padding-left: 10px;

}

.cart-box {
    width: 100%;
    height: 129px;
    display: flex;
}

.cart-box1 {
    width: 135px;
    height: 125px;

}

.cart-box1 img {
    width: 120px;
    height: 120px;
    margin-top: 5px;
    margin-left: 5px;
}

.cart-box2 {
    width: 75%;
    height: 100%;

}

.cart-box2 h3 {
    font-size: 12px;
    padding: 15px 15px;
    font-weight: 600;
    color: #212529;
}

.cart-box2 h4 {
    font-size: 12px;
    padding-left: 15px;
    font-weight: 700;
    padding-top: 5px;
    color: #212529;
}

.cart-box2-btn {
    width: 100%;
    height: 40px;
    display: flex;
    margin-top: 10px;

}

.cart-box2-btn p {
    font-size: 10px;
    padding-top: 13px;
    padding-left: 15px;
    width: 50%;
    font-weight: 600;
}

.cart-addbtn {
    width: 50%;
    float: right;
}

.cart-addbtn button {
    width: 125px;
    font-size: 12px;
    height: 30px;
    margin-top: 5px;
    border-radius: 20px;
    background-color: #ffffff;
    border: 2px solid#A3080C;
    color:#A3080C;
    float: right;
    margin-right: 10px;
    font-weight: 700;
}

.cart-addbtn .increment-decrement-mobile {
    width: 95px;
    height: 30px;
    display: flex;
    border-radius: 20px;
    border: 2px solid#A3080C;
    cursor: pointer;
    float: right;
    margin-right: 10px;
    margin-top: 5px;
}

.cart-addbtn .increment-decrement-mobile span {
    color:#A3080C;
    border-radius: none;
    text-align: center;
    width: 40px;
    padding: 1px;
    font-size: 16px;
    font-weight: 700;
}

.cart-addbtn .increment-decrement-mobile .counter {
    color:#A3080C;
    font-weight: 700;
    font-size: 16px;
}

.divider {
    width: 95%;
    height: 2px;
    background-color: #c0bcbc81;
    margin: auto;
}

.cart-details {
    width: auto;
    height: auto;

}

.cart-details h3 {
    font-size: 14px;
    padding-top: 10px;
    padding-left: 10px;
    font-weight: 600;
    color: #212529;
    line-height: 20px;
    margin-bottom: 10px;
}

.card-details4 p {
    font-size: 14px;
    font-weight: 600;
}

.cart-details ul {
    font-size: 12px;
    height: auto;
    margin-left: -5px;

}

.cart-details ul li {
    line-height: 20px;
    color: #212529;
}

.cart-btn {
    width: 100%;
    height: 60px;
    margin-top: -10px;
    display: flex;
}

.btn-box1 {
    width: 25%;

}

.cart-box2-btn span {
    color:#A3080C;
}

.btn-box1 button {
    font-size: 12px;
    height: 30px;
    width: 125px;
    margin-top: 13px;
    margin-left: 10px;
    background-color: #ffffff;
    border: 1.18px solid rgba(147, 41, 75, 0.74);
    color:#A3080C;
    font-weight: 600;
    border-radius: 2px;
}

.btn-div {
    width: 75%;

}

.btn-box2 {
    display: flex;
    float: right;
    width: auto;
    margin-right: 15px;
}

.btn-box2 span {
    font-size: 11px;
    height: 19px;
    width: auto;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 20px;
    margin-left: 5px;
    border-radius: 12px;
    border: 1px solid rgba(147, 41, 75, 0.44);
    color: rgba(147, 41, 75, 0.76);

}
.accordion-header button{
    color:#2b2b2b;
    font-weight: 400;
}
.paakagebtn {
    width: 100%;
}

.paakagebtn .btn-box1 {
    width: 50%;
}

.paakagebtn .btn-box1 button {
    width: auto;
}

/* footer */
.cart_cost {
    position: fixed;
    right: 0;
    bottom: 0;
    border-left: 2px solid #e2e2e2;
    /* display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox; */
    display: flex;
    flex-direction: row;
    -webkit-box-orient: horizontal;
    /* -moz-box-orient: horizontal; */
    -webkit-box-direction: normal;
    /* -moz-box-direction: normal; */
    -webkit-flex-direction: row;
    /* -ms-flex-direction: row; */
    padding: 20px;
    border-top: 2px solid #e2e2e2;
    border-radius: 25px 0 0 0;
    width: 456px;
    height: 80px;
    background: #fff;
    box-shadow: rgb(100 100 111 / 20%) 0 7px 29px 0;
    -webkit-box-shadow: rgb(100 100 111 / 20%) 0 7px 29px 0;
    align-items: center;
    z-index: 1;
}


.cart_left {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -moz-box-direction: normal;
    -moz-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    width: 60%;
    font-size: 13px;
    font-weight: 500
}

.cart_left span {
    font-size: 16px;
    font-weight: 600;
    color: #212121;
}

.cart_right span {
    width: 140px;
    height: 40px;
    color: #fff;
    border-radius: 25px;
    background:#A3080C;
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    font-weight: 500;
}


