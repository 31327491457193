.sidemodal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(47, 47, 47, 0.587);
}

.sidemodal-content {
    width: 300px;
    height: 100vh;
    background-color: rgb(254, 254, 254);
}

.cancelbtn {
    width: 100%;
    height: 130px;
    background-repeat: no-repeat;
    background-image: url("../../assets/images/Side_menu1.png");
    background-size: cover;
    background-position: center;
}

.cancelbtn img {
    width: 25px;
    height: 25px;
    float: right;
    margin: 10px;
}

.sidemenu-content {


    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 9px;



}

.sidemenu-content button {

    width: auto;

    height: 28px;
    border-radius: 5px;

    font-size: 14px;
    color:#A3080C;
    border: 2px solid#A3080C;
    background-color: transparent;
    margin-right: 20px;
}

.sidemenu-content p {
    margin: 0;
    padding: 0;
    font-size: 15px;
}

/* .phmenu-div{
    width: 100%;
} */
.phmenu-div {
    width: 100%;
    margin: auto;
    height: 630px;
    margin-top: 10px;
    background-color: rgb(247, 246, 246);
}

.phmenu-div ul {
    width: 100%;
    margin: auto;
    padding-top: 10px;
}

.phmenu-div ul a li {
    height: 45px;
    font-family: open sans;
    width: 110%;
    margin-left: -30px;
    padding: 10px;
    border-bottom: 2px solid rgb(235, 235, 235);
    list-style: none;
    justify-content: center;

}

.phmenu-div ul a li:hover {
    border-radius: 2.475px;
    border: 1.238px solid rgba(152, 19, 19, 0.20);
    background: rgba(152, 19, 19, 0.12);
}

.phmenu-div ul a {
    width: 100%;
    text-decoration: none;
    /* color: rgb(61, 61, 61); */
    color:#2b2b2b;

}

.phmenu-div ul a li .nexticon {
    float: right;
    margin-right: 10px;
    margin-top: 5px;
}

.phmenu-div ul a li .usericon {
    margin-left: 5px;
    margin-right: 10px;
    margin-top: -5px;
}

.phmenu-div .active {
    border: 2px solid #c9175ed3;
    background-color: #c9175e56;
    border-radius: 5px;
}

.phmenu-div .logout-btn {
    width: 50%;
    margin: auto;
    margin-top: 45px;
}

.popup-logo {
    width: 56%;
    margin: auto;
    margin-top: 11px;
}

.popup-logo p {
    text-align: center;
    font-size: 12px;
    padding-top: 5px;
}

.media {
    margin-top: -5px;
    display: flex;
    justify-content: space-between;
}

/* .media2 {
    margin-left: 55px;
} */

/* profile page css */

.profilemenu-div {
    width: 100%;
    margin: auto;
    height: 780px;
    padding-top: 50px;
    background-color: rgb(247, 246, 246);
}

.profilemenu-div ul {
    width: 100%;
    margin: auto;
    padding-top: 10px;
}

.profilemenu-div ul a li {
    height: 55px;
    font-family: open sans;
    width: 100%;
    margin-left: -15px;
    padding-top: 10px;
    border-bottom: 2px solid rgb(235, 235, 235);
    list-style: none;
    justify-content: center;
    font-size: 19px;
}

.profilemenu-div ul a li:hover {
    border-radius: 2.475px;
    border: 1.238px solid rgba(152, 19, 19, 0.20);
    background: rgba(152, 19, 19, 0.12);
}

.profilemenu-div ul a {
    width: 100%;
    text-decoration: none;
    color: rgb(61, 61, 61);

}

.profilemenu-div ul a li .nexticon {
    float: right;
    margin-right: 10px;
    margin-top: 5px;
}

.profilemenu-div ul a li .usericon {
    margin-left: 5px;
    margin-right: 10px;
    margin-top: -5px;
}

.profilemenu-div .active {
    border: 2px solid #c9175ed3;
    background-color: #c9175e56;
    border-radius: 5px;
}

.profilemenu-div .logout-btn {
    width: 50%;
    margin: auto;
    margin-top: 90px;

}

.profilemenu-div .logout-btn button {
    border-radius: 8px;
    width: 165px;
    height: 40px;
    font-size: 16px;
    margin: auto;
    font-weight: 600;
    margin-left: 10px;
}
.popup-link{
    width: 33px;
    height: auto;

}
.popup-link img{
    width: 100%;
    height: 100%;
}