.bookingnon{
    
    border: none;
    height: 84vh;
    margin: auto;
    width: 80%;
}
.bookingnon img{
    width: 100%;
    height: auto;
}
.booking_img{
    width: 360px;
   margin: auto;
}
.bookingnon h3{
    text-align: center;
    margin-top: 30px;
    font-size: 16px;
    color: #3A3A3A;
}
.bookingnon p{
    text-align: center;
  
    font-size: 14px;
    color: #3A3A3A;
}

.noaddress h3{
    text-align: center;
    margin-top: 30px;
    font-size: 16px;
    color: #3A3A3A;
}
.noaddress p{
    text-align: center;
  
    font-size: 14px;
    color: #3A3A3A;
}
.noaddress{
    width: 80%;
    margin: auto;
    height: 450px !important;
        margin-top: 20px;
}
@media (max-width:1400px) {
    .bookingnon{
        height: 60vh !important;
    }
}

@media (max-width:1700px) {
    .bookingnon{
        height: 450px !important;
        margin-top: 20px;
    }
    .noaddress{
        
        height: 450px !important;
            margin-top: 20px;
    }
}
@media screen and (max-width:1105px) {
    .Profile-divider .current-content{
        width: 100%;
    }
    .Profile-divider .currentbooking-wrap{
        height: auto;
    }
    .current-content .no-booking{
        width: 70%;
    }
}
@media screen and (max-width:770px){
    .booking-card .current-ftur {
        display: block;
        height: auto;
    }
    .current-ftur .futr2{
        width: 100%;
       margin-bottom: 20px;
    }
    .current-ftur .futr1{
        width: 100%;
    }
    .futr2 .futr2_5{
      
        float:unset;
        margin-left: 10px;
        
        margin-bottom: 10px;
    }
    .current-content .goodwishes h4, p{
        font-size: 14px;
    }
    .booking-card .card-head p {
        font-size: 13px;
        padding-top: 7px;
    }
    .current-content .no-booking{
        width: 80%;
    }
    .bookingnon .booking_img{
        width: unset;
    }
    .bookingnon .address_img{
        width: unset;
    }
}
@media screen and (max-width:470px){
    .curnt-btn button{
        margin-bottom: 10px;
    }
    .futr2_5 .curnt-btn button{
        font-size: 14px;
        height: 30px;
        width: 108px;
    }
    .booking-card .card-head p {
        font-size: 12px;
    }
    .booking-card .current-body span{
        font-size: 13px;
    }
    .current-content .current-pay h5{
        font-size: 16px;
    }
    .current-pay .pay{
        font-size: 14px;
    }
    .current-pay .total table{
        font-size: 14px;
    }
    .current-pay .pay-button{
        width: 90px;
        height: 30px;
    }
    .current-content .no-booking{
        width: 95%;
        height: 300px;
    }
    .current-body li {
        font-size: 12px;
    }
}
@media screen and (max-width:450px){
    .current-body .ord-text li {
        font-size: 12px;
    }
}
.current-content {
    width: 74%;
    height: 73vh;
    border-left: 1px solid rgb(198, 198, 198);
    padding-bottom: 20px;
    overflow: scroll;
    overflow-x: hidden;
}
.current-content::-webkit-scrollbar{
    display: none;
}

.order-content::-webkit-scrollbar{
    display: none;
}

.wallet-content::-webkit-scrollbar{
    display: none;
}

.accordion::-webkit-scrollbar{
    display: none;
}
.currentbooking-wrap{
    background-color: white;
    height: auto;
    width: 100%;
    border-radius: 10px;
    font-family: 'Open Sans', sans-serif;
    margin-top: 20px;
    color: #212121;
}
.currentbooking-wrap .profile-menu{
    width: 350px;
    height: 510px;
    border-right: 0;   
}
.booking-card {
    margin: auto;
    width: 95%;
    height: auto;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin-top: 15px;
    border: 1px solid #cccccc;
   
}
.odr-multiple{
    width: 15%;
}
.odr-multiple h6{
    text-align: right;
    font-size: 16px;
    color:#A3080C;
}
.card-head {
    display: flex;
    width: 100%;
    height: 34px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: rgba(152, 19, 19, 0.25);
}

.card-head p {
    padding-top: 5px;
    font-size: 16px;
    font-weight: 600;
    color: #3A3A3A;
}

.parah2 {
    width: 60%;
   padding-right: 10px;
   text-align: right;
}

.parah1 {
    padding-left: 10px;
    width: 40%;
}

.current-body {
    width: 100%;
    height: auto;
    
    margin-top: 10px;
}

.current-body li {
   
    font-size: 14px;
    margin-top: 5px;
    margin-left: 10px;
}
.ord_details{
    display: flex;
  
    width: 100%;
    height: auto;
}
.ord_box1{
  
    width: 80%;
    height: auto;
}
.ord_box1 h4{
    font-size: 18px;
    padding-left: 30px;
    line-height: 22px;
    word-spacing: 2px;
    color: #3A3A3A;
}
.current-body span {
    color: black;
    font-size: 14px;
}

.current-ftur {
    display: flex;
    width: 100%;
    height: 175px;
   margin-top:30px
}

.futr1 {
    width: 50%;
}

.futr1 h4 {
    font-size: 14px;
    padding: 10px 20px;
}
.futr1 ul li {
    font-size: 14px;
    margin-left: 10px;
}
.futr2{
    width: 50%;
}
.futr2 h4 {
    font-size: 14px;
    padding: 10px 10px;
    color:#A3080C;
    font-weight: 550;
}
.futr2_5{
    width: 300px;
    float: right;
    margin-right: 20px;
}
.futr2 p {
    padding-left: 10px;
    font-size: 14px;
}

.futr2 .curnt-btn {
    width: 100%;
    height: 40px;
    display: flex;
}

.curnt-btn button {
    margin-left: 10px;
    width: 175px;
    height: 40px;
    border-radius: 3px;
    border-style: none;
    background-color:#981313;
    color: white;
    font-size: 16px;
}
.current-pay{
    width: 95%;
    height: auto;
    margin: auto;
    margin-top: 30px;
    border: 1px solid #cccccc;
}
.current-pay h5{
    padding: 20px 20px;
    font-size: 19px;
    
}
.current-pay .pay td{
  padding-top: 10px;
}
.current-pay .pay th{
    padding-top: 10px;
  }
.current-pay table{
    width: 90%;
    margin: auto;
   
}
.current-pay .total{
    padding-top:0px;
}
.pay-button{
    float:right;
    width:237px;
    height: 40px;
    
    margin-top: 30px;
}
.pay-button button{
    width: 100%;
    height: 100%;
    border-radius: 3px;
    background-color:#A3080C;
    color: white;
    border-style: none;
}
.goodwishes{
    width: 90%;
    height: 300px;
    margin: auto;
    padding-top: 100px;
    
}
.goodwishes a{
    color: #3A3A3A;
}
.goodwishes h4{
    font-size: 16px;
    color:#A3080C;
}
.goodwishes p{
    margin-top: 20px;
    font-size:16px ;
}
.no-booking{
    width: 55%;
    height: 390px;
    margin: auto;
   background-color: red;
}
.no-booking img{
    width:100%;
    height: 100%;
}