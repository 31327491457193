.otp-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.otp-inputs {
    display: flex;
    justify-content: center;
}

.otp-input {
    font-family: 'Poppins';
    width: 50px;
    height: 50px;
    font-size: 24px;
    text-align: center;
    margin: 0 5px;
    border: 2px solid #413B3B;
    border-radius: 6px;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.otp-input.empty {
    border-color: #413B3B;
}

.otpactive{
    border-color: #939393 !important;
    box-shadow: 0 3px 10px rgba(220, 6, 106, 0.2) !important;
}

.otp-input:not(.empty) {
    border-color: #E30B5C;
    box-shadow: 0 3px 10px rgba(220, 6, 106, 0.2);
}

.otp-input:focus {
    outline: none;
}

.box-login {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D2D2D2;
    height: 85vh;
}

.box-inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #ffffff;
    /* width: 30%; */
    margin-top: 3rem;
    margin-bottom: 5rem;
    padding: 1.5rem;
    border-radius: 20px;
    box-shadow: 0 3px 10px rgba(220, 6, 106, 0.2);

}

.login_box {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.logo_c {
    height: 5rem;
    width: 9rem;
}

.log_form {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 22rem;
}

.otp-container p {
    font-family: 'Poppins';
    margin-block-end: 0;
    padding-top: 8px;
    padding-bottom: 7px;
    padding-left: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #413B3B;
}

.form_group {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid #888888 !important;
    padding: 4px 0px 4px 0px;
    border-radius: 50px;
    width: 100%;
}

.PhoneInput {
    width: 95%;
}


.PhoneInputCountry {
    margin-right: 20px !important;
}

.PhoneInputInput {
    border: none;
    font-family: 'Poppins';
    font-weight: 600;
    background-color: rgba(255, 255, 255, 0);
}

.PhoneInput:focus {
    outline: none;
}

:focus-visible {
    outline: none;

}

.butn {
    background: #E30B5C;
    border: 1px solid #E30B5C;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    color: #ffffff;
    font-family: 'Poppins';
    margin-top: 20px;
    padding: 7px 30px 7px 30px;
}


.otp-inputs {
    display: flex;
    justify-content: center;

}

.otp-input {
    width: 50px;
    height: 50px;
    font-size: 24px;
    text-align: center;
    margin: 0 5px;
    border: 2px solid #413B3B;
    border-radius: 4px;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.otp-input:focus {
    outline: none;
}

.otp-input:not(.empty) {
    border-color: #E30B5C;
    box-shadow: 0 3px 10px rgba(220, 6, 106, 0.2);
}


@media screen and (max-width: 1059px) {
    .box-login {
        height: 90vh;
    }

    .box-inner {
        margin-top: 6rem;
        margin-bottom: 5rem;
    }

    .form_group {
        width: 90%;
    }

    .log_form p {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 965px) {
    .box-inner {
        width: 36%;
    }

    .form_group {
        width: 70%;
    }
}

@media screen and (max-width: 750px) {
    .box-inner {
        width: 75%;
    }

    .form_group {
        width: 70%;
    }
}