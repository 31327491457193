.service-container {
    width: 100%;
    height: 500px;
}

@keyframes moveFromBottomToTop {
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@media screen and (max-width: 768px) {

    /* .modal{
        display: flex;
        justify-content: flex-start;
        flex-direction: column-reverse;
    } */
    .modal-content {
        width: inherit;

    }

    .Package-content {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(47, 47, 47, 0.893);
    /* display: flex;
    align-items: center;
    justify-content: center;*/
}

.cancel-btn {
    width: 100%;
    height: 45px;

}

.cancel-btn img {
    float: right;
    width: 50px;
    height: 50px;
}

.modal-content {
    /* width: 535px; */
    height: auto;
    border-style: none;
    animation: moveFromBottomToTop 0.5s ease-out;
}

.Package-content {
    background-color: rgb(237, 237, 237);
    width: 100%;
    height: 100%;
    margin-top: 3px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    overflow: hidden;
}

.scroll {
    overflow-y: scroll;
    overflow-x: hidden;

}

.Card_details {
    width: 100%;
    height: 180px;
    background-color: white;

}

.service_text {
    padding: 15px;
    width: 60%;

}

.service_text p {
    line-height: 5px;
    padding-top: 7px;
    font-size: 13px;
}

.rating {
    display: flex;
    margin-top: 20px;


}

.rating p {
    font-size: 14px;
}

.rating img {
    margin-top: 4px;
    /* margin-right: -3px; */

}

.rating1 {
    display: flex;
    margin-top: 20px;

}

.rating1 p {
    font-size: 13px;
}

.rating1 img {
    margin-top: 6px;
    margin-left: 30px;
}

.rating1 h6 {
    margin-left: 10px;
    margin-top: 2px;
}

.details1 {
    margin-top: 4px;
    background-color: white;
    padding: 10px 20px;
}

.details1 ul li {
    color:#A3080C;
    padding-top: 0px;
    font-size: 15px;
}

.details1 ul li span {
    font-size: 13px;
    color: black;
}

.details2 {
    margin-top: 4px;
    background-color: white;
    padding: 10px 5px;
}

.details2 h5 {
    padding-left: 10px;
    color:#A3080C;
    font-size: 14px;
    font-weight: 600;
}

.details2 ul {
    margin-left: 20px;
    padding-left: 1rem;
}

.details2 ul li span {
    font-size: 12px;
    color: #212529;
}

.details2 p {
    font-size: 13px;
    padding: 5px 40px;
}

.details3 {
    margin-top: 4px;
    background-color: white;
    padding: 10px 20px;
}

.details3 h5 {
    color:#A3080C;
    font-size: 19px;
    font-weight: 600;
    padding-left: 10px;
}

.details3 p {
    font-size: 13px;
    padding: 0px 20px;
}

.service-fotr {
    width: auto;
    height: 60px;
    background-color: rgb(255, 255, 255);
    box-shadow: 5px 5px 10px 5px rgb(180, 179, 179);

}

.service-btn {
    margin: auto;
    width: 180px;
    height: 100%;

}

.service-btn button {
    width: 180px;
    height: 40px;
    background-color:#A3080C;
    border-radius: 10px;
    border-style: none;
    margin-top: 10px;

}

/* new pop up */


@media (max-width: 768px) {
    .popup-content .ser-body {
        position: fixed;
        background: #f4f0f0;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
        height: 100vh;
        overflow: auto
    }

   

    .cancel-btn {
        display: none;
    }

    .cart_footer .cart_left span {
        font-size: 13px;
        padding-left: 5px;
    }
    .ser-body .cart_footer {
        height: 55px;
    }
    
    .ser-body .cart_footer .cart_right button {
        border-radius: 3px;
        width: 150px;
        height: 38px;
        background:#A3080C;
        border-style: none;
        color: white;
        margin: auto;
        margin-top: -6px;
       
    }

    .ser-body .cart_footer .cart_right .service_cartbtn {
        display: flex;
        border-radius: 20px;
        width: 150px;
        height: 38px;
        background:#A3080C;
        color: white;
        border: 2px solid#A3080C;
        margin-top: -6px;
        
    }
    .ser-body .cart_footer .cart_right .service_cartbtn span{
        font-size: 16px;
        font-weight: 600;
    }
}

.ser-body {
    background-color: rgb(238, 238, 238);
    width: 600px;
    height: 575px;
    border-radius: 3px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

    font-family: 'Open Sans', sans-serif;
    overflow: hidden;
}

.Nav-bar {
    width: 100%;
    height: 48px;
   background-color: white;
   justify-content: center;
   align-items: center;
   position: fixed;
   box-shadow: 0px 0px 10px 3px rgb(180, 180, 180);
}

.Nav-bar table {
    width: 87%;
    height: 48px;
   
    text-align: center;
}

.Nav-bar table tr a th {
    width: 15%;
    padding-top: 10px;
    padding-left: 10px;
}

.ser-body .Nav-bar table tr td {
    font-size: 16px;
    font-weight: 500;
    width: 90%;
}

.ser-body {
    overflow-y: scroll;
}

.ser-body ul li {
    color:#A3080C;
}

.ser-body .service_text h6 {
    font-size: 16px;
    font-weight: 600;

}
.product-detl {
    padding-top: 0px;
    background-color: white;
}
.product-det {
    padding-top: 56px;
    background-color: white;
}
.service_text h6{
    font-size: 14px;
    font-weight: 600;
}
.Service-img {
    width: 274px;
    height: auto;
    padding: 3px;
}

.Service-img img {
    width: 100%;
    height: auto;
}

.cart_footer {
    display: flex;
    /* width: 100%; */
    padding: 12px 22px 12px 22px;
    position: sticky;
    bottom: 0;
    height: 66px;
    background: #fff;
    width: 99.9%;
    align-items: center;
    justify-content: center;
    box-shadow: 5px 5px 10px 5px #c3c3c3;
    margin-top: 3px;
    position: fixed;
}

.cart_footer .cart_left {
    width: 60%;
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    color: #2D2A2A;
 }
.cart_footer .cart_right {
      margin: auto;
}
.cart_footer .cart_right button {
    border-radius: 3px;
    width: 140px;
    height: 38px;
    background:#A3080C;
    border-style: none;
    color: white;
    font-size: 14px;
    margin-top: 3px;
}

.cart_footer .cart_right .service_cartbtn {
    display: flex;
    border-radius: 20px;
    width: 120px;
    height: 38px;
    background:#A3080C;
    color: white;
    border: 2px solid#A3080C;

}

.cart_footer .cart_right .service_cartbtn span {
    background-color: transparent;
    height: 100%;
}

.cart_footer .cart_right .service_cartbtn .counter {
    background-color: white;
    color: black;
    border-radius: 0px;
}