@media (max-width: 768px) {
    
    .popup-content  {
        position: fixed;
        background: #f4f0f0;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 9;
        /* overflow: auto;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 24px;
        border-top-left-radius: 24px; */
    }
    .popup-content .cart_coupon-body {
        /* position: fixed; */
        background: #f4f0f0;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 9;
        overflow: auto;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 24px;
        border-top-left-radius: 24px;
    }

    .popup-content .cart_coupon-body .Coupon-heading .couponbox span {
        font-size: 14px;
        padding-left: 25px;
    }

    .popup-content .cart_coupon-body .offer h4,
    h6 {
        font-size: 14px;
    }

    .popup-content .cart_coupon-body .offer h6 {
        color: #7D7C7C;
        font-size: 12px;
    }

    .popup-content .cart_coupon-body .Couponcontent-box1 p a {
        text-decoration: none;
        font-size: 14px;
        color:#A3080C;
        font-weight: 600;
    }
    
}
.popup-content{
    background-color: transparent;
}
.popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.596);
    /* Semi-transparent background */
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Ensure the pop-up appears above other content */
    transition: opacity 0.5s ease-in-out;
}
.cart_coupon-body {
    background-color: rgb(238, 238, 238);
    width: 442px;
    height: auto;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    font-family: 'Open Sans', sans-serif;
    overflow: hidden;
}

.cart_coupon-body .Coupon-heading {
    height: 120px;

}

.cart_coupon-body .Coupon-heading .couponbox {
    width: 97%;
    height: 40px;
    margin: auto;
    text-align: center;
    margin-top: 15px;
    /* border: 1px solid rgb(191, 191, 191); */
}

.cart_coupon-body .Coupon-heading .couponbox input {
    width: 73%;
    height: 100%;
    /* border: 0; */
    padding-left: 15px;
    border: 1px solid rgb(191, 191, 191);
}

.cart_coupon-body .Coupon-heading .couponbox button {
    text-align: center;
    background-color:#A3080C;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    width: 118px;
    color: white;
   
}

.cart_coupon-body .offer h4,
h6 {
    font-size: 16px;
}

.cart_coupon-body .offer h6 {
    color: #7D7C7C;
    font-size: 14px;
}

.cart_coupon-body .Couponcontent-box1 p a {
    text-decoration: none;
    font-size: 16px;
    color:#A3080C;
    font-weight: 600;
}

/* checkout modal css */

@media (max-width: 768px) {
    .popup-content  {
        position: fixed;
        background: #f4f0f0;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 9;
        /* overflow: auto;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 24px;
        border-top-left-radius: 24px; */
    }
    .popup-content .checkou-body {
        /* position: fixed; */
        background: #f4f0f0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        z-index: 9;
        overflow: auto;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 24px;
        border-top-left-radius: 24px;
    }
    .popup-content .checkou-body .btnbox{
        width: 95%;
    }
}
.popup-content{
    background-color: transparent;
}
.checkou-body {
    background-color: rgb(238, 238, 238) ; 
    width: 598px ;
    height: auto ;
    border-top-right-radius: 10px ;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3) ;
    font-family: 'Open Sans', sans-serif ;
    overflow: hidden;
}
.address-heading{
    padding: 20px;
    background-color: white;
}
.address_link{
    width: 100%;
    background-color: white;
    padding:15px;
    margin-top: 6px;
}
.address1{
    padding: 10px 20px;
    background-color: white;
    margin-top: 6px;
}
.address-type{
    padding: 2px 10px 2px 10px;
    margin: 5px;
    /* border: 1px rgb(147, 41, 75) solid; */
    border-radius: 20px;
    background-color: #e6e6e6;
}
.address2{
    padding: 10px 20px;
    background-color: white;
    margin-top: 6px;
}
.checkou-body .content-body{
    height: auto;
}
.checkou-body .content-body a{
    font-size: 16px;
    color:#A3080C;
    font-weight: 600;
}
.checkou-body .address1 input[type="radio"], .address2 input[type="radio"]{
    accent-color: black;
}

.checkou-body .address1 input,.address2 input {
  margin-top: 2px;
  width: 18px;
}
.checkou-body .ftrbtn{
  width: 100%;
  height: 60px;
  background-color: white;
  margin-top: 7px;
}
.checkou-body .btnbox {
    width: 90%;
    height: 45px;
    margin: auto;
}
.checkou-body .btnbox button{
    width: 100%;
    height: 100%;
    margin-top: 8px;
    background-color:#A3080C;
    color: white;
    border-style: none;
    border-radius: 3px;
    font-size: 16px;
}
/* map modal  --------------------------------------------------*/

.map-ftr{
    width:100%;
    height: 55px;
    background-color: white;

}
.ftr-btn{
    width: 90%;
    height: 45px;
    margin: auto;
    
}
.ftr-btn button{
    width: 100%;
    height: 100%;
    margin-top: 8px;
    border-style: none;
    font-size: 16px;
    color: white;
    background-color:#A3080C
}
.cupon-scroll::-webkit-scrollbar{
    display: none;
}

.cupon-scroll{
    max-height: 320px;
    overflow-y: scroll;
}