/* img {
    max-width: 100%;
    height: auto;
} */

.why_choose_us_card {
    background-color: white;
    border-radius: 14px;
    margin: 1rem 9rem;
    /* background-image: url(../../assets/images/image\ 18.png); */
    background-repeat: no-repeat;
    background-size: 10rem;
}

.card_pad {
    padding: 5rem;
}

.item span:hover {
    display: inline-block;
}

.section-head {
    margin-bottom: 20px;

}

.section-heads h4 {
    position: relative;
    padding: 0;
    color:#A3080C;
    line-height: 1;
    letter-spacing: 0.3px;
    font-size: 34px;
    font-weight: 700;
    text-align: left;
    text-transform: none;
    margin-bottom: 28px;
}

.section-heads h4:before {
    content: "";
    width: 100px;
    height: 3px;
    background:#A3080C;
    position: absolute;
    left: 0px;
    bottom: -15px;
    margin: 0 auto;
}

.section-heads h4 span {
    font-weight: 700;
    padding-bottom: 5px;
    color: #2f2f2f;
}


.section-head h4 {
    position: relative;
    padding: 0;
    color:#A3080C;
    line-height: 1;
    letter-spacing: 0.3px;
    font-size: 34px;
    font-weight: 700;
    text-align: left;
    text-transform: none;
    margin-bottom: 10px;
}

/* .section-head h4:before {
    content: "";
    width: 100px;
    height: 3px;
    text-align: center;
    border-radius: 50px;
    background:#A3080C;
    position: absolute;
    left: 0px;
    bottom: -15px;
    margin: 0 auto;
} */

.col {
    flex: none;
}
.section-head h4 span {
    font-weight: 700;
    padding-bottom: 5px;
    color: #2f2f2f;
}

p.service_text {
    color: #cccccc !important;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
}

.section-head p,
p.awesome_line {
    color: #818181;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
}

.extra-text {
    font-size: 34px;
    font-weight: 700;
    color: #2f2f2f;
    margin-bottom: 25px;
    position: relative;
    text-transform: none;
}

.extra-text::before {
    content: "";
    width: 60px;
    height: 3px;
    background:#A3080C;
    position: absolute;
    left: 0px;
    bottom: -10px;
    right: 0;
    margin: 0 auto;
}

.extra-text span {
    font-weight: 700;
    color:#A3080C;
}

.item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #fff;
    text-align: center;
    height: 20rem;
    padding: 30px 25px;
    -webkit-box-shadow: 0 0px 25px rgba(0, 0, 0, 0.07);
    box-shadow: 0 0px 25px rgba(0, 0, 0, 0.07);
    border-radius: 20px;
    border: 5px solid rgba(0, 0, 0, 0.07);
    margin-bottom: 30px;
    -webkit-transition: all 0.5s ease 0;
    transition: all 0.5s ease 0;
    transition: all 0.5s ease 0s;
}

.item:hover {
    background:#A3080C;
    box-shadow: 0 8px 20px 0px rgba(0, 0, 0, 0.2);
    -webkit-transition: all 0.5s ease 0;
    transition: all 0.5s ease 0;
    transition: all 0.5s ease 0s;
}

.item:hover .item,
.item:hover span.icon {
    background: #fff;
    border-radius: 10px;
    -webkit-transition: all 0.5s ease 0;
    transition: all 0.5s ease 0;
    transition: all 0.5s ease 0s;
}

.item:hover h6,
.item:hover p {
    color: #fff;
    -webkit-transition: all 0.5s ease 0;
    transition: all 0.5s ease 0;
    transition: all 0.5s ease 0s;
}

.item .icon {
    font-size: 40px;
    margin-bottom: 25px;
    color:#A3080C;
    width: 90px;
    height: 90px;
    line-height: 96px;
    border-radius: 50px;
}

.item .feature_box_col_one {
    background: rgba(247, 198, 5, 0.2);
    color:#A3080C;
}

.item .feature_box_col_two {
    background: rgba(255, 77, 28, 0.15);
    color:#A3080C;
}

.item .feature_box_col_three {
    background: rgba(0, 147, 38, 0.15);
    color:#A3080C;
}

.item .feature_box_col_four {
    background: rgba(0, 108, 255, 0.15);
    color:#A3080C;
}

.item .feature_box_col_five {
    background: rgba(146, 39, 255, 0.15);
    color:#A3080C;
}

.item .feature_box_col_six {
    background: rgba(23, 39, 246, 0.15);
    color:#A3080C;
}

.item p {
    font-size: 16.5px;
    line-height: 18px;
    font-weight: 500;
}

.item h6 {
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 20px;
    color: #2f2f2f;
}

.mission p {
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 28px;
    font-weight: 500;
}

.mission i {
    display: inline-block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background:#A3080C;
    border-radius: 50%;
    color: #fff;
    font-size: 25px;
}

.mission .small-text {
    margin-left: 10px;
    font-size: 13px;
    color: #666;
}

.skills {
    padding-top: 0px;
}

.skills .prog-item {
    margin-bottom: 25px;
}

.skills .prog-item:last-child {
    margin-bottom: 0;
}

.skills .prog-item p {
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 10px;
}

.skills .prog-item .skills-progress {
    width: 100%;
    height: 10px;
    background: #e0e0e0;
    border-radius: 20px;
    position: relative;
}

.skills .prog-item .skills-progress span {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background:#A3080C;
    width: 10%;
    border-radius: 10px;
    -webkit-transition: all 1s;
    transition: all 1s;
}

.skills .prog-item .skills-progress span:after {
    content: attr(data-value);
    position: absolute;
    top: -5px;
    right: 0;
    font-size: 10px;
    font-weight: 600;
    color: #fff;
    background: rgba(0, 0, 0, 0.9);
    padding: 3px 7px;
    border-radius: 30px;
}


@media screen and (max-width: 1286px) {
    .why_choose_us_card {
        margin: 4.5rem 2.3rem;
    }
}

@media screen and (max-width: 1048px) {
    .card_pad {
        padding: 1rem;
    }
    .why_choose_us_card {
        margin: 0rem 0.2rem;
        padding-top: 15px;
    }
    .item {
        height: 23rem;
        padding: 30px 25px;
    }
}


@media screen and (max-width: 490px) {
    .card_pad {
        padding: 0.8rem;
    }

    .item {
        height: 23rem;
        padding: 30px 25px;
    }

    .why_choose_us_card {
        margin: 0rem 0.2rem;
        padding-top: 15px;
    }
    .about-body .boxss ul li p span{
        font-size: 15px;
    }
}
.boxss{
    margin: 0.5rem;
}

.boxss h5{  
    font-size: 16px;
    font-weight: 700;
}

.boxss p{  
    font-size: 14px;
}
.boxss ul li p span{
    font-weight: 900;
    color: #3A3A3A;
    font-size: 16px;
}