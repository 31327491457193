@keyframes moveFromBottomToTop {
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@media screen and (max-width: 768px) {

    .modal-content {
        width: inherit;
    }

    .Package-content {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(47, 47, 47, 0.817);
    display: flex;
     align-items: center;
    justify-content: center;
    
}

.cancel-btn {
    width: 100%;
    height: 45px;

}

.cancel-btn img {
    float: right;
    width: 50px;
    height: 50px;
}

.modal-content {

    width: 535px;
    height: auto;
    border-style: none;
    animation: moveFromBottomToTop 0.5s ease-out;

}

.Package-content {
    background-color: rgb(237, 237, 237);
    width: 100%;
    margin-top: 3px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    overflow: hidden;
}

.scroll {
    overflow-y: scroll;
    overflow-x: hidden;

}

.pack-heading {
    background-color: rgb(255, 241, 229);
    height: 150px;
    padding: 20px;

}

.pack-heading h1 {
    font-size: 30px;

}

.pack-body {
    width: 100%;
    height: 410px;
    background-color: white;


}

.pack-body h4 {
    padding: 20px 20px;
    font-size: 18px;
}

.body-content {
    display: flex;
    width: 100%;
    margin-top: 10px;
    height: 50px;

}

.body-content input {
    margin-top: -30px;
    width: 16px;
    margin-left: 20px;
    background-color: black;
    border: 1px solid rgb(230, 230, 230);
}

.body-content input[type='checkbox'] {
    accent-color:#A3080C;
}

.body-content h5 {
    font-size: 15px;
}

.body-content p {
    font-size: 13px;
}

.waxing {
    width: 100%;
    background-color: white;
    margin-top: -7px;
}

.body-footer {


    width: auto;
    height: 100px;
}

.Facial_hair {
    width: 100%;
    background-color: white;
    margin-top: 5px;
}

.Facial {
    width: 100%;
    background-color: white;
    margin-top: 5px;
}

.Detan {
    width: 100%;
    background-color: white;
    margin-top: 4px;
}

.savings {
    width: 100%;
    height: 40px;
    background-color: rgb(3, 109, 67);
}

.savings p {
    text-align: center;
    color: white;
    font-size: 14px;
    padding-top: 7px;
}

.body-prize {
    width: 100%;
    display: flex;
    background-color: white;
    height: 60%;
    margin: auto;
}

.body-prize h6 {
    width: 70px;
    margin: auto;
    font-size: 18px;
    font-weight: 500;
    padding-left: 10px;
}

.body-prize p {
    width: 230px;
    margin: auto;
    font-size: 13px;
    color: rgb(133, 132, 132);
}

.body-prize button {
    width: 100%;
    height: 40px;
    margin-top: 10px;
    background-color:#A3080C;
    color: white;
    font-size: 17px;
}