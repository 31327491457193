.box-login {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D2D2D2;
    height: 85vh;
}
.mobileact{
    display: none !important;
}

.sapcse{
    padding: 16px 16px 16px 16px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.desktop{
    display: block !important;
}

.box-inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #ffffff;
    /* width: 30%; */
    margin-top: 3rem;
    margin-bottom: 5rem;
    padding: 1.5rem;
    border-radius: 20px;
    box-shadow: 0 3px 10px rgba(220, 6, 106, 0.2);

}

.login_box {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.logo_c {
    height: 5rem;
    width: 9rem;
}

.log_form {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 22rem;
}

.log_form p {
    font-family: 'Poppins';
    margin-block-end: 0;
    padding-top: 8px;
    padding-bottom: 7px;
    padding-left: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #413B3B;
}

.form_group {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid #888888 !important;
    padding: 4px 0px 4px 0px;
    border-radius: 50px;
    width: 100%;
}

.PhoneInput {
    width: 95%;
}


.PhoneInputCountry {
    margin-right: 20px !important;
}

.PhoneInputInput {
    border: none;
    font-family: 'Poppins';
    font-weight: 600;
    background-color: rgba(255, 255, 255, 0);
}

.PhoneInput:focus {
    outline: none;
}

:focus-visible {
    outline: none;

}

.butn {
    background: #E30B5C;
    border: 1px solid #E30B5C;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    color: #ffffff;
    font-family: 'Poppins';
    margin-top: 20px;
    padding: 7px 30px 7px 30px;
}

@media screen and (max-width: 1059px) {
    .box-login {
        height: 90vh;
    }

    .box-inner {
        margin-top: 6rem;
        margin-bottom: 5rem;
    }

    .form_group {
        width: 90%;
    }

    .log_form p {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 965px) {
    .box-inner {
        width: 36%;
    }

    .form_group {
        width: 70%;
    }
}

@media screen and (max-width: 750px) {
    .box-inner {
        width: 75%;
    }

    .form_group {
        width: 70%;
    }
}

/* Log in pop up css */

@keyframes moveFromBottomToTop {
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.loginmodal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(47, 47, 47, 0.587);
    display: flex;
    align-items: center;
    justify-content: center;
}

.loginmodal-content {
    width: 600px;
    height: auto;
    background-color: red;
    border-style: none;
    /* animation: moveFromBottomToTop 0.5s ease-out; */
}

.cancel-btn {
    width: 100%;
    height: 45px;
}

.cancel-btn {
    width: 100%;
    height: 40px;
}

.cancel-btn img {
    float: right;
    width: 50px;
    height: 50px;
}

.login-content {
    background-color: rgb(237, 237, 237);
    width: 100%;
    height: 100%;
    margin-top: 8px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    overflow: hidden;
}

/*  */

/* new Popup.css */

@media (max-width: 768px) {
    .mobileact{
        display: block !important;
    }
    .sapcse{
        padding: 6.5rem 16px 16px 16px;
    }
    .desktop{
        display: none !important;
    }
    .popup-content .popup-body {
        position: fixed;
        background: #ffffff;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
        height: 100vh;
        overflow: auto;
        padding: 0px 0px 0px 0px;
    }

    .popup-content .popup-body .Navbar {
        display: block;
    }

    /* .popup-content .popup-body .cancel-btn {
        display: none;
    } */
    .popup-content .popup-body {
        
        margin-top: 0px;
      
    }
    .popup-content .popup-body .login-wrap{
        width: 90%;      
    }
}

.popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    height: 100vh;
    background-color: rgba(0, 0, 0, 0.596);
    /* Semi-transparent background */
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Ensure the pop-up appears above other content */
    transition: opacity 0.5s ease-in-out;
}

.popup.open {
    display: flex;
}

.popup-content {
    background-color: transparent;
}

.popup-body {
    background-color: white;
    width: 500px;
    height: 367px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    margin-top: 15px;
    font-family: 'Open Sans', sans-serif;
    padding: 30px;
}
.popup-body .login-wrap{
    width: 80%;
    margin: auto;
    padding-top: 50px;
    
}
.popup-body .Navbar {
    width: 100%;
    height: 48px;
    display: none;
}

.popup-body .Navbar table {
    width: 90%;
    height: 48px;
    margin-top: -8px;
    text-align: center;
   
    
}

.popup-body .Navbar table tr th {
    width: 15%;
}

.popup-body .Navbar table tr td {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    padding-left: 20px;
    width: 90%;
}

.popup-body h3 {
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    color: #4f4f4f;
}

.popup-body form {
    width: 100%;
    height: 48px;
    margin-top: 40px;
}

.popup-body .phone-input {
    width: 100%;
    height: 48px;
    border: 1px solid #818181;
    display: flex;
    border-radius: 3px;
    margin-top: 40px;
}

.popup-body .phone-input span {
    width: 61px;
    height: 45px;
    background-color: white;
    padding: 12px;
    border-right: 1px solid #918383;
}

.phone-input input {
    width:400px ;
    padding: 10px;
    border: 0px;
    height: 48px;
}
.login-btn {
    margin-top: 40px;
    width: 100%;
    height: 45px;
    top: 10px;
    right: 10px;
    color: white;
    background:#A3080C;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 3px;
    font-weight: 600;
}

.popup-body .login-btn {
    margin-top: 20px;
    width: 100%;
    height: 45px;
    top: 10px;
    right: 10px;
    color: white;
    background:#A3080C;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 3px;
    font-weight: 600;
}

.popup-body label {
    width: 100%;
    padding-top: 5px;
    display: flex;
    margin-top: 10px;
}

.popup-body label input {
    width: 19px;
    height: 19px;
    margin-top: 7px;
}

.popup-body label span {
    margin-top: 6px;
    font-size: 14px;
    padding-left: 5px;
    color: #686868;
}

.popup-body label input[type="checkbox"] {
    width: 30px;
    accent-color:#A3080C;
}

.popup-body h4 {
    font-size: 12px;
    color: #686868;
    font-weight: 400;
    margin-top: 7px;
    line-height: 20px;
    text-align: left;
}

.popup-body h4 span {
    color:#A3080C;
    font-weight: 600;
    font-size: 12px;
}


/* otp css */

.optpopup{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.03);
    /* Semi-transparent background */
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Ensure the pop-up appears above other content */
    transition: opacity 0.3s ease-in-out;
}
.popup-body p{
    text-align: center;
    font-size: 14px;
    color: #818181;
    margin-top: 10px;
}
.popup-body .otp-inputs .otp-input{
    margin-top: 10px;
    border-color: rgb(200, 200, 200);
    box-shadow: none;
}
.popup-body h6{
    text-align: center;
    margin-top: 25px;
    color: #cc1c5dde;
}