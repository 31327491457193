@media screen and (max-width:1049px) {
    .frontpage .home-slider {
        padding-top: 50px;
    }
    .home-slid .banner{
        margin-top: -10px;
    }
    .banner .slid-img {
        height: 290px;
    }

    .frontpage .card-container {
        height: 1242px;
        margin-top: -75px;
    }

    .frontpage .card-container h3 {
        padding-top: 70px;
        font-size: 20px;

    }

    .Service-box .service-dis {
        display: block;
    }

    .choose1 .brand1 {
        width: 150px;
        margin: auto;
    }

    .choose1 .brand1 img {
        width: 150px;
        height: 80px;
    }

    .choose1 .brand2 {
        width: 200px;
        margin: auto;
    }

    .choose1 .brand2 img {
        width: 200px;
        height: 70px;
    }

    .choose1 .brand3 {
        width: 160px;
        margin: auto;
    }

    .choose1 .brand3 img {
        width: 160px;
        height: 70px;
    }

    .choose1 .brand4 {
        width: 160px;
        margin: auto;
    }

    .choose1 .brand4 img {
        width: 160px;
        height: 70px;
    }

    .choose1 .brand5 {
        width: 160px;
        margin: auto;
        margin-top: -70px;
    }

    .choose1 .brand5 img {
        width: 150px;
        height: 150px;
    }

    .choose1 .brand6 {
        width: 160px;
        margin: auto;
    }

    .choose1 .brand6 img {
        width: 160px;
        height: 70px;
    }

    .brand-box .choose-img1 {
        margin: auto;
        height: 114px;
        margin-bottom: 20px;
    }

    .choose1 .choose-img {
        margin: auto;
        width: 110px;
    }

    .choose1 .choose-img img {
        width: 110px;
        height: 110px;
        margin: auto;
    }

    .choose-img2 .choose1 h4 {
        font-size: 18px;
    }
}

@media screen and (max-width:768px) {
    .frontpage .card-container h3 {
        padding-top: 0px;
    }

    .banner .overlay {
        display: none;
    }

    .Brand-sec .question h4 {
        font-size: 18px;
    }

    
    
}

@media screen and (max-width:700px) {
    .frontpage .card-container h3 {
        padding-top: 0px;
        font-size: 18px;
    }

    .frontpage .card-container {
        width: 100%;
        height: 1120px;
        padding-top:100px;
    }
    
    .home-slider .displnon {
        display: block;

    }

    .home-slider .banner {
        width: 100%;
        margin: auto;
    }

    .home-slider .home-slid {
        margin-top: 10px;
    }

    .frontpage .home-slider {
        background-color: white;
    }

    .displnon .search-container {
        width: 95%;
        margin: auto;
        margin-top: 10px;

    }

    .displnon .search-container input {
        height: 45px;
        border-radius: 7px;
        border: 1px solid  #d8cece;
        background: #FFF;
    }

    .banner .slid-img {
        height: 185px;
    }

    

    .Service-box .Service-box1 .box-img {
        width: 95px;
        margin: auto;
    }

    .card-container .Service-box {
        margin-top: 30px;
        height: 120px;
    }

    .card-container .martop {
        margin-top: 5px;
    }

    .Service-box .Service-box1 .box-img img {
        width: 95px;
        height: 72px;
    }

    .Service-box .Service-box1 {
        width: 150px;
        height: 115px;
        margin: auto;
    }

    .Service-box .Service-box1 a p {
        font-size: 14px;
    }

    .choose1 .brand1 {
        width: 100px;
        margin: auto;
    }

    .choose1 .brand1 img {
        width: 100px;
        height: 50px;
    }

    .choose1 .brand2 {
        width: 150px;
        margin: auto;
    }

    .choose1 .brand2 img {
        width: 150px;
        height: 40px;
    }

    .choose1 .brand3 {
        width: 110px;
        margin: auto;
    }

    .choose1 .brand3 img {
        width: 110px;
        height: 40px;
    }

    .choose1 .brand4 {
        width: 110px;
        margin: auto;
    }

    .choose1 .brand4 img {
        width: 110px;
        height: 40px;
    }

    .choose1 .brand5 {
        width: 110px;
        margin: auto;
        margin-top: -70px;
    }

    .choose1 .brand5 img {
        width: 110px;
        height: 115px;
    }

    .choose1 .brand6 {
        width: 110px;
        margin: auto;
    }

    .choose1 .brand6 img {
        width: 110px;
        height: 40px;
    }

    .brand-box .choose-img1 {
        margin: auto;
        height: 80px;
        margin-bottom: 20px;
    }

    .choose1 .choose-img {
        margin: auto;
        width: 80px;
    }

    .choose1 .choose-img img {
        width: 80px;
        height: 80px;
        margin: auto;
    }

    .choose-img2 .choose1 {
        height: 155px;
    }

    .choose-img2 .choose1 h4 {
        font-size: 16px;
        padding-top: 10px;
    }

    .why-choose .choose-img2 {
        margin-top: 25px;
    }

    .frontpage .why-choose {
        height: auto;
        padding-bottom: 30px;
        margin-top: 20px;
    }

    .frontpage .why-choose h2 {
        padding-top: 25px;
        font-size: 18px;
    }

    .selling-parent .selling-scroll {
        width: 90%;
        margin: auto;
        margin-top: 20px;
    }

    /* .selling-scroll .selling-card {
        width: 380px;
        height: 219px;
        display: flex;
    } */

    /* .selling-scroll .selling-card img {
        width: 380px;
        height: 219px;
    } */

    .selling .selling-parent h2 {
        font-size: 18px;
    }

    .frontpage .testmonial {
        height: 392px;
    }

    .frontpage .testmonial h2 {
        font-size: 18px;
        padding-top: 20px;
    }

    .testmonial .testmonial-card-wrap {
        margin-top: 15px;
    }

    .frontpage .Brand-sec h2 {
        font-size: 18px;
        padding-top: 25px;
    }

    .home-slider .dnon {
        display: block;
        display: flex;
        margin: auto;
        width: 95%;
        justify-content: left;
        padding-left: 5px;
        /* margin-top: 3px;
        margin-bottom: 15px; */
    }
}

@media screen and (max-width:448px) {

    .banner .slid-img {
        height: 145px;
    }

    .control-dots {
        margin-bottom: 0rem !important;
    }

    .slider-container {
        padding-top: 2.2rem;
    }

    .frontpage .card-container {
        height: auto;
        padding-bottom: 10px;
    }

    .frontpage .card-container h3 {
        padding-top: 0px;
    }

    .card-container .Service-box {
        margin-top: 0px;
        height: 130px;
    }

    .Service-box .Service-box1 a p {
        font-size: 13px;
    }

    .card-container .martop {
        margin-top: 0px;
    }

    .card-container .Service-box {
        padding-top: 30px;
    }

    .choose1 .brand1 {
        width: 74px;
        margin: auto;
    }

    .choose1 .brand1 img {
        width: 74px;
        height: 37px;
    }

    .choose1 .brand2 {
        width: 100px;
        margin: auto;
    }

    .choose1 .brand2 img {
        width: 100px;
        height: 37px;
    }

    .choose1 .brand3 {
        width: 70px;
        margin: auto;
    }

    .choose1 .brand3 img {
        width: 70px;
        height: 33px;
    }

    .choose1 .brand4 {
        width: 84px;
        margin: auto;
    }

    .choose1 .brand4 img {
        width: 84px;
        height: 37px;
    }

    .choose1 .brand5 {
        width: 70px;
        margin: auto;
        margin-top: -50px;
    }

    .choose1 .brand5 img {
        width: 70px;
        height: 90px;
    }

    .choose1 .brand6 {
        width: 87px;
        margin: auto;
    }

    .choose1 .brand6 img {
        width: 87px;
        height: 37px;
    }


    .Brand-sec .brand-box .choose-img1 {
        margin: auto;
        height: 30px;
        margin-bottom: 40px;
        margin-top: 38px;
    }

    .choose-img2 .choose1 h4 {
        font-size: 11px;
        padding-top: 10px;
    }

    .choose1 .choose-img {
        margin: auto;
        width: 50px;

    }

    .choose1 .choose-img img {
        width: 50px;
        height: 50px;
        margin: auto;
    }

    .choose-img2 .choose1 {
        height: 100px;
    }

   
}

@media screen and (max-width:574px) {
    .frontpage .card-container {
        width: 100%;

    }

    .card-container .Service-box {
        width: 95%;

    }

    .Service-box .Service-box1 .box-img {
        width: 90px;
        margin: auto;
    }

    .Service-box .Service-box1 .box-img img {
        width: 90px;
        height: 72px;
    }

    .Service-box .Service-box1 {
        width: 150px;
        height: 115px;
        margin: auto;
    }

    .Brand-sec .brand-box {
        margin-top: 25px;
        width: 95%;
    }

    .frontpage .Brand-sec {
        margin-top: 20px;
    }

    .why-choose .choose-img2 {
        width: 100%;
    }

    .card-container.custom-margin {
        margin-top: -303px;
      }

      .card-container.custom-margin  h3 {
        text-align: left;
        margin-left: 20px;
    }
    
    /* .card-container.custom-margin.subcat {
        margin-top: -223px;
      } */

      .card-container.custom-margin.subcat  h3 {
        text-align: left;
        margin-left: 20px;
    }

}
@media screen and (max-width:768px) {

.mobile-bnr{
    display: block;
}
.home-slid{
    display: none;
}

.mobile-banner .slide-img {
    width: 100%;
    margin: auto;
    height: 172px;
   margin-top: 15px;
}
.frontpage .card-container{
    margin-top: -178px;
}

.card-container.custom-margin {
           margin-top: -241px;
  }

.frontpage .card-container h3{
    padding-top: 10px;
}

}
/* .home-slider {
    height: auto;
    width: 100%;
}

.home-slider .slider-container {
    height: auto;
    border-bottom: 2px solid gray;
} */

.question{
    height: auto;
    margin-bottom: 20px;
}

.card-container {
    width: 80%;
    margin: auto;
    height: 840px;
    background-color: white;
    font-family: 'Open Sans', sans-serif;
    border-radius: 3px;
    margin-top: -50px;
}

.card-container h3 {
    color: #413B3B;
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    padding-top: 89px;
}

.card-container h3 span{
    color:#A3080C;
}

.home-service-box h3 span {
    color:#A3080C;
}

.service-dis {
    display: none;
}

.Service-box {
    width: 90%;
    height: 170px;
    margin: auto;
    margin-top: 50px;
    display: flex;

}

.displnon {
    display: none;
}

.martop {
    margin-top: 10px;
}

.dnon {
    display: none;
}


.Service-box1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 200px;
    height: 170px;
    margin: auto;
}

.Service-box2 {
    width: 190px;
    height: 160px;
    margin-left: 60px;
}

.Service-box2 .box-img {
    width: 159px;
    margin: auto;

}

.Service-box2 img {
    width: 159px;
    height: 121px;
    justify-content: center;
    align-items: center;
}

.Service-box2 p {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    padding-top: 5px;
}

.Service-box1 .box-img {
    width: 159px;
    margin: auto;
}

.Service-box1 img {
    width: 159px;
    height: 121px;
    justify-content: center;
    align-items: center;
}

a {
    text-decoration: none;
}

.Service-box1 a p {
    text-decoration: none;
    color: #000;
    text-align: center;
    font-size: 17px;
    font-weight: 400;
    padding-top: 10px;
}

/* card slider */
.selling-parent {
    font-family: 'Open Sans', sans-serif;
    margin-top: 26px;
}

.selling-parent h2 {
    text-align: center;
    color: #413B3B;
    font-size: 28px;
    font-weight: 600;
}

.selling-parent h2 span {
    color:#A3080C;
    font-weight: 600;
}

.selling-scroll {
    width: 100%;
    overflow-y: scroll;
    display: flex;
    padding: 3px;
    margin-top: 30px;
}

.selling-scroll::-webkit-scrollbar {
    display: none;
}

.selling {
    width: auto;
    height: auto;
}

.mleft {
    margin-left: 20px;
}

.selling-card {
    margin: 10px !important;
}


.selling-card img {
    width: 100%;
    height: auto;
    padding-right: 15px;
}

@media screen and (max-width:574px) {
    .selling-card img {
        width: 97%;
        height: auto;
        padding-right: 7px;
    }
}

/* testmonial */
.testmonial {
    width: 100%;
    height: 476px;
    background-color: white;
    font-family: 'Open Sans', sans-serif;
    margin-top: 36px;
}

.testmonial h2 {
    text-align: center;
    color:#A3080C;
    font-size: 28px;
    font-weight: 600;
    padding-top: 40px;
}

.testmonial-card-wrap {
    width: 90%;
    margin: auto;
    margin-top: 40px;
}

.why-choose {
    width: 100%;
    height: 829px;
    background: #FDF6EC;
    margin-top: 40px;
    font-family: 'Open Sans', sans-serif;
}

.why-choose h2 {
    text-align: center;
    font-size: 28px;
    color: #363535;
    text-align: center;
    font-family: Open Sans;
    font-weight: 600;
    padding-top: 70px;
}

.why-choose h2 span {
    color:#A3080C;
}

.choose-img1 {
    display: flex;
    width: 90%;
    margin: auto;
    margin-top: 100px;
    height: 160px;
}

.choose-img2 {
    display: flex;
    width: 90%;
    margin: auto;
    margin-top: 100px;
    height: auto;
}

.choose1 {
    width: 300px;
    height: 200px;
    margin: auto;

}

.choose-img {
    margin: auto;
    width: 118px;
}

.choose-img img {
    width: 118px;
    height: 118px;
    margin: auto;
}

.choose1 h4 {
    font-size: 20px;
    text-align: center;
    padding-top: 20px;
    color: #2D2A2A;
}

.mobile-taglin {
    width: 50%;
    /* display: flex; */
    flex-direction: row;
    height: 30px;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.Brand-sec {
    width: 100%;
    height: auto;
    background-color: white;
    margin-top: 50px;
    font-family: 'Open Sans', sans-serif;
    /* margin-bottom: 30px; */
}

.Brand-sec h2 {
    text-align: center;
    padding-top: 60px;
    font-size: 28px;
    color: #212121;
    font-weight: 600;
}

.Brand-sec h2 span {
    color:#A3080C;
}

.brand-box {
    border: 2px solid #C0BCBC;
    width: 90%;
    margin: auto;
    margin-top: 70px;
    height: auto;
}

.brand-box .choose-img1 {
    width: 100%;
    margin-top: 70px;
}

.brand1 {
    margin: auto;
    width: 200px;
}

.brand1 img {
    width: 200px;
    height: 100px;
}

.brand2 {
    margin: auto;
    width: 236px;
}

.brand2 img {
    width: 266px;
    height: 100px;
}

.brand3 {
    margin: auto;

    width: 200px;
}

.brand3 img {
    width: 200px;
    height: 80px;
}

.brand4 {
    margin: auto;

    width: 220px;
}

.brand4 img {
    width: 220px;
    height: 100px;
}

.brand5 {
    margin: auto;
    width: 150px;
    margin-top: -50px;
}

.brand5 img {
    width: 150px;
    height: 150px;
}

.brand6 {
    margin: auto;

    width: 200px;
}

.brand6 img {
    width: 200px;
    height: 90px;
}

.mobile-taglin p{
    color: #757575;
}

/* banner slider */
.home-slider {
    width: 100%;
    overflow: hidden;
    height: 400px;

}

.banner {
    width: 100%;
    height: 400px;

    margin: auto;
}

.slid-img {
    width: 100%;
    margin: auto;
    height: 350px;
    
}

.slide-img img {
    width: 100%;
    height: auto;
} 

.slid-img img {
    width: 100%;
    height: 100%;
}

.overlap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.26);
}

.location_dropdown {
    font-weight: 500;
    margin-left: 10px;
    width: 556px;
    height: 54px;
    font-size: 16px;
    border-radius: 9px;
    border: 2px solid #E4E4E4;
    background: #FFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.loc_menu {
    display: flex;
    align-items: center;
    margin-left: 10px;

}

.loc_menul {
    width: 245px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.location_dropdown h3 {
    font-size: 16px;
    padding-top: 8px;
    font-weight: 400;
    color: #757575;
    text-align: left;
    display: inline;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;
   
}

.crntloc {
    float: right;
    margin-top: -30px;
}

.dropdoun-menu {
    width: 280px;
    height: 106px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #E6E6E6;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.42);
    margin-top: 10px;
    cursor: pointer;
    position: absolute;
    z-index: 1000;
    padding-bottom: 10px;
}

.lctn {

    padding: 10px;
    margin-top: -20px;
}

.lctns {

    padding: 10px;
    margin-top: 15px;
}

.arrowup {
    margin-top: -37px;
    margin-left: 10px;

}

.menu-input input {
    width: 300px;
    height: 100%;
    margin-left: 9px;
    border: none;
    color: rgba(65, 59, 59, 0.44);
    font-size: 12px;
}

.menu-input {
    width: 221px;
    height: 40px;
    margin-top: 10px;
    margin: auto;
    display: flex;
    border-radius: 6px;
    border: 1px solid #E4E4E4;
    background: rgba(217, 217, 217, 0.00);
    justify-content: center;
    align-items: center;
}

.divdr {
    width: 95%;
    margin: auto;
    height: 1px;
    background-color: #EAE5E5;
    margin-top: -5px;
    margin-bottom: 7px;
}

.lctn h4 {
    font-size: 13px;
    padding-top: 5px;
}

/* .hpKsA_EQ {
    width: 221px;
    height: 48px;
    box-sizing: border-box;
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    margin: 16px;
    position: relative
}
.iHQOQ61V {
    position: absolute;
    left: 18px;
    top: 0;
    bottom: 0;
    height: 18px;
    margin: auto;
    z-index: 6
}
.fdXKyLVN {
    width: 180px;
    margin: 14px 16px 14px 48px
} */


.server_drop {
    width: 39.3%;
    position: absolute;
    height: auto;
    margin-left: 5px;
    background: #FFF;
    border-radius: 9px;
    border: 2px solid #E4E4E4;
    margin-top: 60px;
    overflow-y: scroll;
}


/* General styles for grid container */
.grid-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* Desktop layout with 5 columns */
    gap: 0px;
    /* Gap between grid items */
    padding: 20px 60px;
}

/* Styles for grid items (individual blocks) */
.grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Service-boxx {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    height: 195px;
    margin-bottom: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
}
/* .Service-boxx:hover{
    border-radius: 12px;
    box-shadow: 0px 0px 8px -2px gray;
} */
.Service-boxx p {
    text-decoration: none;
    color: #2b2b2b;
    text-align: center;
    font-size: 17px;
    font-weight: 400;
    padding-top: 10px;
   line-height: 18px;
}

.box-img img {
    width: 160px;
    height: 121px;
    border-radius: 10px;
}
@media (max-width: 1320px) {
    .grid-container {
        grid-template-columns: repeat(4, 1fr);
        /* Mobile layout with 3 columns */
    }
}

@media (max-width: 1320px) {
    .grid-container {
        padding: 20px;
    }
    
}

@media (max-width: 1020px) {
    .grid-container {
        grid-template-columns: repeat(3, 1fr);
        /* Mobile layout with 3 columns */
    }
    
}

@media (max-width: 780px) {
    .box-img img {
        width: 140px;
        height: 110px;
        border-radius: 6px;
    }

    .grid-item{
        width: 194px;

    }
    
}

@media (max-width: 729px) {
    .box-img img {
        width: 140px;
        height: 110px;
        border-radius: 6px;
    }

    .grid-item{
        width: 150px;

    }
    
}

/* Media query for mobile screens */
@media (max-width: 767px) {
    .grid-container {
        grid-template-columns: repeat(3, 1fr);
        /* Mobile layout with 3 columns */
    }
}


@media (max-width: 487px) {
    .box-img img {
        width: 95px;
        height: 72px;
        border-radius: 6px;
    }

    .Service-boxx p {
        text-decoration: none;
        color: #000;
        text-align: center;
        font-size: 15px;
        font-weight: 400;
        padding-top: 10px;
       
    }

    .grid-item {
        width: 130px;
    }

    .Service-boxx {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 200px;
        height: 120px;
        margin-bottom: 8px;
    }    
}


@media (max-width: 417px) {
    .box-img img {
        width: 95px;
        height: 72px;
        border-radius: 6px;
    }

    .Service-boxx p {
        text-decoration: none;
        color: #616161;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        padding-top: 5px;
       
    }

    .grid-item {
        width: 124px;
    }
    
    .Service-boxx {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 200px;
        height: 136px;
        
    } 
    
    .grid-container {
        padding: 10px 5px;
    }
}

@media (max-width: 380px) {
    .box-img img {
        width: 95px;
        height: 72px;
        border-radius: 6px;
    }

    .Service-boxx p {
        text-decoration: none;
        color: #4f4f4f;
        text-align: center;
        font-size: 15px;
        font-weight: 400;
        padding-top: 10px;
       
    }

    .grid-item {
        width: 119px;
    }
    
    .Service-boxx {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 200px;
        height: 120px;
        margin-bottom: 19px;
    } 
    
    .grid-container {
        padding: 10px 0px;
    }
}

/* General text styling for the paragraphs */
.contentText {
    font-size: 15px;
    color: #404040;
    line-height: 1.6;  /* Line spacing for better readability */
    padding: 5px 0;    /* Spacing between paragraphs */
  }

  .headingText {
    font-size: 20px;  /* Default font size for h2 on large screens */
    font-weight: 600;
    color: #1d2024;
    margin-bottom: 20px;
  }
  
  /* Styling for the clickable text (Read More / Show Less) */
  .toggleText {
    cursor: pointer;
    margin-top: 20px;
    color:#A3080C;
    /* text-decoration: underline; */
    font-size: 15px;
    display: inline-block;  /* Ensures it shows on all screen sizes */
  }

  .service-list li {
    margin-bottom: 15px;  /* Space between each list item */
  }

  .service-list strong {
    font-size: 24px;  /* Default font size for service titles */
    color: #333;
    display: block;  /* Makes strong act like a block element */
    margin-bottom: 5px;  /* Space below the service title */
  }
  
  /* Example styles for the rest of the component */
  .showContent {
    padding: 20px;
    max-width: center !important;
    margin: 0 auto;  /*Center the content*/
  }
  
  .footer-content-wrapper {
    background-color: #f9f9f9;
    padding: 20px 87px 20px 87px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);  /* Add shadow for a modern look */
  }
  
  .hideContent {
    margin-top: 15px;
  }
  
  /* Desktop layout adjustments */
  @media only screen and (min-width: 768px) {
    .contentText {
      font-size: 16px;  /* Slightly larger text on desktop */
    }
  
    .toggleText {
      font-size: 16px;
    }
    .footer-content-wrapper h2 {
        font-size: 24px;  /* Larger font size for h2 on desktop */
        font-weight: 600;
        line-height: normal;
      }
    
      .footer-content-wrapper p {
        font-size: 15px;  /* Larger font size for paragraphs on desktop */
        font-weight: 500;
        color: #3a3939;
      }

      .service-list strong {
        font-size: 20px;  /* Larger font size for service titles on desktop */
      }

      .service-list  p {
        margin-top: 0px !important;  /*Larger font size for service titles on desktop */
        /* margin-bottom: 10px !important;  */
        color: #3a3939;
      }
      .service-list  li {
        padding-left: 1rem !important;  /*Larger font size for service titles on desktop */
        /* margin-bottom: 10px !important;  */
      }
  }
  
  /* Mobile layout adjustments */
  @media only screen and (max-width: 767px) {
    .showContent {
      padding: 6px;  /* Reduce padding for mobile */
    }
  
    .footer-content-wrapper {
      padding: 10px;  /* Reduce padding inside the wrapper */
      margin: 10px;   /* Add margin to give breathing room on smaller screens */
      padding-top: 25px;
    }
  
    .contentText {
      font-size: 14px;  /* Smaller font size for mobile */
    }

    .footer-content-wrapper h2 {
        font-size: 15px;  /* Adjust h2 font size for tablet */
        font-weight: 600;
        line-height: normal;
      }

      .footer-content-wrapper p {
        font-size: 12px;  /* Adjust paragraph font size for tablet */
        font-weight: 500;
        color: #3a3939;
      }
  
    .toggleText {
      font-size: 14px;
      margin-top: 10px; /* Adjust margin for mobile */
      margin-bottom: 59px !important;
    }

    .service-list strong {
        font-size: 14px;  /* Adjust service title font size for tablet */
      }
      .service-list  li {
        padding-left: 0rem !important;  /*Larger font size for service titles on desktop */
        /* margin-bottom: 10px !important;  */
      }

  }








