.nav_search {
    width: 100%;
    margin: auto;
    background-color: rgb(255, 255, 255);
    height: 45px;
    margin-top: 5px;
    padding-left: 20px;
    display: flex;
    border-radius: 9px;
    border: 2px solid #47454545;
    background: #FFF;
}

.location-body {
    background-color: white;
    width: 500px;
    height: auto;
    padding-top: 20px;
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    margin-top: 10px;
    font-family: 'Open Sans', sans-serif;
}

.location-wrap {
    width: 100%;
    background-color: rgb(255, 255, 255);
    height: 100px;
}

.location-wrap h4 {
    padding-left: 40px;
    font-size: 14px;
    color:#A3080C34;
}

.nav_search img {
    justify-content: center;
    padding-top: 13px;
    width: 18px;
    height: 33px;
}

.nav_search input {
    width: 95%;
    height: 100%;
    margin-left: 15px;
    border: none;
    font-size: 14px;
    background-color: transparent;

}

.search-map {
    width: 100%;
    height: 78vh;
    background-color: rgba(222, 222, 222, 0.411);
}

.map_div {
    padding-top: 11px;
}

.cart_footer button {
    width: 95%;
    margin: auto;
    height: 45px;
    background-color:#A3080C;
    color: white;
    border: none;
    margin-top: -4px;
    border-radius: 3px;
}

.service_btn {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

}

.srvc_1 {
    width: auto;
    padding: 6px;
    border-radius: 8px;
    border: 1px solid #E0DFDF;
    background: rgba(217, 217, 217, 0.00);
    margin-bottom: 10px;
    margin-left: 17px;
    font-size: 12px;
    color: #6B6B6B;
    text-align: center;
}

/* .popup-body .divdr {
    height: 1px;
    width: 100%;
    background-color: rgb(205, 205, 205);
    margin-top: 20px;
} */

.popup-body h4 {
    color: #413B3B;
    font-size: 14px;
    font-weight: 400;
    padding-left: 6px;
}

.menu-input {

    width: 90%;
    height: 40px;
    margin-top: 10px;
    margin: auto;
    display: flex;
    border-radius: 6px;
    border: 1px solid #E4E4E4;
    background: rgba(217, 217, 217, 0.00);
    justify-content: center;
    align-items: center;
}

/* .menu-input:focus{
    border: 2px solid#A3080C;
}
.menu-input:focus
{
  box-shadow: 0 0 3px 0 #1183d6;
  border-color: #1183d6;
  outline: none;
} */
.loc-input input[type="search"]:focus {
    border-color:#A3080Ca1;
}

.loc-input {
    width: 97%;
    margin: auto;
    margin-top: 15px;
}

.loc-input input {
    width: 100%;
    height: 45px;
    font-size: 13px;
    margin: auto;
    border-radius: 6px;
    border: 2px solid #E4E4E4;
    padding-left: 40px;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
}

.loc-input .loc-inputs:focus {
    border-color:#A3080Ca1;
}

/* .divdr {
    width: 100%;
    margin: auto;
    height: 2px;
    background-color: #EAE5E5;
    margin-top: 8px;
    margin-bottom: 7px;
} */
.loc-input .loc-inputs {

    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    font-size: 13px;
    margin: auto;
    border-radius: 10px;
    border: 2px solid #E4E4E4;
    padding-left: 40px;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
}


/* .menu-input:active{
    border:1px solid#A3080C;
}  */
/* .brdr{
    margin-top: 15px;
    width: 100%;
    
    height: 40px;
    margin-top: 10px;
    margin: auto;
    display: flex;
    border-radius: 6px;
  
    background: rgba(217, 217, 217, 0.00);
    justify-content: center;
    align-items: center;
    border:1px solid#A3080C;
} */

.css-1wy0on6 custom-cursor-default-hover {
    display: none !important;
}

.lctns h4 {
    font-size: 14px;
}

.search-cont {
    width: 100%;
    height: 100vh;
    background-color: white;
    padding-top: 17px;
}

.address-option2 {
    width: 100%;
    height: auto;
    margin-top: 15px;
}

.option2 {
   display: flex;
flex-direction: row; /* Ensures items are laid out in a row */
flex-wrap: nowrap; /* Prevents wrapping */
align-content: stretch; /* Default; stretches the content area to fill the container */
justify-content: center; /* Centers items along the main axis */
align-items: center; /* Centers items along the cross axis */
width: 95%;
border-top: 1px solid rgb(202, 202, 202);
margin: auto;
padding: 10px;
cursor: pointer;
height: auto; /* Adjusts dynamically to content size */
overflow-wrap: break-word; /* Ensures long words break into multiple lines */
word-break: break-word; /* Compatibility with older browsers */
box-sizing: border-box; /* Includes padding and borders in width/height */

}

.option-detl {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    width: 100%;
    line-height: 1;
    margin-left: 15px;

}

.option-detl h3 {
    font-size: 14px !important;
    margin: 0 !important;
    padding: 0 !important;
}

.option-detl p {
    margin: 0;
    padding: 0;
    font-size: 12px;
    line-height: 17px;
}

.address-option {
    width: 100%;
    height: auto;
    margin-top: 5px;
}

.option1 {
    display: flex;
    width: 95%;
    height: 80px;
    /* justify-content: center; */
    align-items: center;
    border-top: 1px solid rgb(202, 202, 202);
    margin: auto;

}

.option1 img {
    margin: 10px;
}

.option-det {
    align-items: center;
    height: 45px;
    width: 100%;
    line-height: 1;
    margin-left: 8px;
}

.option-det h3 {
    font-size: 14px;
}

.option-det p {
    font-size: 12px;
}

@media screen and (max-width:768px){
    .srvc_1  p{
       font-size: 12px;
    }
}