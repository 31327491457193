.Card h2 {
  margin-top: -25px;
}

.Card p {
  margin-top: -30px;
}


.multicard-container {
  height: 250px;
  width: auto;

}

.timebox1-active
  {
    border: 1.7px solid#A3080C !important;
    background-color: rgba(147, 41, 75, 0.03);
}


.multicard-container h5 {
  padding: 20px;
  overflow: hidden;
}

.multicard-wrap {
  display: flex;
  overflow-x: scroll;
  width: 200%;
  padding: 10px;

  margin-top: -20px;
}

.multicard-wrap::-webkit-scrollbar {
  display: none;
}

.multicard-slider {
  display: flex;
}

.timeslot-parent {
  margin-bottom: 50px;
  font-family: 'Open Sans', sans-serif;
}

@media screen and (max-width:1049px) {


  .Progress-bar {
    display: none;
  }

  .time-wrap {
    width: 100%;
  }

  .timeslot-parent {
    padding-top: 70px;
    width: 100%;
  }

  .time-boxes {
    width: 100%;
  }

  .hide {
    display: none;
  }

  .multicard-container {

    height: 183px;
  }

  .multicard-container h5 {
    font-size: 14px;
    font-weight: 600;
  }

  

  .Card-wrap .Card .product--image {
    width: 56px;
    height: 56px;
    border-style: none;
  }

  .Card-wrap .Card {
    width: 94px;
    height: 114px;
    margin-left: 10px;
  }

  .Card-wrap .Card h2 {
    font-size: 11px;
    padding-top: 10px;
  }

  .Card-wrap .Card h2 img {
    width: 52px;
    height: 50px;

  }

  .Card-wrap .price {
    font-size: 13px;
    padding-top: 5px;
  }

  .timeslot-parent .cart_cost {
    display: flex;
    padding: 9px;
    position: fixed;
    bottom: 0;
    height: 55px;
    background: #fff;
    width: 99.9%;
    align-items: center;
    justify-content: center;
    box-shadow: 5px 5px 10px 5px #c3c3c3;
    border-radius: 1px;
  }

  .timeslot-parent .cart_cost .cart_left span {
    font-size: 14px;
  }

  .timeslot-parent .cart_cost .cart_right span {
    font-size: 15px;
    font-weight: 500;
    border-radius: 2px;
  }
}
@media screen and (max-width:580px){
  .timeslot-parent .cart_cost .cart_left {
    display: none;
  }
  

  .timeslot-parent .cart_cost .cart_right {
    display: none;
  }

  .timeslot-parent .cart_cost .cart_addressbtn {
    display: block;
  }
}

@media screen and (max-width:450px) {
  .timeslot-parent .time-wrap{
    margin-bottom: 10px;
    
  }

  .timeslot-parent .cart_cost .cart_addressbtn {
    background-color:#A3080C;

    width: 100%;
    height: 45px;
    text-align: center;
    padding-top: 12px;
    border-radius: 3px;

  }
  .time-wrap .timeslot{
    margin-top: 35px;
  }
  .time-wrap .timeslot p {
    font-weight: 400;
    font-size: 14px;
  }

  .timeslot-parent .cart_cost .cart_addressbtn a {
    color: white;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
  }
  
}

@media screen and (max-width:1195px) {
  .time-row .mleft {
    margin-left: 20px;

  }
}

@media screen and (max-width:768px) {
  .hide1 {
    display: none;
  }
  
  .timeslot-parent {
    padding-top: 21px;
    width: 100%;
    margin-bottom: 41px;
  }

  .time-boxes .mob-row {
    padding-top: 15px;
  }

  .time-boxes .time-row {
    margin-top: -10px;
  }

  .time-row .time-box1 {
    width: 106px;
    height: 45px;

  }
  .reschdule-wrap .choose-time-slot p{
    font-size: 16px;
    font-weight: 600;
  }
  .reschdule-wrap .time-box h5{
    font-size: 14px;
    font-weight: 600;
  }
  .time-boxes {
    width: 100%;
  }

  .time-box .timebox1 {
    width: 50px;
    height: 50px;

  }

  .time-box_scroll .timebox1 p {
    font-size: 13px;
    margin-left: -8px;
    text-align: center;
    margin-top: -17px;
  }

  .time-wrap .time-box h5 {
    font-size: 16px;
    padding-left: 20px;
    padding-top: 15px;
  }

  .time-wrap .time-box {
    padding: 0;
    padding-bottom: 10px;
    background-color: white;
  }
  .time-box_scroll .timebox1{
    margin-left: 20px;
  }
  .time-box_scroll .timebox1 h3 {
    font-size: 15px;
    margin-left: -7px;
    margin-top: -15px;
  }

  .choose-time-slot {
    background-color: white;
    margin-top: 10px;
    height: auto;
    /* padding-bottom: 10px; */
  }

  .date {
    display: none;
  }

  .time-wrap .time-heading h3 {
    font-size: 16px;

  }

  .time-wrap .time-heading h3 img {
    width: 17px;
    height: 17px;

  }

  .time-wrap .time-heading {
    padding-top: 15px;
    height: 50px;
    background-color: white;
  }

  .time-wrap .timeslot p {
    font-size: 14px;
    padding: 0;
    margin:0;
  }

  .time-wrap .timeslot {
    background-color: white;
    height: auto;
    padding: 10px 20px ;
  }

  .timeslot-parent .time-wrap {
    background-color: white;
  }

  .time-wrap .choose-time-slot p {
    font-size: 16px;
  }
}

.cart_cost .cart_right a {
  text-decoration: none;

}

.Card-wrap {
  display: flex;
}

.Card {
  width: 130px;
  height: 170px;
  background-color: rgb(255, 255, 255);
  text-align: center;
  margin-left: 20px;
  border-radius: 3px;
}

.Card .product--image {
  width: 112px;
  height: 112px;
  border: 3px solid#A3080C;
  margin: auto;
  border-radius: 50%;
  margin-top: 10px;
}

.Card h2 {
  font-size: 13px;
  padding-top: 10px;
}

.Card h2 img {
  width: 77px;
  height: 60px;

}

.price {
  font-size: 14px;

}

.timeslot {
  width: 100%;
  height: 65px;
  padding: 20px;
  border-bottom: 2px solid rgb(238, 238, 238);
  display: flex;
}

.timeslot p {
  font-size: 16px;
  font-weight: 500;
  width: auto;
}

.time-wrap {
  margin-top: 40px;
  border-radius: 5px;
  background-color: white;
  margin: auto;
  margin-top: 20px;
  height: auto;
  padding-bottom: 20px;
}

.timeslot .editicon {
  width: 40px;
  height: auto;
  float: right;
 margin-left: 0px;
}

.time-heading {
  width: 100%;
  height: auto;
  padding: 15px 20px;

  border-bottom: 2px solid rgb(238, 238, 238);
}

.time-heading h3 {
  font-size: 24px;
  font-weight: 400;
}

.date {
  width: 100%;
  height: 50px;
  padding: 10px 20px;
  border-bottom: 2px solid rgb(238, 238, 238);
}

.date h5 {
  font-size: 24px;
  font-weight: 500;
}

.time-box {
  width: 100%;
  padding: 20px;
  height: auto;
  border-bottom: 2px solid rgb(233, 232, 232);

}

.time-box h5 {
  font-size: 20px;
  font-weight: 400;
}

.scroll-wrap {
  display: flex;
}

.time-box_scroll {
  display: flex;
  overflow-y: scroll;
  width: auto;
  padding-right: 10px;
}

.time-box_scroll::-webkit-scrollbar {
  display: none;
}

.timebox1 {
  width: 101px;
  height: 101px;
  border: 2px solid rgb(224, 224, 224);
  border-radius: 5px;
  padding: 20px;
 
}

.marginleft{
 margin-left: 20px;
}

.timebox1:hover {
  border: 1.7px solid#A3080C;
  background-color: rgba(147, 41, 75, 0.03);
}

.timebox1 p {
  font-size: 13px;
  font-weight: 500;
  text-align: center;
}

.timebox1 h3 {
  margin-top: -10px;
  text-align: center;
  font-weight: 700;
}

.choose-time-slot {
  width: 100%;
  height: auto;
  margin-bottom: 30px;
}

.choose-time-slot p {
  border-bottom: 2px solid rgb(231, 231, 231);
  padding: 10px 20px;
  font-weight: 500;
  font-size: 22px;
  color: #3A3A3A;
}

.time-box1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 221px;
  height: 45px;
  border: 1.296px solid #C0BCBC;
  border-radius: 5px;
  /* box-shadow: 2px 4px 4px -2px rgb(184, 181, 181); */
  font-size: 14px;
  margin: auto;
  margin-bottom: 12px;
}

/* .mleft{
  margin-left: 34px;
} */
.time-box1:hover {
  border: 1.7px solid#A3080C;
  background-color: rgba(147, 41, 75, 0.03);
}

.time-boxes {
  display: flex;
  width: 98%;
  /* margin: auto; */
  height: auto;
  align-items: center;
  justify-content: center;
}

.time-row {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 30px;
}
.slotfull{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 167px;
  height: 45px;
  border: 1.296px solid #C0BCBC;
  text-align: center;
  border-radius: 5px;
  /* box-shadow: 2px 4px 4px -2px rgb(184, 181, 181); */
  font-size: 14px;
  margin: auto;
  margin-bottom: 12px;
}
.slotfull span{
   color:#A3080C;
}
.slotfull h6{
  color: rgb(190, 190, 190);
  margin: 0;
  
  padding: 0;
}

@media screen and (max-width:768px) {
  .time-row .slotfull{
      width: 106px;
      height: 45px;
      font-size: 12px;
      padding-top: 3px;
      margin-bottom: 12px;
    }
    
    .time-row .slotfull h6{
      font-size: 14px;
      padding-top: 3px;
    }
}