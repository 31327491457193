@media screen and (max-width: 1049px) {
    .payment-parent {
        padding-top:5px;

    }
    .payment-parent .pay-wrap{
        margin-top: -9px;
        
    }
    .head-wrap {
        display: none;
    }
    .total-pay {
        width: 90%;
        margin: auto;
       
    }
    .total table tr td{
        padding-top: 14px;
    }
    .total table tr th{
        padding-top: 14px;
    }
    .total{
        height: 48px;
    }
    .total-pay h5 {
        padding-bottom: 10px;
        border-bottom: 2px solid rgb(224, 223, 223);
    }
    .pay-divider {
        height:800px ;
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: nowrap;
    }
    .pay-divider .pay-bill {
        width: 100%;
        border-style: none;
        background-color: white;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        height: auto;
     
    }
    .pay-bill .total-pay {
        width: 80%;
        margin-top: 16px;
    }
    .pay-bill .total-pay h5{
        font-size: 16px;
        font-weight: 600;
    }
    .pay-bill .total-pay  table{
        font-size: 14px;      
    }
    .pay-divider .pay-method{
       margin-top: 40px;
       width: 100%;
        border-top: 8px solid rgba(227, 226, 226, 0.676);
    }
    .payment-text {
        display: none;
    }
    .pay-online h4{
        padding-top: 20px;
    }
    .pay-text1{
        display:flex;
        flex-direction: column;
        margin-bottom: 20px;
    }
     .pay-text1 .pay-img1{
        width: 90%;
        flex-direction: column;
    }
    .pay-method .pay-text1 h3{
        font-size: 16px;
    }
    .pay-img1 img {
        max-width:100%;
    }
    .pay-method .pay-online{
        height: 120px;
        border-bottom: 8px solid rgba(220, 220, 220, 0.543);
    }
    .pay-method .pay-online h4{
        padding: 10px 20px;
        font-size: 16px;
    }
    .pay-online .online-box{
         height: 45px;
         border-radius: 6px;
    }
    .pay-online .online-box .rounded-checkbox img{
        margin-top:0px;
    }
    .pay-online .online-box .rounded-checkbox p{
        margin-top:10px;
        width: 248px;
    }
    .pay-online .online-box .rounded-checkbox input{
        margin-top: 10px;
    }

    .pay-method .pay-cash{
        height: 120px;
        border-bottom: 8px solid rgba(220, 220, 220, 0.475);
    }
    .pay-method .pay-cash h4{
        padding: 10px 20px;
        font-size: 16px;
    }
    .pay-cash .cash-box{
         height: 45px;
         border-radius: 6px;
    }
    .pay-cash .cash-box .rounded-checkbox img{
        margin-top:0px;
    }
    .pay-cash .cash-box .rounded-checkbox p{
        margin-top:10px;
        width: 248px;
    }
    .pay-cash .cash-box .rounded-checkbox input{
        margin-top: 10px;
    }
}
@media screen and (max-width:1190px){
    .pay-cash .cash-box .rounded-checkbox p{
        margin-top:15px;
        width: 291px;
    }
    .pay-online .online-box .rounded-checkbox p{
        margin-top:15px;
        width: 291px;
    }
}
@media screen and (max-width:1039px){
    .pay-cash .cash-box .rounded-checkbox p{
        margin-top:15px;
        width: 791px;
    }
    .pay-online .online-box .rounded-checkbox p{
        margin-top:15px;
        width: 791px;
    }
}
@media screen and (max-width:999px){
    .pay-cash .cash-box .rounded-checkbox p{
        margin-top:15px;
        width: 491px;
    }
    .pay-online .online-box .rounded-checkbox p{
        margin-top:15px;
        width: 491px;
    }
}
@media screen and (max-width:723px){
    .pay-cash .cash-box .rounded-checkbox p{
        margin-top:15px;
        width: 282px;
    }
    .pay-online .online-box .rounded-checkbox p{
        margin-top:15px;
        width: 282px;
    }
    .pay-method .cancel{
        padding-left: 20px;
        border-bottom: 8px solid rgba(220, 220, 220, 0.475);
        padding-bottom: 15px;
    }
    .pay-method .cancel h3{
        font-size: 16px;
        
    }
    /* .pay-method .cancel p{
        font-size: 13px;
       
    } */
}
@media screen and (max-width:485px){
    .pay-cash .cash-box .rounded-checkbox p{
        margin-top:15px;
        width: 200px;
    }
    .pay-online .online-box .rounded-checkbox p{
        margin-top:15px;
        width: 200px;
    }
}

@media screen and (max-width:1049px){
    .payment-parent .cart_cost {
        display: flex;
        padding: 9px;
        position: fixed;
        bottom: 0;
        height: 55px;
        background: #fff;
        width: 99.9%;
        align-items: center;
        justify-content: center;
        box-shadow: 5px 5px 10px 5px #c3c3c3;
        border-radius: 1px;
      }
    
      .payment-parent .cart_cost .cart_left span {
        font-size: 14px;
      }
    
      .payment-parent .cart_cost .cart_right span {
        font-size: 15px;
        font-weight: 500;
        border-radius: 2px;
      }
}

@media screen and (max-width:450px) {
    .payment-parent .cart_cost .cart_left {
      display: none;
    }
  
    .payment-parent .cart_cost .cart_right {
      display: none;
    }
  
    .payment-parent .cart_cost .cart_addressbtn {
      display: block;
    }
  
    .payment-parent .cart_cost .cart_addressbtn {
      background-color:#A3080C;
  
      width: 100%;
      height: 45px;
      text-align: center;
      padding-top: 12px;
      border-radius: 3px;
  
    }
  
    .payment-parent .cart_cost .cart_addressbtn a {
      color: white;
      text-decoration: none;
      font-size: 14px;
      font-weight: 500;
    }
    .pay-wrap .pay-head3 h3{
        display: none;
    }
  }

.payment-parent {
    padding-bottom: 60px;
    font-family: 'Open Sans', sans-serif;
}

.pay-wrap {
    background-color: white;
    border-radius: 20px;
    height: auto;
    margin-top: 30px;
    
}

.editicon {
    width: 100px;
    height: auto;
    float: right;
  

}
.editicon img{
    margin-left: 30px;
}
.pay-head {
   
    display: flex;
    height: 65px;
    padding:20px 30px;
    border-bottom: 1px solid rgb(238, 238, 238);

}
.pay-head p{
    font-size: 16px;
    color: #212121;
    font-weight: 400;
   
}
.cancel{
    width: 97%;
    padding-top: 30px;
    padding-left: 30px;
    border-bottom: 4px solid rgba(220, 220, 220, 0.475);
}
.cancel h3{
    font-size: 20px;
}
.cancel p{
    font-size: 14px;
}
.pay-head2 {
    width: 100%;
    height: 60px;
    padding: 18px 30px;
    display: flex;
    border-bottom: 1px solid rgb(238, 238, 238);

}

.pay-head2 h3 {
    font-size: 16px;
    font-weight: 400;
}
.pay-head3 h3 {
    font-size: 24px;
    font-weight: 600;
}
.pay-head3{
    width: 100%;
    height: 60px;
    padding: 15px 30px;
    display: flex;
    border-bottom: 1px solid rgb(238, 238, 238);
}

.pay-divider {
    display: flex;
    width: 100%;
    height: auto;
    padding-bottom:10px ;
}

.pay-method {
    width: 60%;
    height:auto;

}

.pay-bill {
    width: 40%;
    height: auto;

    border-left: 1px solid rgb(234, 234, 234);
}
.pay-bill table td{
  font-weight: 400;
}
.pay-bill h5{
    font-weight: 600;
}
.online-box {
    width: 90%;
    height: 60px;
    border: 1px solid rgba(0, 0, 0, 0.29);
    
    margin: auto;
    border-radius: 3px;
    margin-top: 5px;

}
.pay-method h4, h3{
    font-weight: 500;
}
/* .online-box:hover {
    border: 1.7px solid#A3080C;
    background: rgba(147, 41, 75, 0.03);
}

.cash-box:hover {
    
    background-color: rgba(147, 41, 75, 0.03);
} */

.pay-online {

    width: 100%;
    height: 180px;
    border-bottom: 4px solid rgb(236, 236, 236);

}

.pay-online h4 {
    padding: 20px 30px;
    font-size: 20px;
}

.pay-cash {
    width: 100%;
    height: 180px;
    border-bottom: 4px solid rgb(236, 236, 236);
}

.pay-cash h4 {
    padding: 20px 30px;
    font-size: 20px;
}
.total-pay .pay tr th{
    margin-top: 20px;
}
.total-pay .pay  tr td{
    margin-top: 20px;
}
.cash-box {
    width: 90%;
    height: 60px;

    border: 1px solid rgba(0, 0, 0, 0.29);
    margin: auto;
    border-radius: 3px;
    margin-top: 5px;
}

.Radiobtn {

    width: 20px;
    height: 20px;
    margin-top: 18px;
    accent-color:#A3080C;
    float: right;
}

.label1 {
    display: flex;
    width: 90%;
    height: 100%;
    margin: auto;

}

.rounded-checkbox img {
    width: 30px;
    height: 30px;
   
}

.rounded-checkbox p {
    width: 500px;
    margin-left: 20px;
    margin-top: 15px;
}
/* .pay-text{
    display: none;
} */
.pay-text1 h3{
    padding: 20px 30px;
    font-size: 20px;
}
.pay-text1{
    width: 100%;
    height: auto;
   
}
.pay-img1 {
    width: 100%;
    margin: auto;
    height: auto;
    /* flex-direction: column; */
    /* padding-left: 87px; */
    display: flex;
    align-content: center;
    justify-content: center;

}
.pay-img1 img {
    width: auto;
    height: 100px;

}
/* .pay-img {
    width: 90%;
    margin: auto;
    height: 120px;

} */

/* .pay-img img {
    width: 100%;
    height: 100%;

} */

/* .pay-text h3 {
    padding: 20px 30px;
    font-size: 21px;
} */

.total-pay {
    width: 80%;
    margin: auto;
    height: 200px;
    margin-top: 50px;
}

.total-pay .total {
    border-bottom: 1px solid rgb(228, 227, 227);
    margin-top: 16px;
    height: 50px;
}
.total-pay .total tr th{
    margin-top: 5px;
}
.total-pay .total tr td{
    margin-top: 10px;
}
.payment-text {

    margin: auto;
    width: 60%;
    height: 60px;
    margin-top: 80px;
}

@keyframes example {
    0% {
        color: rgb(255, 85, 0);
    }

    25% {
        color: yellow;
    }

    50% {
        color: purple;
    }

    75% {
        color: blue;
    }

    75% {
        color: rgb(250, 6, 124);
    }

    100% {
        color: rgb(8, 186, 246);
    }
}

.payment-text h2 {
    text-align: center;
    animation-name: example;
    animation: example 8s infinite;
}

.rounded-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none; /* Prevent text selection */
 
    width: 90%;
    height: 100%;
    margin: auto;
}

.rounded-checkbox input {
  opacity: 0;
  cursor: pointer;
  float: right;
}

.checkmark {
  height: 22px; /* Adjust the size of the checkbox */
  width: 22px; /* Adjust the size of the checkbox */
  background-color: #fff;
  border: 1px solid #5a5a5a; /* Border color for the checkbox */
  border-radius: 50%; /* Makes it rounded */
  margin-right: 10px; /* Add some spacing between the checkbox and label */
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold; /* Style the checkmark */
  font-size: 15px; /* Adjust the size of the checkmark */
  overflow: hidden;
}


.rounded-checkbox input:checked + .checkmark::before {
  content: "\2713"; /* Unicode checkmark character */
  color: #ffffff; /* Color of the checkmark when checked */
  background-color: #0ad810;
  width: 100%;
  padding: 4px;
  font-weight: 600;
}

@media screen and (max-width:705px){
    /* .rounded-checkbox p{
        width: 25px;
        height: 25px;

    } */
}
/* You can also add additional styles to the label text here */
