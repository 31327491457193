@keyframes moveFromBottomToTop {
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@media screen and (max-width: 768px) {

    /* .modal {
        display: flex;
        justify-content: flex-start;
        flex-direction: column-reverse;
    } */

    .modal-content {
        width: inherit;

    }

    .content {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(57, 57, 57, 0.15);
    /* display: flex;
    align-items: center;
    justify-content: center; */
}

.modal-content {
    /* width: 600px; */
    height: auto;
    background-color: transparent;
    border-style: none;
    animation: moveFromBottomToTop 0.5s ease-out;

}

.content {
    background-color: rgb(237, 237, 237);
    width: 100%;
    height: 100%;
    margin-top: 8px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    overflow: hidden;
}

.cancel-btn {
    width: 100%;
    height: 45px;
}

.cancel-btn {
    width: 100%;
    height: 40px;
}

.cancel-btn img {
    float: right;
    width: 50px;
    height: 50px;
}

.confirm-btn {
    width: 100%;
    background-color: white;
    height: 80px;
    margin-top: 5px;

}

.confirm-btn .btn1 {
    margin: auto;
    width: 250px;
    height: 45px;

}

.confirm-btn .btn1 button {
    background-color:#A3080C;
    color: white;
    border-style: none;
    font-size: 18px;
    border-radius: 10px;
    width: 280px;
    height: 45px;
    margin-top: 15px;
}