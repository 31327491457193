.app-download-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 34vh;
    background: linear-gradient(179deg, rgb(255, 255, 255), #f8f9fa);
    padding: 1rem;
  }
  
  .app-download-content {
    display: flex;
    background-color: white;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    max-width: 1000px;
    width: 100%;
  }
  
  .app-mockup {
    flex: 1;
    padding: 1rem 1rem 0rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(359deg, #ffffff, rgb(255 188 188));
  }
  
  .app-screen {
    /* background-color: white; */
    /* border-radius: 20px; */
    padding: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    background-image: url('https://res.cloudinary.com/dy4hpcssz/image/upload/v1728235608/subcategory/ilc3txbqhkruisukxpvl.png'); /* Add your image here */
    background-size: cover; /* Cover ensures the image takes up the whole container */
    background-position: center; /* Center the image within the container */
    background-position: top; /* Aligns the image to the top */
    background-repeat: no-repeat; /* Ensure the image doesn't repeat */
  }
  
  .app-icon {
    width: 80px;
    height: 80px;
    /* background-color: #A3080C; */
    /* border-radius: 20px; */
    margin-bottom: 1rem;
   
  }
  
  .app-screen p {
    color: #A3080C;
    font-weight: bold;
  }
  
  .app-info {
    flex: 1;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .app-info h1 {
    color: #A3080C;
    font-size: 24px;
    margin-bottom: 0.5rem;
    font-weight: 600;
  }
  
  .app-info h2 {
    color: #4e4e4e;
    font-size: 17px;
    margin-bottom: 0.5rem;
  }
  
  .app-info p {
    color: #666;
    margin-bottom: 1.5rem;
  }
  
  .download-options {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .store-badge {
    max-width: 150px;
  }
  
  .qr-code {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .qr-code p {
    margin-bottom: 0;
  }
  
  .download-button {
    background-color: #A3080C;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: none;
  }
  
  .download-button:hover {
    background-color: #d81919;
  }
  
  @media (max-width: 768px) {
    .app-download-content {
      flex-direction: column;
    }
  
    .app-mockup, .app-info {
      width: 100%;
    }
  
    .download-button {
      display: block;
    }
  }
  
  @media (max-width: 480px) {
    .download-options {
      flex-direction: column;
      align-items: center;
    }
  
    .store-badge {
      max-width: 200px;
    }
  }