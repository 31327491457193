::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f7c5d75b;
    border-radius: 6px;
}

::-webkit-scrollbar {
    width: 7px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color:#A3080C;
}


.typingas::placeholder {
    animation: typing 3s steps(30, end), blink-caret 0.75s step-end infinite;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid #333;
    width: 0;
    color: #333;
    /* Adjust color for visibility */
}

@keyframes typing {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

@keyframes blink-caret {
    50% {
        border-color: transparent;
    }
}



.addNew {
    color:#A3080C;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.css-1hb7zxy-IndicatorsContainer {
    display: none !important;
}

.css-7ef8f9-control {
    box-shadow: none !important;
}

.header {
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;
    height: 75px;
}

.logo {
    display: flex;
    align-items: flex-end;

}

.logo_img {
    height: 91px;
    padding-bottom: 32px;
    width: 111px;
    margin-bottom: 10px;
}

.navlink {
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;
}

.tageline {
    font-family: 'Open sans', sans-serif;
    margin-block-end: 0;
    padding-top: 8px;
    padding-bottom: 1px;
    padding-left: 15px;
    font-weight: 400;
    font-size: 22px;
    color: #0c0c0c;
    margin-bottom: 10px;
}

.tageline2 {
    font-family: 'Open sans', sans-serif;
    margin-block-end: 0;
    padding-top: 8px;
    padding-bottom: 3px;
    padding-left: 15px;
    font-weight: 600;
    font-size: 24px;
    color: #413B3B;
    margin-bottom: 10px;

}

.ul_list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.nave_link {
    float: left;
}

/* .nave_link a {
    display: block;
    padding: 8px;
    font-size: 21px;
    font-weight: 500;
    text-decoration: none;
    font-family: 'Fredoka';
    color: #0c0c0c;
} */

/* .nav_link .actives:hover {
    font-family: 'Fredoka';
    color: #E30B5C;
} */

.cat_icon {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.icon {
    height: 45px;
    width: 40px;
    padding-bottom: 5px;
}

.navbar {
    background-color: #ffffff;
    height: 67px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    box-shadow: 0 8px 9px rgb(0 0 0 / 0.1);
    z-index: 20;
}

.nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    margin-left: 60px;
    margin-right: 60px;
    max-width: 1500px;
}

.cart_icon_number {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:#A3080C;
    color: #fff;
    top: 16px;
    right: 83px;
    border-radius: 50%;
    height: 1.2rem;
    width: 1.2rem;
    font-size: 12px;
}


.cart_icon_number_foot {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:#A3080C;
    color: #fff;
    top: 3px;
    right: 20px;
    border-radius: 50%;
    height: 1rem;
    width: 1rem;
    font-size: 11px;
}

.nav-logo {
    display: flex;
    color:#A3080C;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    flex-grow: 1;
}

.nav-menu {
    display: flex;
    align-items: flex-end;
    list-style: none;
    text-align: center;
    /* padding-inline-start: 0px; */
    margin-bottom: 10px;
    margin-right: 2rem;
}

.nav-links:hover {
    font-family: 'Open sans', sans-serif;
    color:#A3080C;
}

.nav-links {
    font-size: 20px;
    font-weight: 500;
    text-decoration: none;
    font-family: 'Open sans', sans-serif;
    color: #0c0c0c;
    font-family: 'open sans', sans-serif;
    padding: 0.5rem 1rem;
    border-bottom: 3px solid transparent;
}

.fa-code {
    margin-left: 1rem;
}

.nav-item {
    line-height: 40px;
    margin-right: 1rem;
}

.nav-item:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
    width: 100%;
    background:#A3080C;
}

.nav-item .active {
    color:#A3080C;
}

.nav-icon {
    display: none;
}

@media only screen and (max-width: 1115px) {
    .tageline {
        font-family: 'Open sans', sans-serif;
        margin-block-end: 0;
        padding-top: 8px;
        padding-bottom: 7px;
        padding-left: 8px;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        color: #413B3B;
    }

    .nav-item {
        margin-right: 0px;
    }
}

@media screen and (max-width: 1047px) {
    .tageline {
        display: none;

    }

    .nav-menu {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        border-top: 1px solid #fff;
        position: absolute;
        top: 80px;
        left: -110%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: #e1e1e1;
        left: 0px;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-item .active {
        color:#A3080C;
        border: none;
    }

    .nav-links {
        padding: 5px;
        width: 100%;
        display: table;
    }

    .nav-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color:#A3080C;
    }

    .icon {
        height: 45px;
        width: 40px;
        margin-right: 17px;
    }
}



/* Path: index.html */
.mobile_active {
    display: none;

}

.mobile_nav {
    display: flex;
    background: #fff;
    align-items: center;
    position: fixed;
    width: 100%;
    justify-content: space-around;
    box-shadow: 0 8px 9px rgb(0 0 0 / 0.1);
    z-index: 99;
    height: 45px;
}

.mobile_tagline {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.mobile_tageline1 {
    font-family: 'Open sans', sans-serif;
    margin-block-end: 0;
    margin-bottom: 0rem;
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    color: #413B3B;
}

.mobile_tageline2 {
    font-family: 'Open sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    color:#A3080C;
    margin-bottom: 0rem;

}

.navs_link {
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;

    color: #0c0c0c;
    font-family: 'Open sans', sans-serif;
    padding: 0.5rem 1rem;
    border-bottom: 3px solid transparent;
}

.navs_link:hover {
    font-family: 'Open sans', sans-serif;
    color:#A3080C;
}


.mobile_link {
    line-height: 40px;
    margin-right: 1rem;
}

/* .mobile_link:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
}

.mobile_link:hover:after {
    width: 100%;
    background:#A3080C;
} */

.mobile_logo_img {
    max-width: 9rem;
    padding-bottom: 8px;
}

@media screen and (max-width: 1048px) {
    .navbar {
        display: none;
    }

    .mobile_active {
        display: block;
    }

    .mobile_tageline1 {
        font-size: 1.2rem;
        font-weight: 600;
    }

    .mobile_tageline2 {
        font-size: 0.9rem;
        font-weight: 600;

    }

    .navs_link {
        font-size: 16px;
        font-weight: 600;
    }

    .mobile_link {
        line-height: 40px;
        margin-right: 0rem;
    }


    .logo_img {
        max-width: 6rem;
        padding-bottom: 8px;
    }

    .mobile_logo_img {
        max-width: 7rem;
        padding-bottom: 8px;
    }
}

@media screen and (max-width: 756px) {


    .mobile_tageline1 {
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }


}


@media screen and (max-width: 456px) {
    .navbar {
        display: none;
    }

    .mobile_active {
        display: block;
    }

    .mobile_tageline1 {
        font-size: 12px;
        font-weight: 600;
    }

    .mobile_tageline2 {
        font-size: 0.7rem;
        font-weight: 600;

    }

    .navs_link {
        font-size: 14px;
        font-weight: 600;
    }

    .mobile_link {
        line-height: 40px;
        margin-right: -1rem;
    }

    .mobile_logo_img {
        width: 5rem;
        height: 3.3rem;
        padding-bottom: 8px;
    }
}


@media screen and (max-width: 1048px) {
    .cart_icon_number_foot {
        right: 6.5rem;
    }
}


@media screen and (max-width: 948px) {
    .cart_icon_number_foot {
        right: 5.5rem;
    }
}

@media screen and (max-width: 800px) {
    .cart_icon_number_foot {
        right: 4.5rem;
    }
}

@media screen and (max-width: 696px) {
    .cart_icon_number_foot {
        right: 3.5rem;
    }
}

@media screen and (max-width: 520px) {
    .cart_icon_number_foot {
        right: 2.1rem;
    }
}

@media screen and (max-width: 400px) {
    .cart_icon_number_foot {
        right: 1.5rem;
    }
}

/* Container background styling */
.custom-container {
    background-color: rgba(0, 0, 0, 0.6);
  }
  
  /* Popup styling */
  .custom-popup {
    border-radius: 24px; /* Equivalent to rounded-3xl */
    padding: 1.5rem; /* Bootstrap padding */
  }
  
  @media (min-width: 576px) {
    .custom-popup {
      padding: 3rem; /* For sm padding */
    }
  }
  
  /* Image styling */
  .custom-image {
    margin-bottom: 3rem; /* Bootstrap margin-bottom equivalent */
  }
  
  /* Title styling */
  .custom-title {
    color: #101828;
    font-size: 1.25rem; /* Equivalent to text-xl */
    margin-bottom: 2rem;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    line-height: 1.3;
  }
  
  @media (min-width: 576px) {
    .custom-title {
      font-size: 2em; /* Equivalent to sm font-size */
    }
  }
  
  /* Close button styling */
  .custom-close-button {
    display: none;
  }
  
  /* Actions styling */
  .custom-actions {
    gap: 12px;
    width: 100%;
  }
  
  /* Cancel button styling */
  .custom-cancel-button {
    border: 1px solid #C21558;
    background: linear-gradient(92.34deg, rgba(12, 83, 186, 0.05) 2.95%, rgba(194, 21, 88, 0.05) 94.64%);
    color: #C21558;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
  }
  
  .custom-cancel-button:hover {
    background: linear-gradient(92.34deg, #C21558 2.95%, #0C53BA 94.64%);
    color: white;
  }
  
  /* Confirm button styling */
  .custom-confirm-button {
    border: 1px solid #C21558;
    background: linear-gradient(92.34deg, #C21558 2.95%, #0C53BA 94.64%);
    color: white;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
  }