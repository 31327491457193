@media screen and (max-width:1105px) {
    .profile-content .profile-detail form {
        width: 100%;
    }

    .profile-content .btn-wrap {
        width: 100%;
        border-top: 8px solid rgb(238, 238, 238);
    }

    .profile-parent {
        padding-top: 25px;
    }
}

.editprofile-wrap {
    background-color: white;
    height: auto;
    width: 100%;
    border-radius: 10px;
    font-family: 'Open Sans', sans-serif;
    margin-top: 20px;
}

.profile-detail form {
   
    width: 568px;
    height: 480px;
   
}
.editprofile-wrap .profile-content{
     height:750px;
}
.profile-detail form p {
    padding-top: 15px;
    font-size: 16px;
}

.profil-editbtn {
        width: 100%;
    height: 45px;
}

.btn-wrap {
    width:568px;
    height: 100px;
   margin-bottom: 30px;
   margin-top: -50px;
}

.profil-editbtn button {
    width: 100%;
    height: 100%;
    margin: auto;
    border-radius: 3px;
    border-style: none;
    background-color:#A3080C;
    color: white;
    font-size: 18px;
    margin-top: 30px;
}

.inputbox {
    position: relative;
    width: 100%;
}

.inputbox input {
    width: 100%;
    height: 45px;
    padding: 15px;
    border-radius: 6px;
    border: 2px solid #E4E4E4;
    outline: none;
    font-size: 14px;
    color: #505050;
    margin-top: 20px;
}

.inputbox select {
    width: 100%;
    height: 45px;
    padding: 0px 15px;
    border-radius: 6px;
    border: 2px solid #E4E4E4;
    outline: none;
    font-size: 14px;
    color: #505050;
    margin-top: 20px;
}

.inputbox span {
    position: absolute;
    left: 0;
    padding:31px 10px;
    pointer-events: none;
    font-size: 12px;
    color: #adadad;
    /* transition: 0.05s; */
}

.inputbox input:valid~span,
.inputbox input:focus~span {
    color:#A3080C;
    transform: translateX(10px) translateY(-7px);
    font-size: 12px;
    padding: 0px 1px;
    margin-top: 19px;
    background-color: rgb(255, 255, 255);
    border-left: 1px solid #e1e1e1;
    border-right: 1px solid #e2e2e2;
}