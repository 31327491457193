.newquestion_card {
  width: 95%;
  margin: auto;
  margin-top: 60px;
  /* height: 600px; */
  font-family: 'Open Sans', sans-serif;
}
.col {
  flex: none;
}
.newquestion_card h4 {
  font-size: 28px;
  text-align: center;
  font-weight: 400;
  color: #000;
}

.newquestion_card h4 span {
  color:#A3080C;
  font-weight: 600;
}

.newquestion_card .question_card_inner {
  width: 100%;
  display: flex;
  margin-top:10px;
}

.newquestion_card .question_card_innerBox {
  background-color: rgb(255, 255, 255);
  padding: 0.4rem;
  border-radius: 10px;
  border: 1.5px solid #e3e3e3;
  margin: 0.8rem;
  cursor: pointer;
}

*, :after, :before {
}

.newquestion_card .question_card_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.column {
  width: 100%;
}

.newquestion_card .question_card_flex h3 {
  font-size: 17px;
  color:#3A3A3A;
  margin: 0;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
}

@media screen and (max-width: 1286px) {
  .newquestion_card {
    margin: auto;
    margin-top: 30px;
  }
}

/* @media screen and (max-width: 1048px) {
  .newquestion_card {
    display: none;
  }
} */



@media screen and (max-width: 490px) {

  .newquestion_card .question_card_inner {
    flex-direction: column;
    padding-bottom: 23px;
  }
  .newquestion_card {
    margin: auto;
    margin-top: 30px;
  }
  
  @media screen and (max-width: 678px) {
   .question .newquestion_card h4 {
      font-size: 18px;
      
    }
  
  }
  .newquestion_card .question_card_flex h3 {
    font-size: 14px;
    font-weight: 500;
  }
}