@media screen and (max-width:1105px) {
    .Profile-divider .wallet-content {
        width: 100%;
        border-style: none;
        height: auto;
    }

    .history1 {
        border-top: 5px solid rgb(236, 236, 236);
    }

    .wallet-content .wallet-note {
        border-top: 5px solid rgb(236, 236, 236);
        margin-top: 20px;
        padding-top: 20px;
    }

    .wallet-content .history {
        width: 97%;
        border-top: 5px solid rgb(236, 236, 236);
        padding-top: 20px;
    }
}

@media screen and (max-width:768px) {
    .wallet-text .wallet-box2 img {
        width: 44px;
        height: 44px;
    }

    .wallet-text .wallet-box2 h4 {
        font-size: 14px;
    }

    .wallet-box .wallet-text {
        height: 40%;
    }

    .wallet-text .wallet-box1 h3 {
        font-size: 16px;
    }

    .history1 .help-next h3 {
        font-size: 14px;
        padding-top: 20px;
    }

    .wallet-note .note h3 {
        font-size: 14px;
    }

    .wallet-note .note ul li {
        font-size: 13px;
    }

    .wallet-content .history table tr th {
        font-size: 11px;
    }

    .wallet-content .history table tr td {
        font-size: 11px;
    }

    .wallet-content .history table tr {
        height: 30px;
    }

    .wallet-content .history h3 {
        font-size: 16px;
        padding-left: 10px;
    }
}

.wallet-box {
    width: 90%;
    margin: auto;
    height: 203px;
    margin-top: 30px;
    border: 2px solid rgb(212, 212, 212);
    border-radius: 5px;
}

.wallet-content {
    width: 74%;
    height: 73vh;
    border-left: 1px solid rgb(198, 198, 198);
    margin-bottom: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.wallet-text {
    width: 100%;
    margin: auto;
    height: 45%;
}

.danger {
    color: red;
}

.wallet-box1 {
    width: 60%;
    margin: auto;

    height: 100%;
}

.wallet-box2 {
    width: 60%;
    margin: auto;

    height: 100%;
    display: flex;
}

.wallet-inner {
    display: flex;
    width: auto;
    margin: auto;
}

.wallet-box1 h5 {
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    padding-top: 16px;
    color: #3A3A3A;
}

.wallet-box1 h3 {
    font-size: 18px;
    text-align: center;
    padding-top: 25px;
    font-weight: 400;
    color: #3A3A3A;
}

.wallet-box2 h4 {
    font-size: 20px;
    padding-top: 10px;
    color: #3A3A3A;
}

.wallet-box2 img {
    width: 62px;
    height: 62px;
    margin-right: 10px;
}

.history1 {
    width: 100%;
    height: 105px;
    padding-top: 20px;
}

.history1 h3 {
    font-size: 19px;
    padding-top: 15px;
    color: #3A3A3A;
}

.history1 .help-next {
    width: 90%;
    margin: auto;

}

.wallet-note {
    width: 100%;
    margin-top: 50px;
}

.note {
    width: 90%;
    margin: auto;
}

.note h3 {
    font-size: 19px;
    font-weight: 500;
    padding-left: 5px;
    color: #3A3A3A;
}

.note ul {
    width: 100%;
}

.note ul li {
    margin-top: 15px;
    font-size: 16px;
    color: #3A3A3A;
}

.history {
    width: 90%;
    margin: auto;
    margin-top: 40px;
}

.history h3 {
    font-size: 19px;
    color: #3A3A3A;
    font-weight: 600;
}

.history table {
    width: 100%;
    margin-top: 20px;
    background-color: white;
    border-radius: 3px;
}

.gray {
    border-radius: 3.991px;
    background: #F2F0F0;
}

.history table tr {
    height: 45px;
    /* border-bottom: 2px solid white; */
    border-radius: 3px;
}

.history table tr th {
    /* background-color: #E3E3E3; */
    color: #161616;
    font-size: 16px;
    font-weight: 600;
    color: #3A3A3A;
    padding-left: 10px;
}

.history table tr td {

    /* background: rgba(221, 31, 31, 0.08); */
    /* color: #161616; */
    font-size: 16px;
    font-weight: 500;
    padding-left: 10px;
    /* color: #3A3A3A; */
}

.bg_success {
    background-color: rgba(4, 255, 0, 0.096);
}

.history table tr td span {
    background-color: #FCE6BD;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 2px;
}