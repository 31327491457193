@keyframes moveFromBottomToTop {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(47, 47, 47, 0.893);
    /* display: flex;
    align-items: center;
    justify-content: center; */
}

.modal-content {

    /* width: 600px; */
    height: auto;
    background-color: transparent;
    border-style: none;
    animation: moveFromBottomToTop 0.5s ease-out;
    /* padding: 20px;
   
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px; */
}

.cancel-btn {
    width: 100%;
    height: 45px;

}

.cancel-btn img {
    float: right;
    width: 50px;
    height: 50px;
}

.Coupon-content {
    background-color: rgb(237, 237, 237);
    width: 100%;
    height: 100%;
     margin-top: 3px;
     border-top-left-radius: 20px;
     border-top-right-radius: 20px;
     border-bottom-left-radius: 20px;
     border-bottom-right-radius: 20px;
     overflow: hidden;
}

.Coupon-heading {
    background-color: white;
    width: 100%;
    height: 100px;
    padding: 10px 10px;
}

.Coupon-heading h3 {
    font-size: 20px;
    padding: 5px;
    padding-left: 9px;
}

.couponbox {
    display: flex;
    width: 100%;

}

.couponbox .input {
    width: 75%;
    height: 30px;

}

.input input {
    border: 1px solid rgb(185, 182, 182);
    border-radius: 4px;
    width: 320px;
    height: 30px;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 5px;
}

.apy {
    float: right;
    margin-right: 10px;
    margin-left: 25px;
}

.apy p {
    color:#A3080C;
    font-size: 16px;
    font-weight: 500;
    padding-top: 10px;
}

.Couponcontent-box1 {
    width: 100%;
    height: 60px;
    background-color: white;
    margin-top: 4px;
    display: flex;
}

.Couponcontent-box1 p {
    padding-left: 20px;
    padding-top: 20px;
    color:#A3080C;
    font-size: 15px;
    font-weight: 500;
    float: right;

}

.offer {
    width: 75%;
    height: 50px;
}

.offer h4 {
    padding-top: 8px;
    padding-left: 30px;
    font-size: 14px;
    font-weight: 500;
}

.offer h6 {
    font-size: 12px;
    padding-left: 30px;
}

.offer p {
    color: black;

}
@media screen and (max-width: 768px) {
    
    /* .modal{
        display: flex;
        justify-content: flex-start;
        flex-direction: column-reverse;
    } */
    .modal-content {
        width: inherit;
       
    }
    .Coupon-content{
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
}

/* Hide the browser's default checkbox */
.checkmark1 {
    display: flex;
    align-items: center; /* Aligns checkbox and text vertically */
    margin-right: 10px; /* Adds right margin */
    cursor: pointer; /* Changes cursor to pointer on hover */
     /* Adjust text size */
  }
  
  /* Styling the custom checkbox */
  .checkmark1 input[type="checkbox"] {
    appearance: none; /* Hides the default checkbox */
    width: 20px; /* Width of the custom checkbox */
    height: 20px; /* Height of the custom checkbox */
    background-color: #eee; /* Background color when unchecked */
    border: 2px solid #ddd; /* Border for the checkbox */
    border-radius: 4px; /* Optional: rounded corners */
    margin-right: 10px; /* Margin between checkbox and text */
  }
  
  /* Style for when checkbox is checked */
  .checkmark1 input[type="checkbox"]:checked {
    background-color: #eee; /* Background color when checked */
    border: 2px solid #38AE36; /* Border color when checked */
  }
  
  /* Create the checkmark/indicator */
  .checkmark1 input[type="checkbox"]:checked::after {
    content: "";
    position: relative;
    display: block;
    width: 12px; /* Width of the checkmark */
    height: 14px; /* Height of the checkmark */
    border: solid #38AE36; /* Checkmark color */
    border-width: 0 3px 3px 0; /* Creates a checkmark shape */
    transform: rotate(40deg); /* Rotates to create checkmark */
    bottom: 2px;
    left: 2px; /* Aligns checkmark horizontally */
  }
  
  /* Create a custom checkbox */

  