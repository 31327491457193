.review_card {
    display: flex;
    flex-direction: column;
    padding: 2.9rem 1.9rem 2.9rem 1.9rem;
    background-color: #ffff;
    margin: 5rem 5rem ;
    border-radius: 17px;
}

.review_card h1 {
    color: #000;
    font-size: 30px;
    font-family: 'Open sans', sans-serif;
    text-align: center;
}

.review_card_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #f8f8f8;
    padding: 1rem;
    margin: 0.5rem;
    border-radius: 12px;
    /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.473); */
}

.review_card_profile {
    display: flex;
    align-items: center;
}

.review_card_img img {
    width: 55px;
    height: 55px;
    margin-right: 0.8rem;
}

.review_card_auth a {
    text-decoration: none;
}

.review_card_auth h5 {
    margin: 0px;
}

.review_card_auth p {
    margin: 0px;
}

.review_card_review {
    height: 9rem;
    margin-top: 0.5em;
    padding: 5px;
    overflow-y: auto;
}

.str {
    display: flex;
    justify-content: inline;
    align-items: center;
    min-height: 1px;
    font-size: 20px;
    margin-top: 5px;
}


.slick-dots li.slick-active button:before {
    opacity: .75;
    color:#A3080C;
}

.slick-dots li button:before{
    font-size: 10px !important;
    
}


@media screen and (max-width: 1286px){
    .review_card {
        padding: 2.9rem 1.9rem 2.9rem 1.9rem;
        margin: 4.5rem 2.3rem ;
    }
}


@media screen and (max-width: 450px){
    .review_card {
        padding: 1.9rem 0.8rem 1.9rem 0.8rem;
        margin: 3.5rem 0.8rem ;
    }

    .section-head h4{
        font-size: 22px !important;
    }

    .section-head h4:before {
        width: 60px !important;
    }
}