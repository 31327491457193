@media screen and (max-width:1105px) {
    .Profile-divider .current-content {
        width: 100%;
        margin: auto;
        border-style: none;
        height: 100vh;
    }

    .currentbooking-wrap {
        margin-bottom: 45px;
    }

    .address-top p {
        display: none;
    }

    .current-content .address-form {
        width: 100%;
    }

    .current-content h3 {
        margin-left: -28px;

    }

    .add-heading button {
        border-style: none;

    }

    .current-content .address-top {
        border-bottom: 8px solid rgba(211, 211, 211, 0.5);
        width: 100%;
    }

    .current-content .address-top a {
        padding-left: 20px;
    }

    .current-content .add1 {
        margin-bottom: 20px;
        width: 95%;
    }

    .add1 .add-list ul li {
        padding-top: 5px;
    }

    .add1 .add-heading .lable {
        width: 150px;
        height: 20px;
        font-size: 12px;
        border-radius: 10px;
        color: #3A3A3A;
    }
    .address-form .form2{
        width: 90%;
        margin: auto;
    }
}

.address-top {
    width: 90%;
    margin: auto;
    height: 50px;
    display: flex;
    margin-top: 20px;
}

.address-top p {
    font-size: 16px;
    padding: 15px 5px;
    width: 650px;
}

.address-top a {
    text-decoration: none;
    color:#A3080C;
    margin-top: 5px;
    margin-right: 30px;
    font-size: 16px;

}

.current-content h3 {
    padding-left: 50px;
    font-size: 14px;
    margin-top: 30px;
    color: #3A3A3A;
}

.add1 {
    width: 90%;
    margin: auto;
   
    margin-top: 20px;
    border: 1px solid rgb(197, 197, 197);
    border-radius: 5px;
    margin-bottom: 20px;
}

.add-heading {
    display: flex;
    width: 100%;
    height: 55px;
}

.add-heading p {
    font-size: 14px;
    padding: 20px 20px;
    width: 700px;
    font-weight: 600;
    color: #3A3A3A;
}

.add-heading .lable {
    width: 74px;
    height: 23px;
    margin: 20px;
    border-radius: 20px;
    background-color: #EDE5E5;
    border-style: none;
    font-size: 14px;
    margin-right: 20px;
    font-weight: 600;
    text-align: center;
    color: #3A3A3A;
}

.add-list {
    width: 100%;
 
}

.add-list ul {
    margin-left: -10px;
}

.add-list ul li {
    font-size: 14px;
    padding-top: 10px;
    list-style: none;
}

.add-list p {
    margin-left: 20px;
    font-size: 14px;
    font-weight: 500;
    color: #3A3A3A;
}

.add-list .cat {
    margin-left: 20px;
    margin-bottom: 15px;
    color: #3A3A3A;
    font-size: 14px;
    font-weight: 500;
}

.add1-btn {
    width: 100%;
    height: 35px;
    display: flex;

}

.add1-btn button {
    width: 50%;
    font-size: 14px;
    border-top: 1px solid rgb(211, 211, 211);
    border-bottom: none;
    border-left: none;
    border-right: 1px solid rgb(211, 211, 211);
    ;
    background-color: transparent;
    color:#A3080C;
    font-weight: 600;
}

.address-form .addresstype {
    width: 90%;
    height: 80px;
    border-top: 3px solid rgb(236, 236, 236);
    border-bottom: 3px solid rgb(237, 237, 237);
    margin: auto;
}

.addresstype button {
    width: 54px;
    height: 27px;
    border: 1px solid rgb(187, 187, 187);
    background-color: white;
    border-radius: 5px;
    color: #bbbbbb;
    margin-top: 20px;
    font-weight: 600;
    font-size: 14px;
}

.anchorlink {
    width: 90%;
    margin: auto;
     margin-top: -15px;
    
}
.address-form .textarea{
    width: 90%;
    margin: auto;
    margin-top: 15px;
}
.anchorlink a {
    text-decoration: none;
    color:#A3080C;
}

.address-form {
    width: 75%;
    margin: auto;
}

.addresstype .active {
    border: 1px solid#A3080C;
    color:#A3080C;
}

.current-content .head-address {
    height: 50px;
}

.current-content .head-address h3 {
    font-size: 18px;
    margin-top: 10px;
}

.address-form .form2 {
    margin-top: 5px;
    margin: auto;
    width: 90%;
}

.newadd-btn {
    width: 90%;
    height: 80px;
    margin: auto;
    display: flex;
    margin-top: 20px;
}

.newadd-btn button {
    width: 7000rem;
    height: 40px;
    border-radius: 3px;
    margin-top: 30px;

}

.btn-1 {
    border-style: none;
    color: white;
    background-color:#A3080C;
}

.btn-2 {
    background-color:#A3080C;
    margin-left: 30px;
    border-style: none;
    color: white;
}