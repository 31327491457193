.carousel .carousel-dots {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;

}

.carousel .carousel-dots li {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #E30B5C;
    margin-right: 5px;
    cursor: pointer;

}

.carousel .carousel-dots li.selected {
    background-color: #f7377d;
}


.carousel .control-dots .dot {
    background-color: #E30B5C;
}

@media (max-width: 768px) {
    .carousel .carousel-dots {
        bottom: 10px;
    }

    .carousel .carousel-dots li {
        width: 8px;
        height: 8px;
        margin-right: 3px;
    }

}

@media (max-width: 1049px) {
    .inerr .search-container .search-input {
        width: 400px;
    }

    .overlay .inerr {
        width: 65%;
    }
}

.inerr {
    width: 56.2%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay {
    position: absolute;
    width: 100%;
    z-index: 9;
    left: 0;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    margin-top: -30px;
}

.search-container {
    flex: 1;
    display: flex;
    position: relative;
}

.search-container .search-input {
    font-family: 'Open sans', sans-serif;
    font-weight: 500;
    flex: 1;
    width: 556px;
    height: 54px;
    font-size: 16px;
    padding-left: 48px;
    border-radius: 9px;
    border: 2px solid #E4E4E4;
    margin-left: 5px;
}

.search-button {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: none;
    background-color: transparent;
    color: rgb(62, 61, 61);
    cursor: pointer;

}

.search-button i {
    width: 33;
    height: 27;

}

.location-dropdown {
    font-weight: 500;
    margin-left: 10px;
    width: 556px;
    height: 54px;
    font-size: 16px;
    border-radius: 9px;
    border: 2px solid #E4E4E4;
    background: #FFF;
}

.control-dots {
    margin-bottom: 2rem !important;
}

.u12 {
    display: none;
}


.mobile_hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 4.5rem;
}

.mobile_hero_box1 {
    padding: 0.5rem 0.9rem 0.5rem 0.9rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mobile_hero_add .hero_p1 {
    font-family: 'Open sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.1rem;
    margin: 0px;
    color: #413B3B;
}

.mobile_hero_add .hero_p2 {
    font-family: 'Open sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 0.8rem;
    margin: 0px;
    color: #413B3B;
}

.profile_out {
    padding: 0.5rem;
    border: 2px solid#A3080C;
    border-radius: 50%;
}

.profile_image_hero {
    height: 1.5rem;
    margin-bottom: 0.02rem;
    width: 1.5rem;
}

.slider-container {
    width: 100%;
    padding: 0px 0px;
}

@media (max-width: 1049px) {
    .slider-container {
        padding-top: 3.2rem;
    }

}

@media (max-width: 768px) {
    .u12 {
        display: block;
    }

    /* 
    .slider-container {
        display: none;
    } */

    .search-container .search-input {
        font-size: 16px;
    }

    .search-button {
        padding: 8px;
    }

    .location-dropdown {
        font-size: 14px;
    }
}