.Progress-bar {
    display: flex;
    width: 70%;
    margin: auto;
    padding-top: 30px;
    justify-content: center;
}

.progress-line {
    width: 600px;
    height: 3px;
    background-color: #cecece;
    margin-top: 10px;
}

.progress-line_active {
    background-color:#A3080C;
}

.progress-step {
    width: 35px;
    height: 25px;
    background-color: #cecece;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.progress-active {
    background-color:#A3080C;
    color: white;
}

/* search */
form.nosubmit {
    border: none;
    margin: 20px;
    height: 42px;
}

input.nosubmit {
    width: 100%;
    border: 1px solid #7D7C7C;
    ;
    display: block;
    border-radius: 3px;
    height: 42px;
    font-size: 17px;
    padding: 9px 4px 9px 40px;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
}

@media screen and (max-width:1049px) {
    .parent-container .cart_cost {
        display: flex;
        padding: 15px;
        position: fixed;
        bottom: 0;
        height: 55px;
        background: #fff;
        width: 99.9%;
        align-items: center;
        justify-content: center;
        box-shadow: 5px 5px 10px 5px #c3c3c3;
        border-radius: 1px;
    }

    .parent-container .cart_cost .cart_left span {
        font-size: 14px;
    }

    .parent-container .cart_cost .cart_right span {
        font-size: 15px;
        font-weight: 500;
        border-radius: 2px;
    }
}

@media screen and (max-width:450px) {
    .parent-container .cart_cost .cart_left {
        display: none;
    }

    .parent-container .cart_cost .cart_right {
        display: none;
    }

    .parent-container .cart_cost .cart_addressbtn {
        display: block;
    }

    .parent-container .cart_cost .cart_addressbtn {
        background-color:#A3080C;
        width: 100%;
        height: 45px;
        text-align: center;
        padding-top: 12px;
        border-radius: 3px;

    }

    .parent-container .cart_cost .cart_addressbtn a {
        color: white;
        text-decoration: none;
        font-size: 14px;
        font-weight: 500;
    }

}

/* search */


.address-container {
    background-color: white;
    height: auto;
    margin-top: 30px;
    margin-bottom: 90px;
    border-radius: 20px;

}

.head-address {
    width: 100%;
    height: 50px;

    align-items: center;
    border-bottom: 1px solid #d8d7d7;
}

.head-address h3 {
    font-size: 23px;
    padding: 12px 40px;
}

.form-container {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
}

.address-map {

    width: 60%;
    height: auto;
    margin-bottom: 30px;
}

.cartadd-form {
    width: 40%;

}

/* .address-form h4 {
    text-align: center;
    margin-top: 15px;
    font-size: 18px;
} */
.textarea {
    margin: auto;
    width: 85%;
    margin-top: 10px;
    border-bottom: 3px solid rgb(237, 237, 237);
    border-top: 3px solid rgb(239, 238, 238);
}

.textarea h5 {
    padding-top: 20px;
    font-size: 19px;

}

.textarea p {
    font-size: 14px;
}

.cartadd-form h3 {
    font-size: 17px;
    font: 600;
    padding: 10px 30px;
}

.cartadd-form form {
    margin: auto;
    width: 91%;

}

.cartadd-form input {
    margin-top: 15px;
    height: 45px;
}

.cartadd-form .form-floating label {
    padding-top: 10px;
}

.cartadd-form .addresbtn {
    margin: auto;
    height: 50px;
    border-radius: 10px;
    box-shadow: 0px 15px 10px -15px gray;
    width: 358px;
    margin-top: 30px;
}

.cartadd-form .anchor {
    width: 85%;
    margin: auto;
    margin-top: 15px;
}

.cartadd-form .anchor a {
    text-decoration: none;
    color:#A3080C;
    font-size: 14px;
    display: none;
    font-weight: 600;
    cursor: pointer;
}

.cartadd-form .anchor p {
    color:#A3080C;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
}

.cartadd-form .addresbtn button {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    background-color:#A3080C;
    color: white;
    border-style: none;
    font-size: 16px;
}

.address-map .map {
    margin-top: 50px;
    width: 712px;
    height: 511px;
    margin: auto;
    margin-top: 30px;
}

.addresstype {
    width: 85%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 20px;
}

.parent-container {
    font-family: 'Open Sans', sans-serif;
}

@media screen and (max-width:1049px) {
    .form-container {
        display: block;
        height: auto;
        padding-top: 3rem;
    }

    .address-map {
        display: none;
    }

    .address-map {
        width: 100%;

    }

    .address-container .head-address {
        display: none;
    }

    .parent-container {
        padding-top: 20px;
    }



    .cartadd-form .nosubmit {
        padding-top: 10px;
    }

    .cartadd-form {
        width: 100%;
        border-style: none;
        height: auto;
        margin-top: 10px;

    }


    

    .address-container {
        margin-bottom: 20px;
    }

    .parent-container .address-container {
        height: auto;
        width: 100%;
        border-radius: 3px;
    }

    .address-map .map {
        width: 90%;
    }

    .cartadd-form h3 {
        font-size: 25px;
        padding-left: 35px;
        padding: 0px 30px;
    }

    .addresbtn {
        margin-bottom: 20px;
    }

    .Progress-bar {
        display: none;
    }

    .cartadd-form .anchor {
        margin-top: 40px;
    }



}

/* footer */

.summaryFooter {

    width: 100%;
    height: 70px;
    background-color: #ffffff;
    box-shadow: 4px 5px 8px 5px gray;
}

.addbtn {
    margin: auto;
    float: right;
}

.addbtn button {

    margin: auto;
    width: 300px;
    height: 45px;
    border-radius: 5px;
    background-color:#A3080C;
    color: white;
    border-style: none;
    font-size: 16px;
}

@media screen and (max-width:800px) {

    .summaryFooter {
        margin-top: -60px;
    }

    .facial {
        display: none;
    }

    .facialprize {
        display: none;
    }

    .address-container {
        width: 95%;

    }

    .form-container {
        padding-top: 3rem;
    }
}

.footer-wrap {
    display: flex;
    margin: auto;
}

.facial {
    width: 270px;
}

.facial p {
    float: right;
    padding-top: 15px;
    padding-right: 30px;
    font-size: 18px;
    font-weight: 500;
}

.facialprize {
    margin: auto;
    width: 300px;

    text-align: center;
}

.facialprize p {
    padding-top: 15px;
    font-size: 18px;
    font-weight: 500;
    padding-right: 120px;
}