@media screen and (max-width:1105px) {
    .Profile-divider .gethelp-content{
        width: 100%;
        border-style: none;
        height: 84vh;
    }

}
@media screen and (max-width:768px) {

    .gethelp-box .help-text{
        height: auto;
    }
    .gethelp-box .help-img{
        margin-bottom: 5px;
    }
    .gethelp-box .help-img img{
        width: 57px;
        height: 57px;
        margin-top: 30px;
    }
    .gethelp-box .help-text h4{
        font-size: 18px;
        font-weight: 600;
    }
    .gethelp-box .help-text p{
        font-size: 14px;
    }
    .gethelp-content .help-next h3{
        font-size: 14px;
    }
    .contact .contact1 button{
        font-size: 16px;
    }
    .gethelp-content .query{
        width: 95%;
    }
}
.gethelp-content{
    width: 70%;
    height: auto;
    border-left: 1px solid rgb(198, 198, 198);
    margin-bottom: 30px;
}
.gethelp-box{
    width: 95%;
    margin: auto;
    height: auto;
    display: flex;
    border: 2px solid rgb(220, 220, 220);
    margin-top: 40px;
    border-radius: 5px;
}
.help-text{
 width: 70%;
 height: 130px;
 font-size: 14px;
 margin-top: 23px;
 padding:20px 20px;
 
}
.help-text h4{
    font-size: 20px;
    font-weight: 600;
}
.help-text p{
    font-size: 18px;
}
.help-img{
    width: 30%;
    height: 130px;
    margin-bottom: 10px;
    margin-top: 13px;
    
}
.help-img img{
    float: right;
    width: 90px;
    height: 90px;
   margin-right: 30px;
   margin-top: 20px;
}
.help-next{
    display: flex;
    width: 95%;
    height: 60px;
    margin: auto;
   border: 2px solid #e0e0e0;
   margin-top: 30px;
   border-radius: 5px;
}
.gethelp-content a{
    text-decoration: none;
}
.help-next h3{
    font-size: 18px;
    width: 900px;
    padding: 20px 20px;
    color: black;   
    font-weight: 400;
}
.help-next img{
    width: 20px;
    height: 20px;
    margin-top: 20px;
    margin-right: 20px;
}

/* contact */

.contact{
    width: 80%;
    margin: auto;
    height: 250px;
   
    margin-top: 50px;
}
.contact1{
    width: 100%;
    height: 39px;
    margin: auto;
    margin-top: 30px;
}
.contact1 button{
    width: 100%;
    height: 100%;
    border-radius: 3px;
    border-style: none;
    background-color: rgba(147, 41, 75, 0.12);
    color:#A3080C;
    font-size: 20px;
    font-weight: 600;
}

/* Query  */

.query{
    width: 65%;
    margin: auto;
    height: auto;
    margin-top: 30px;
    font-family: 'Open Sans', sans-serif;
}
.query h3{
    font-size: 18px;
    margin-top: 20px;
    font-weight: 550;
}
.choose{
    width: 100%;
    height: 45px;
    display: flex;
    margin-top: 10px;
    border: 2px solid rgb(223, 222, 222);
    border-radius: 3px;
    font-family: 'Open Sans', sans-serif;
}
.choose h4{
    font-size: 18px;
    padding: 10px;
    width: 700px;
    color: rgb(76, 76, 76);
}
.choose img{
    width: 20px;
    height: 20px;
    margin-top: 10px;
    margin-right: 20px;
}
.query .choose select{
    width: 95%;
    border: none;
    height: 100%;
    font-size: 17px;
}  
.choose select option{
   font-size: 14px;
   
}
.query textarea{
    width: 100%;
    height: 100px;
    border: 2px solid rgb(224, 224, 224);
}
.query button{
    margin-top: 20px;
    width: 100%;
    height: 45px;
    background-color:#A3080C;
    color: white;
    border-style: none;
    border-radius: 3px;
}






@media screen and (max-width:768px) {
    .gethelp-contents{
        width: 100% !important;
    }
    .gethelp-boxs .help-texts{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
    }
    .gethelp-boxs .help-img{
        margin-bottom: 5px;
    }
    .gethelp-boxs .help-img img{
        width: 57px;
        height: 57px;
        margin-top: 30px;
    }
    .gethelp-boxs .help-texts h4{
        font-size: 18px;
        font-weight: 600;
    }
    .gethelp-boxs .help-texts p{
        padding: 0;
        margin: 0;
        font-size: 14px;
        text-align: center;
    }
    .gethelp-contents .help-next h3{
        font-size: 14px;
    }
    .contacts .contact1 button{
        font-size: 16px;
    }
    .gethelp-content .query{
        width: 95%;
    }
}
.gethelp-contents{
    width: 74%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 30px;
}
.gethelp-boxs{
    display: flex;
    justify-content: center;
    width: 95%;
    margin: auto;
    height: auto;
    display: flex;
    border: 2px solid rgb(220, 220, 220);
    margin-top: 40px;
    border-radius: 5px;
}

.help-texts{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 80%;
    height: 130px;
    font-size: 14px;
    padding:20px 20px;
    
   }

   .contacts{
    width: 80%;
    margin: auto;
    height: 250px;
    margin-top: 10px;
}