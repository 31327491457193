/* footer CSS */
.footer {
    background-color: #0c0c0c;

    width: 100%;
    position: relative;
}

.futr-img {
    width: 90%;
    margin: auto;
    padding-top: 20px;
    padding-bottom: 10px;
    display: flex;
}

.ftr1 {
    margin: auto;
}

.footer_inner {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    width: 90%;
    margin: auto;
}

.uls {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.footer_titel {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    color:#A3080C;
}

.footer_itmes {
    font-family: 'Open Sans';
    margin: 5px 0px 5px 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: #f6f3f4;
}

.footer_social_logo {
    width: 100%;
    height: 40px;
    /* display: flex; */
    margin: auto;
    /* justify-content: center;
    align-items: center;
    border-radius: 50px; */

}

.contener1 {
    height: fit-content;
}

.contener1 ul a li:hover {
    color:#A3080C
}

.footer .divdr {
    width: 90%;
    margin: auto;
    background-color: #4C2D2D;
    margin-top: 10px;
}

.logo_footer {
    display: flex;
    align-items: center;
    float: right;
    margin-top: -80px;
    margin-right: 60px;
    width: 250px;
    height: 80px;

}

.social-link {
    margin: auto;

}

.footer_com {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
    padding-bottom: 30px;
}

.footer_comtext {
    font-size: 15px;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 5px;
    color: #ffffff;
    padding: 0px 15px 0px 15px;
}


.active_footer {
    display: none;
}

.mobile_footer {
    display: flex;
    padding: 0.3rem;
    align-items: center;
    justify-content: space-around;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 0px 15px rgba(227, 11, 92, 0.2);
}

.footer_menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.mobile_logo_img1 {
    width: 2rem;
    height: 2rem;
}

.footer_link {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;
    margin: 0px 0px 0px 0px;
    color: #212121;

}

.act {
    color:#A3080C;
}

.footer_link:hover {
    color:#A3080C;
}


.footer_link:hover:after {
    width: 100%;
    color:#A3080C;
    background:#A3080C;
}


/* Utility Classes */
.gap-2 {
    gap: .5rem;
}
.flex-wrap {
    flex-wrap: wrap;
}
.flex {
    display: flex;
}
.mt-5 {
    margin-top: 1.25rem;
}
*, :after, :before {
    box-sizing: border-box;
    border: 0 solid #e5e7eb;
}
.font-normal {
    font-weight: 400;
}
.text-xs {
    font-size: .75rem;
    line-height: 1rem;
}
.items-center {
    align-items: center;
}

/* City Container Styles */
.city-container {
    display: flex;
    flex-wrap: wrap; /* Use the utility class for wrapping */
    gap: 10px; /* You can replace this with .gap-2 if you want to use the utility class */
    margin-top: 1.25rem; /* Use the .mt-5 utility class */
    padding: 2rem 4.5rem 2rem 4.5rem;
    justify-content: center;
}

/* City Item Styles */
.city-item {
    display: flex;
    align-items: center; /* Use the .items-center utility class */
    font-size: 16px; /* Adjust font size if necessary */
    font-weight: normal; /* Use the .font-normal utility class */
    color: #ffffff; /* Change to your desired font color */
}

/* Separator Style */
.separator {
    margin: 0 0.25rem; /* Adjust spacing around separator */
    color: #d6d6d6; /* Change to your desired separator color */
}


@media screen and (max-width: 1048px) {
    .mobile_footer {
        position: fixed;
        left: 0;
        bottom: 0;
    }

    .footer {
        display: none;
    }

    .active_footer {
        display: block;
    }

    .mobile_logo_img1 {
        width: 1.5rem;
        height: 1.7rem;
        padding-top: 4px;
    }

    .footer_link {
        font-family: 'Fredoka';
        font-style: normal;
        font-weight: 600;
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 456px) {
    .mobile_footer {
        position: fixed;
        left: 0;
        bottom: 0;
    }

    .footer {
        display: none;
    }

    .active_footer {
        display: block;
    }

    .mobile_logo_img1 {
        width: 1.7rem;
        height: 1.6rem;
    }

    .footer_link {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 0.8rem;
    }
}