.back-nav{
    height: 50px;
    width: 100%;
}
.back-nav table{
    width: 80%;
    margin-left:20px;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 600;
}

.activenamekhf{
    border-radius: 2.475px;
    border: 1.238px solid rgba(152, 19, 19, 0.20);
    background: rgba(152, 19, 19, 0.12)
}